import { useQuery } from "@tanstack/react-query";
import { fetchProjectById } from "services/projectsService";
import { projectQueryKeys } from "settings/constants/queryKeys";

const useProject = (projectId?: string) => {
  return useQuery({
    queryKey: projectQueryKeys(projectId!),
    queryFn: () => fetchProjectById(projectId!),
    enabled: !!projectId,
  });
};

export default useProject;
