import { FC } from "react";
import { Container, Spinner } from "@chakra-ui/react";

const SpinnerLoading: FC = () => {
  return (
    <Container py="6" centerContent>
      <Spinner
        size="lg"
        thickness="3px"
        color="blue.500"
        emptyColor="gray.200"
      />
    </Container>
  );
};

export default SpinnerLoading;
