import { Stack } from "@chakra-ui/react";
import useOrder from "hooks/useOrder";
import { ReactNode } from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "settings/constants/routes";
import { NotificationHR } from "types/notification.type";

const RenderOrderLink = ({
  orderId,
  children,
  action,
}: {
  orderId: string;
  children: ReactNode;
  action: NotificationHR["action"];
}) => {
  const { data: order } = useOrder(orderId);

  let link = "";
  if (!!order?.id && !!order.status && action === "message_created") {
    link = ROUTES.ORDER_PAGE(order.id, order.status, "chat");
  } else if (!!order?.id && !!order.status) {
    link = ROUTES.ORDER_PAGE(order.id, order.status, "activity");
  }

  // TODO: Handle cases for delivery created, revision accpeted or any kind of update on order like left review etc.

  if (!link)
    return (
      <Stack as={Link} p="2">
        {children}
      </Stack>
    );
  return (
    <Stack as={Link} to={link} p="2">
      {children}
    </Stack>
  );
};
export default RenderOrderLink;
