import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { updateProjectByStatus } from "services/projectsService";
import { ROUTES } from "settings/constants/routes";
import { ERRORS } from "settings/constants/toastMessages";
import useCustomToast from "./useCustomToast";
import { IProjectStatus } from "types/project.types";

interface BtnLoadingProps {
  btn: IProjectStatus;
  isLoading: boolean;
}

const useActionButtons = () => {
  const [btnLoading, setBtnLoading] = useState<BtnLoadingProps>({
    btn: "draft",
    isLoading: false,
  });
  const { id: projectId } = useParams();
  const { errorToast } = useCustomToast();
  const navigate = useNavigate();

  const updateProjectStatus = async (status: IProjectStatus) => {
    if (!projectId) {
      errorToast({
        title: "Project not found!",
        description: "Please try hard reload or contact support",
      });
      return;
    }
    setBtnLoading({ btn: status, isLoading: true });
    const { error } = await updateProjectByStatus(projectId, { status });
    setBtnLoading({ btn: status, isLoading: false });

    if (!!error) return errorToast({ title: ERRORS.GENERIC });

    if (status === "active") return navigate(ROUTES.PROJECTS_ACTIVE);
    if (status === "completed") return navigate(ROUTES.PROJECTS_COMPLETED);
    // if (status === "deleted") return navigate(ROUTES.PROJECTS_DELETED);
    if (status === "deleted") return navigate(-1);
    if (status === "draft") return navigate(ROUTES.PROJECTS_DRAFT);
    if (status === "paused") return navigate(ROUTES.PROJECTS_PAUSED);
  };

  const redirectToWizard = () =>
    navigate(`${ROUTES.PROJECTS_NEW}/${projectId}`);

  return {
    btnLoading,
    handlers: { updateProjectStatus, redirectToWizard },
  };
};

export default useActionButtons;
