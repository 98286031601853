import { BoxProps, Stack } from "@chakra-ui/react";
import RenderOrderLink from "./RenderOrderLink";
import { NotificationHR } from "types/notification.type";
import { ReactNode } from "react";

interface RenderLinkProps extends BoxProps {
  children: ReactNode;
  data: Pick<
    NotificationHR,
    "resourceId" | "resourceType" | "referenceType" | "action"
  >;
}

const RenderLink = ({ children, data }: RenderLinkProps) => {
  if (data?.referenceType === "order")
    return (
      <RenderOrderLink orderId={data.resourceId || ""} action={data.action}>
        {children}
      </RenderOrderLink>
    );

  if (data?.referenceType === "delivery")
    return (
      <RenderOrderLink orderId={data.resourceId || ""} action={data.action}>
        {children}
      </RenderOrderLink>
    );

  return <Stack p="2">{children}</Stack>;
};

export default RenderLink;
