/////////////////////
// AuthProvider Context
/////////////////////

// Basic Imports
import { ReactNode, createContext, useContext } from "react";
import Cookies from "js-cookie";
import axios from "libraries/axios";

// Services
import {
  loginUser,
  getUserAccountDetails,
  getAuthToken,
} from "services/authService";
import { User } from "types/user.types";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { authUserQueryKeys } from "settings/constants/queryKeys";
import { ROUTES } from "settings/constants/routes";

// Custom Hooks

// Interfaces
interface AuthContextType {
  token: string | undefined;
  user: User | null;
  isLoading: boolean;
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
}

// Context
const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

interface AuthProviderProps {
  children: ReactNode;
}
export const AuthProvider = ({ children }: AuthProviderProps) => {
  const token = getAuthToken();
  const queryClient = useQueryClient();
  const { data: user, isLoading } = useQuery({
    queryKey: authUserQueryKeys(),
    queryFn: async () => {
      if (!token) throw new Error();
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      return getUserAccountDetails();
    },
  });

  const login = async (email: string, password: string): Promise<void> => {
    const token = await loginUser(email, password);
    Cookies.set("token", token, {
      expires: 1,
      sameSite: "strict",
      secure: true,
    });
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

    const user = await getUserAccountDetails();
    queryClient.setQueryData(authUserQueryKeys(), user);
  };

  const logout = (): void => {
    Cookies.remove("token");
    delete axios.defaults.headers.common["Authorization"];

    queryClient.clear();

    window.location.href = ROUTES.LOGIN_IN;
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        isLoading,
        login,
        logout,
        token,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
