import { useQuery } from "@tanstack/react-query";
import { getOrderReceipt } from "services/receiptService";
import { orderReceipt } from "settings/constants/queryKeys";

const useOrderReceipt = (orderId: string) => {
  return useQuery({
    queryKey: orderReceipt(orderId),
    queryFn: () => getOrderReceipt(orderId),
    enabled: !!orderId,
    refetchInterval: 10000,
  });
};

export default useOrderReceipt;
