import { FC } from "react";
import { AspectRatio, Box, Icon } from "@chakra-ui/react";
import { MdImageNotSupported } from "react-icons/md";
import { Attachment } from "types/attachments.types";
import ImageWithLoader from "pages/OfferPage/Image/ImageWithLoader";

interface Props {
  attachments: Attachment[];
}
const OfferCardImages: FC<Props> = ({ attachments }) => {
  const featuredImage =
    attachments.find((a: Attachment) => a.featured) || attachments[0];

  if (!featuredImage)
    return (
      <AspectRatio ratio={3 / 2}>
        <PlaceholderImage />
      </AspectRatio>
    );
  return (
    <AspectRatio ratio={3 / 2}>
      <ImageWithLoader
        width={400}
        height={500}
        src={featuredImage.thumbnailUrl}
        alt={featuredImage.name}
      />
    </AspectRatio>
  );
};

const PlaceholderImage = () => {
  return (
    <Box bg="gray.200">
      <Icon fontSize="4xl" color="gray.500" as={MdImageNotSupported} />
    </Box>
  );
};

export default OfferCardImages;
