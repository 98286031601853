import { useQuery } from "@tanstack/react-query";
import { getAllOrders } from "services/orderService";
import { Order, OrderStatus } from "types/order.types";
import _ from "lodash";

type OrdersProps = { userId?: string; status?: OrderStatus };
const useOrders = ({ userId, status }: OrdersProps) => {
  const orderKeys = status ? ["users", "orders", status] : ["users", "orders"];

  return useQuery<Order[]>({
    queryKey: orderKeys,
    queryFn: () => getAllOrders({ userId: userId!, status }),
    select: (data) => _.orderBy(data, "createdAt", "desc"),
    enabled: !!userId,
  });
};

export default useOrders;
