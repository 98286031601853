import { FC } from "react";
import {
  Card,
  UseRadioProps,
  useRadio,
  Text,
  useRadioGroup,
  Stack,
  StackProps,
  HStack,
  Icon,
  As,
} from "@chakra-ui/react";
import { FaExternalLinkAlt } from "react-icons/fa";

export type RadioButtonOption = {
  label: string;
  value: string;
  description: string;
  icon: As;
  isExternal?: boolean;
  href?: string;
};
interface Props {
  options: RadioButtonOption[];
  defaultValue: string;
  onChange?: (value: string) => void;
  styles?: StackProps;
}
const RadioCheckboxes: FC<Props> = ({
  options,
  defaultValue,
  onChange,
  styles,
}) => {
  const { getRadioProps, getRootProps } = useRadioGroup({
    defaultValue,
    onChange,
  });

  return (
    <Stack {...getRootProps()} align="center" justify="center" {...styles}>
      {options.map((mood) => (
        <CustomRadio
          key={mood.value}
          label={mood.label}
          description={mood.description}
          icon={mood.icon}
          isExternal={mood.isExternal}
          href={mood.href}
          {...getRadioProps({ value: mood.value })}
        />
      ))}
    </Stack>
  );
};

interface CustomRadioProps extends UseRadioProps {
  label: string;
  description: string;
  icon: As;
  isExternal?: boolean;
  href?: string;
}
const CustomRadio: FC<CustomRadioProps> = (props) => {
  const { label, description, icon, isExternal, href, ...radioProps } = props;
  const { state, getInputProps, getRadioProps, htmlProps, getLabelProps } =
    useRadio(radioProps);

  return (
    <Card
      as="label"
      {...htmlProps}
      variant={state.isChecked ? "filled" : "outline"}
      px={{ base: 4, md: 6 }}
      py={1}
      w="full"
      bg="white"
      cursor="pointer"
      border="2px"
      borderColor={state.isChecked ? "blue.500" : "inherit"}
      onClick={() => {
        if (!!href) {
          window.location.href = href;
        }
      }}
    >
      <input {...getInputProps({})} hidden />
      <HStack {...getRadioProps()} position="relative">
        <HStack spacing={4}>
          <Icon
            as={icon}
            fontSize={22}
            color={state.isChecked ? "blue.500" : "inherit"}
          />

          <Stack textAlign="left" spacing={0}>
            <Text fontWeight="semibold" fontSize="md">
              {label}
            </Text>
            <Text {...getLabelProps()} fontSize="sm">
              {description}
            </Text>
          </Stack>
        </HStack>

        {isExternal && (
          <Icon as={FaExternalLinkAlt} position="absolute" right="0" />
        )}
      </HStack>
    </Card>
  );
};

export default RadioCheckboxes;
