import { API_URL } from "settings/site-credentials";
import axios from "libraries/axios";

export async function fetchAssetsByProjectId(projectId: string) {
  try {
    const res = await axios.get(`${API_URL}/projects/${projectId}/assets`);

    const assets = res.data.data.assets as any[];
    return { assets, res };
  } catch (error) {
    return { error: "Something went wrong..." };
  }
}
