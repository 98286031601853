import { Button, HStack, Icon, Skeleton, Text } from "@chakra-ui/react";
import useOrderReceipt from "hooks/useOrderReceipt";
import { FaDownload } from "react-icons/fa6";
import { MdReceipt } from "react-icons/md";
import { Link } from "react-router-dom";

const OrderReceipt = ({ orderId }: { orderId: string }) => {
  const { data, isLoading } = useOrderReceipt(orderId);

  if (!!isLoading) return <Skeleton w="full" h="22px" />;
  return (
    <HStack justify={"space-between"}>
      <HStack>
        <Text>Receipt</Text>
        <Icon as={MdReceipt} color="green" />
      </HStack>
      <Link
        to={data?.receiptUrl || ""}
        target="_blank"
        rel="noopener noreferrer"
      >
        <HStack>
          <Button variant="link" rightIcon={<Icon as={FaDownload} />}>
            Download
          </Button>
        </HStack>
      </Link>
    </HStack>
  );
};

export default OrderReceipt;
