/////////////////////
// Security Page
/////////////////////

import { useAuth } from "context/AuthProvider";
import SecurityForm from "./SecurityForm";
import Alert from "components/features/Alert";

const SecurityPage = () => {
  const { user } = useAuth();

  if (!user)
    return (
      <Alert
        status="error"
        title="User account not found."
        description="Please attempt a hard reload or re-authenticate. If the issue continues, kindly contact support."
      />
    );
  return <SecurityForm user={user} />;
};

export default SecurityPage;
