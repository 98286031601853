import {
  isToday,
  isYesterday,
  format,
  formatDistanceToNow,
  subDays,
} from "date-fns";
import { NotificationHR } from "types/notification.type";

interface GroupedNotifications {
  today: NotificationHR[];
  yesterday: NotificationHR[];
  earlier: NotificationHR[];
}

export function groupNotificationsByDate(notifications: NotificationHR[]) {
  const groups: GroupedNotifications = {
    today: [],
    yesterday: [],
    earlier: [],
  };

  notifications.forEach((notification: NotificationHR) => {
    const createdAt = new Date(notification.createdAt);
    if (isToday(createdAt)) {
      groups.today.push(notification);
    } else if (isYesterday(createdAt)) {
      groups.yesterday.push(notification);
    } else {
      groups.earlier.push(notification);
    }
  });

  return groups;
}

export function formatDateRelative(updatedAt: string | Date | number) {
  const date = new Date(updatedAt);
  const now = new Date();
  const oneDayAgo = subDays(now, 1);

  if (date > oneDayAgo) {
    // If the date is within the last 24 hours, show in relative format
    return formatDistanceToNow(date, { addSuffix: true });
  } else {
    // Otherwise, show the date and time
    return format(date, "MMM dd,yyyy hh:mm a"); // e.g., Jun 20, 2020, 12:30 PM
  }
}
