/////////////////////
// Auth Service
// HC is just a crypto generated string which tells the react component that the payment is done or canceled
// to show up the modal when a user click cancel on stripe payment page or subscribed as stripe page is
// totally stateless so we have to query out the params
/////////////////////

// Basic Imports
import Cookies from "js-cookie";
import axiosInstance from "libraries/axios";
import axios from "libraries/axios";
// Design Imports

// Layout and Section Imports

// Data Imports

// Functions

// Custom Hooks and Services

// Interfaces
interface SignupType {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
  // role: "admin" | "member" | "customer"
}

interface ResetTypes {
  email: string;
}
interface VerificationTypes {
  token: string;
}

interface ResetPasswordTypes {
  token: string;
  newPassword: string;
  confirmPassword: string;
}

// Functions
export const getUserAccountDetails = async () => {
  const response = await axios.get(`/users/account`);
  return response.data.data.user;
};

export const loginUser = async (
  email: string,
  password: string
): Promise<string> => {
  const response = await axios.post("/auth/login", {
    email,
    password,
    source: "HR_APP",
  });
  return response.data.token as string;
};

export async function verification({
  token,
}: VerificationTypes): Promise<void> {
  await axios.post(`/auth/account/verify`, {
    verificationToken: token,
  });
}
export async function sendVerificationEmail(email: string): Promise<void> {
  await axios.post(`/auth/account/verify/send-email`, {
    email,
  });
}

export async function resetPassword({
  token,
  newPassword,
  confirmPassword,
}: ResetPasswordTypes): Promise<void> {
  await axios.post(`/auth/account/password/reset/`, {
    passwordResetToken: token,
    newPassword,
    confirmPassword,
  });
}

export async function forgotPassword({ email }: ResetTypes): Promise<void> {
  await axios.post(`/auth/account/password/reset/send-email`, { email });
}

export async function signup(data: SignupType): Promise<void> {
  await axiosInstance.post("/auth/signup", {
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    password: data.password,
    confirmPassword: data.confirmPassword,
    role: "customer",
    source: "HR_APP",
  });
}

export function getAuthToken() {
  const token = Cookies.get("token");
  return token;
}
