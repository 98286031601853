import { useQuery } from "@tanstack/react-query";
import { getOfferById } from "services/gigsService";

const useOffer = (offerId?: string) => {
  return useQuery({
    queryKey: ["offers", offerId],
    queryFn: () => getOfferById(offerId!),
    enabled: !!offerId,
  });
};

export default useOffer;
