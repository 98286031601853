import axios from "libraries/axios";
import { Offer, OfferPrice } from "types/offer.types";
import { Category, SubCategory } from "types/gigCategory";
import { Rating } from "types/gigRatings";
import { Tag } from "types/gigTag";
import { FAQ } from "types/faq.types";

export type AllGigsResponse = { success: boolean; gigs: Offer[] };
export async function getAllGigs(
  searchText: string = ""
): Promise<AllGigsResponse> {
  const url = searchText
    ? `/offers?search=${encodeURIComponent(searchText)}`
    : `/offers`;

  const { data } = await axios.get(url);
  return data.data.offers;
}

export async function getOfferById(id: string): Promise<Offer> {
  const response = await axios.get(`/offers/${id}`);
  return response.data.data.offer;
}

export async function getOfferPrices(offerId: string): Promise<OfferPrice[]> {
  const response = await axios.get(`/offers/${offerId}/prices`);
  return response.data.data.prices;
}

export async function getOfferRatings(offerId: string): Promise<Rating[]> {
  const { data } = await axios.get(`/offers/${offerId}/ratings`);
  return data.data.ratings;
}

export async function getOfferCategoryById({
  categoryId,
}: {
  categoryId: string;
}): Promise<Category> {
  const { data } = await axios.get(`/categories/${categoryId}`);
  return data.data.category;
}

export async function getOfferSubcategories({
  categoryId,
}: {
  categoryId: string;
}): Promise<SubCategory[]> {
  const { data } = await axios.get(`/categories/${categoryId}/subcategories`);
  return data.data.subCategories;
}

export async function getOfferSubcategoryById({
  subcategoryId,
}: {
  subcategoryId: string;
}): Promise<SubCategory> {
  const { data } = await axios.get(`/subcategories/${subcategoryId}`);

  return data.data.category;
}

export async function getTagById(tagId: string): Promise<Tag> {
  const { data } = await axios.get(`/tags/${tagId}`);

  return data.data.tag;
}
export async function getTagsByIds(tagIds: string[]): Promise<Tag[]> {
  // Use Promise.all to fetch all tags concurrently
  const tagPromises = tagIds.map((tagId) => getTagById(tagId));
  const tags = await Promise.all(tagPromises);

  return tags;
}

export const getOfferFaqs = async (offerId: string): Promise<FAQ[]> => {
  const response = await axios.get(`/offers/${offerId}/faqs`);
  return response.data.data.faqs;
};
