import { FC, ReactNode } from "react";
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Flex,
  Box,
  Badge,
  Stack,
  Link,
} from "@chakra-ui/react";
import { Logo } from "components/ui/logo/Logo2";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
}
const SidebarDrawer: FC<Props> = ({ children, isOpen, onClose }) => {
  return (
    <Drawer
      isOpen={isOpen}
      placement="left"
      onClose={onClose}
      // finalFocusRef={btnRef}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <Stack spacing={4}>
          <DrawerHeader>
            <Flex align="center">
              <Link href="/">
                <Logo h="35px" color="black" />
              </Link>
              <Box ml="-10px" fontSize="2xl" color="primary">
                <Badge>Talent</Badge>
              </Box>
            </Flex>
          </DrawerHeader>
          <DrawerBody>{children}</DrawerBody>
        </Stack>
      </DrawerContent>
    </Drawer>
  );
};

export default SidebarDrawer;
