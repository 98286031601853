import { FC, ReactNode, useEffect } from "react";
import { SimpleGrid, Skeleton } from "@chakra-ui/react";
import { Profile } from "types/profile.types";
import ProfileCard from "components/sections/ProfileCard";
import Alert from "components/features/Alert";
import useProfiles from "hooks/useProfiles";

interface Props {
  search: string;
}
const RenderProfiles: FC<Props> = ({ search }) => {
  const { data: profiles, isLoading, error, onSearch } = useProfiles();

  useEffect(() => {
    onSearch(search);
  }, [search]);

  console.log({ profiles });

  if (!!isLoading) return <OffersSkeletonLoading />;
  if (!!error)
    return (
      <Alert
        w="max"
        status="error"
        title="Failed to fetch public profiles"
        description="Please try reload or contact support if error persists."
      />
    );
  if (!profiles)
    return (
      <Alert
        w="max"
        status="error"
        title="Unable to fetch public profiles"
        description="Please try reload or contact support if error persists."
      />
    );
  if (profiles.length === 0)
    return (
      <Alert
        status="info"
        title="No public profiles have been created yet."
        w="max"
      />
    );
  return (
    <Layout>
      {profiles.map((profile: Profile) => {
        return <ProfileCard key={profile.id} profile={profile} />;
      })}
    </Layout>
  );
};

const OffersSkeletonLoading = () => {
  return (
    <Layout>
      <Skeleton w="full" h="20rem" rounded="md" />
      <Skeleton w="full" h="20rem" rounded="md" />
      <Skeleton w="full" h="20rem" rounded="md" />
      <Skeleton w="full" h="20rem" rounded="md" />
      <Skeleton w="full" h="20rem" rounded="md" />
      <Skeleton w="full" h="20rem" rounded="md" />
      <Skeleton w="full" h="20rem" rounded="md" />
      <Skeleton w="full" h="20rem" rounded="md" />
    </Layout>
  );
};

const Layout = ({ children }: { children: ReactNode }) => {
  return (
    <SimpleGrid w="full" gap="6" columns={{ base: 1, md: 2, lg: 4 }}>
      {children}
    </SimpleGrid>
  );
};

export default RenderProfiles;
