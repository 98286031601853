/////////////////////
// [Replace me] Page
/////////////////////

// Basic Imports
import { FC, useEffect, useState } from "react";

// Design imports
import {
  Badge,
  Icon,
  SimpleGrid,
  Stack,
  useCheckboxGroup,
} from "@chakra-ui/react";

import { ButtonlessCheckbox } from "components/ui/common/Checkbox";

// Data imports
import { AssetProps } from "data/projectWizardData";
import {
  RENDERING,
  // NONE_PLAN,
  FLOORPLAN,
  SELECTIONS,
} from "settings/constants/billingPlans";
import { useAuth } from "context/AuthProvider";
// import { giveRemainingDaysForSubscription } from "services/stripeService";
// Custom Hooks and Services

// Interface imports
interface Props {
  assets: AssetProps[];
  defaultValues?: string[];
  values?: string[];
  onSelectedAssets?: (assets: any) => void;
}
const AssetsCheckBoxes: FC<Props> = ({
  assets,
  defaultValues,
  onSelectedAssets,
}) => {
  const { user } = useAuth();
  const [checkboxValues, setCheckboxValues] = useState<string[]>(
    defaultValues || []
  );
  // const [remainingDays, setRemainingDays] = useState(0);
  const { getCheckboxProps } = useCheckboxGroup({
    value: checkboxValues,
    onChange: (values: string[]) => setCheckboxValues(values),
  });

  // TODO: Subscription field has been removed in latest schema version.
  // const getDays = async () => {
  // const days = await giveRemainingDaysForSubscription(
  //   user?.subscription?.currentPeriodEnd,
  //   user?.subscription?.canceledAt
  // );
  // setRemainingDays(days);
  // };

  useEffect(() => {
    // TODO: Subscription field has been removed in latest schema version.
    // if (user?.subscription?.status === "canceled") {
    //   getDays();
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (!!defaultValues) {
      setCheckboxValues(defaultValues);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  useEffect(() => {
    const selectedIn = assets.filter((asset: AssetProps) =>
      checkboxValues.some((value) => value === asset.value)
    );

    if (onSelectedAssets) {
      onSelectedAssets(selectedIn);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkboxValues]);

  return (
    <Stack spacing={6}>
      {/* For starter plans */}
      <Stack align="center" spacing={6}>
        <Badge w="max" py="1" px="3" variant="solid" colorScheme="blue">
          {SELECTIONS}
        </Badge>
        <SimpleGrid maxW="7xl" columns={{ base: 1, md: 2 }} spacing={5} pb={5}>
          {assets
            .filter((a) => a.plan === SELECTIONS)
            .map((asset: AssetProps, idx: number) => (
              <ButtonlessCheckbox
                key={idx.toString()}
                icon={<Icon as={asset.icon} />}
                title={asset.title}
                description={asset.description}
                // TODO: Code is commented because subscription field is not available on user schema and need to use subscription route
                // isDisabled={
                //   user?.subscription?.plan === NONE_PLAN && remainingDays < 1
                // }
                disableText="You should have at least starter plan"
                {...getCheckboxProps({ value: asset.value })}
              />
            ))}
        </SimpleGrid>
      </Stack>

      {/* For professional plans */}
      <Stack cursor="not-allowed" align="center" spacing={6}>
        <Badge w="max" py="1" px="3" variant="solid" colorScheme="blue">
          {FLOORPLAN}
        </Badge>
        <SimpleGrid maxW="7xl" columns={{ base: 1, md: 2 }} spacing={5} pb={5}>
          {assets
            .filter((a) => a.plan === FLOORPLAN)
            .map((asset: AssetProps, idx: number) => (
              <ButtonlessCheckbox
                key={idx.toString()}
                icon={<Icon as={asset.icon} />}
                title={asset.title}
                description={asset.description}
                // isDisabled={
                //   (user?.subscription?.plan === SELECTIONS ||
                //     user?.subscription?.plan === NONE_PLAN) &&
                //   (remainingDays < 1 ||
                //     user?.subscription?.planCancelledAt === SELECTIONS)
                // }
                disableText="Only allowed in Professional plan, update your plan by going to Account Settings > Billing > Manage Billing"
                {...getCheckboxProps({ value: asset.value })}
              />
            ))}
        </SimpleGrid>
      </Stack>

      {/* For business plans */}
      <Stack align="center" spacing={6}>
        <Badge w="max" py="1" px="3" variant="solid" colorScheme="blue">
          {RENDERING}
        </Badge>
        <SimpleGrid maxW="7xl" columns={{ base: 1, md: 2 }} spacing={5} pb={5}>
          {assets
            .filter((a) => a.plan === RENDERING)
            .map((asset: AssetProps, idx: number) => (
              <ButtonlessCheckbox
                key={idx.toString()}
                icon={<Icon as={asset.icon} />}
                title={asset.title}
                description={asset.description}
                // isDisabled={
                //   asset.actionData === null ||
                //   // asset.stylesData.length === 0 || // Bug: It was forcing "other" asset to be disabled and it shouldn't be so I am gonna comment it and later we will remove it
                //   ((user?.subscription?.plan === SELECTIONS ||
                //     user?.subscription?.plan === FLOORPLAN ||
                //     user?.subscription?.plan === NONE_PLAN) &&
                //     (remainingDays < 1 ||
                //       user?.subscription?.planCancelledAt === FLOORPLAN ||
                //       user?.subscription?.planCancelledAt === SELECTIONS))
                // }
                disableText="Only allowed in Business plan, update your plan by going to Account Settings > Billing > Plan Details"
                {...getCheckboxProps({ value: asset.value })}
              />
            ))}
        </SimpleGrid>
      </Stack>
    </Stack>
  );
};

export default AssetsCheckBoxes;
