// hooks/useHighlightAnimation.ts
import { useState, useEffect, useRef } from "react";
import { keyframes } from "@chakra-ui/react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";

interface UseHighlightAnimationOptions {
  paramName: string;
  paramValue: string;
  color?: string;
  scale?: number; // Default scale is 1.05
  animationDuration?: number; // Default duration is 1500ms
}

export const useHighlightAnimation = ({
  paramName,
  paramValue,
  color = "#3182ce",
  scale = 1.05,
  animationDuration = 1000,
}: UseHighlightAnimationOptions) => {
  const [animate, setAnimate] = useState(false);
  const elementRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  // Animation keyframes for scaling and border color
  const scaleAnimation = keyframes`
    0%, 100% { transform: scale(1); }
    50% { transform: scale(${scale}); }
  `;

  const animationStyles = {
    animation: `${scaleAnimation} ${animationDuration}ms ease-in-out`,
  };

  useEffect(() => {
    if (searchParams.get(paramName) === paramValue) {
      setAnimate(true);
      if (elementRef.current) {
        const elementTop =
          elementRef.current.getBoundingClientRect().top + window.scrollY;
        window.scrollTo({
          top: elementTop - 30, // Add some top offset
          behavior: "smooth",
        });
      }
      // Remove the search parameter after the animation is completed
      setTimeout(() => {
        setAnimate(false);
        const params = new URLSearchParams(searchParams);
        params.delete(paramName);

        // Construct the new URL, preserving the existing pathname
        const newUrl = `${location.pathname}?${params.toString()}`;

        // Update the URL without causing a full reload
        navigate(newUrl, { replace: true });
      }, animationDuration);
    }
  }, [
    searchParams,
    paramName,
    paramValue,
    animationDuration,
    location.pathname,
    navigate,
  ]);

  return { animate, elementRef, animationStyles };
};
