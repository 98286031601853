import { useQuery } from "@tanstack/react-query";
import { getCategories } from "services/categoryService";
import { categoriesKeys } from "settings/constants/queryKeys";
import { formatCategoriesWithOtherAtEnd } from "utils/category.utils";

const useCategories = () => {
  return useQuery({
    queryKey: categoriesKeys(),
    queryFn: () => getCategories(),
    select: formatCategoriesWithOtherAtEnd,
  });
};

export default useCategories;
