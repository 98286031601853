import { FC } from "react";
import { Avatar, Divider, Flex, Skeleton, Stack, Text } from "@chakra-ui/react";
import Ratings from "components/ui/common/Ratings";
import OfferTags from "./OfferTags";
import { Offer } from "types/offer.types";
import HtmlReactParser from "components/ui/HtmlReactParser";
import FAQSection from "./FAQSection";
import useOfferReviews from "hooks/useOfferReviews";
import { ImageGallery } from "../Image/ImageGallery";
import RequirementSection from "./RequirementSection";
import ReviewsSection from "./ReviewsSection";

interface Props {
  data: Offer;
}
const ContentSection: FC<Props> = ({ data }) => {
  const { data: reviews, isLoading } = useOfferReviews(data.id);

  return (
    <Stack spacing={8} w="full">
      {/* Gig Header (Title + Seller + Average Ratings + Orders in Queue) */}
      <Stack>
        <Text
          as="h1"
          fontWeight="semibold"
          fontSize={{ base: "xl", md: "2xl" }}
        >
          {data.title}
        </Text>
        <Flex align="center" gap={2}>
          <Avatar size="sm" src={data?.profileId?.avatar || ""} />
          <Text as="span" color="black">
            {data?.profileId?.title}
          </Text>

          <Divider
            orientation="vertical"
            height="20px"
            borderColor="gray.400"
          />

          <Skeleton isLoaded={!isLoading}>
            <Ratings
              size="xs"
              rating={!reviews ? 0 : Number(reviews?.averageRating.toFixed(1))}
              totalCount={reviews?.totalRatingsCount || undefined}
              showDigits
            />
          </Skeleton>

          <Divider
            orientation="vertical"
            height="20px"
            borderColor="gray.400"
          />

          <Text fontWeight="semibold">
            {data.ordersInQueue} orders in queue
          </Text>
        </Flex>
      </Stack>

      {/* Gig Gallery */}
      <ImageGallery images={data.attachments} />

      {/* Gig Description */}
      <Stack>
        <Text as="h2" fontWeight="bold" fontSize="xl">
          Description
        </Text>
        <Stack w={{ md: "40rem", base: "auto" }}>
          <HtmlReactParser html={data.description.htmlText} />
        </Stack>
      </Stack>

      {/* Tags Section */}
      <OfferTags isLoading={false} labels={data?.tags?.map((d) => d.name)} />

      {/* FAQ Section */}
      <FAQSection offerId={data._id || data.id} />

      {/* Requirement Section */}
      <RequirementSection offerId={data._id || data.id} />

      {/* Reviews Section */}
      <ReviewsSection offerId={data._id || data.id} />
    </Stack>
  );
};

export default ContentSection;
