/////////////////////
// Theme Foundations
/////////////////////
import { theme } from "@chakra-ui/react";

const foundations = {
  colors: {
    ...theme.colors,
    primary: "#3181CE",
    secondary: "blue.400",
    defaultLight: "gray.800",
    defaultDark: "gray.200",
    borderColor: "#3181ce70",
    iconBg: "#E74F4F",
    ratingColor: "#FFB33E",
  },

  layerStyles: {
    base: {
      bg: "pink",
      border: "2px solid",
      borderColor: "purple",
    },
    selected: {
      bg: "red",
      color: "green",
      borderColor: "blue",
    },
  },
  fonts: {
    ...theme.fonts,
  },
  borders: {
    ...theme.borders,
    primary: {
      borderColor: "#3181CE",
    },
  },
};

export default foundations;
