///////////////////
// RadioPricePage Page
/////////////////////

// Basic Imports
//import * as React from "react";

// Design Imports
import { Box, Container, SimpleGrid } from "@chakra-ui/react";

// Layout and Section Imports
import { PricingCard } from "./PricingCards";

// Data Imports

// Custom Hooks and Services
import { getStripePlans } from "services/stripeService";
import { LIVE_FEATURES } from "settings/site-credentials";
import { priceSaving } from "data/pricingData";
import { useQuery } from "@tanstack/react-query";
import Alert from "components/features/Alert";
import Loader from "components/ui/common/Loader";
import { ISubscriptionPlanPrice } from "types/stripe.types";
import { useSearchParams } from "react-router-dom";
import { FC } from "react";

// Interfaces
interface Props {
  activePriceId?: string;
}

// Functions
export const RadioPricePage: FC<Props> = ({ activePriceId }) => {
  const [searchParams] = useSearchParams();
  const currency = searchParams.get("currency") || "usd";

  const getPlanDuration = ({
    interval,
    interval_count,
  }: ISubscriptionPlanPrice["recurring"]): string => {
    if (interval === "month" && interval_count === 3) return "Quarterly";
    if (interval === "month" && interval_count === 1) return "Monthly";
    if (interval === "year" && interval_count === 1) return "Yearly";

    return "Unknown";
  };

  const {
    data: plans,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["subscriptionPlans", currency],
    queryFn: () => getStripePlans(currency),
    select(data) {
      console.log({ data });
      const shapedPlans = data.prices.map((price) => ({
        priceId: price.id,
        price: price.unit_amount / 100,
        duration: getPlanDuration(price.recurring),
        currency: price.currency,
      }));
      return shapedPlans;
    },
  });

  if (!!isLoading) return <Loader />;
  if (!!error || !plans)
    return (
      <Alert
        status="error"
        title="Failed to fetch plans"
        description="Please attempt a hard reload or re-authenticate. If the issue continues, kindly contact support."
      />
    );
  return (
    <Box as="section" bg="bg-surface">
      <Container maxW="100%">
        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={4}>
          {plans.map((plan, index) => {
            const isActive = activePriceId === plan.priceId;
            return (
              <PricingCard
                name={plan.duration}
                description={""}
                priceId={plan.priceId}
                price={Number(plan.price)}
                duration={plan.duration}
                currency={plan.currency}
                benefits={[""]}
                save={LIVE_FEATURES ? 0 : priceSaving[index]}
                isActive={isActive}
                styles={{
                  color: "black",
                }}
              />
            );
          })}
        </SimpleGrid>
      </Container>
    </Box>
  );
};
