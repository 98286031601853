import { useLocation, useSearchParams } from "react-router-dom";
import { Skeleton, Wrap } from "@chakra-ui/react";
import RadioButtons from "components/ui/common/RadioButtons";
import useCategories from "hooks/useCategories";

const CategoriesFilters = () => {
  const { data: categories, isLoading } = useCategories();
  const options = categories?.map((cat) => ({
    label: cat.name,
    value: cat.id,
  }));
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const categoryId = searchParams.get("category");

  const onChangeCategory = (value: string) => {
    const params = new URLSearchParams(location.search);
    if (!!value) {
      params.set("category", value);
    } else {
      params.delete("category");
    }

    setSearchParams(params);
  };

  if (isLoading)
    return (
      <Wrap spacing={2}>
        {[1, 2, 3, 4, 5, 6].map((item) => (
          <Skeleton key={item} w="8rem" h="2.5rem" />
        ))}
      </Wrap>
    );
  return (
    <RadioButtons
      options={[{ label: "All", value: "" }, ...(options || [])]}
      defaultValue={categoryId || ""}
      onChange={onChangeCategory}
    />
  );
};

export default CategoriesFilters;
