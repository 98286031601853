import { FC, useEffect, useState } from "react";
import {
  Circle,
  Divider,
  HStack,
  Icon,
  SlideFade,
  Stack,
  Text,
} from "@chakra-ui/react";
import { FaStar } from "react-icons/fa6";
import MinimizeIconButton from "components/ui/IconButtons/MinimizeIconButton";
import OrderReviewForm from "./OrderReviewForm";
import useQueryParams from "hooks/useQueryParams";

interface Props {
  orderId: string;
}
const OrderReviewAction: FC<Props> = ({ orderId }) => {
  const [isMinimized, setIsMinimized] = useState(false);
  const { getParam } = useQueryParams();
  const highlightParam = getParam("highlight");

  useEffect(() => {
    if (highlightParam) setIsMinimized(false);
  }, [highlightParam]);

  return (
    <Stack spacing={4}>
      <HStack justify="space-between" align="center">
        <HStack>
          <Circle bg="orange.100" p={3}>
            <Icon color="orange.700" as={FaStar} />
          </Circle>
          <Text fontWeight="semibold">Please submit a review.</Text>
        </HStack>
        <MinimizeIconButton
          aria-label="Minimize Delivery"
          isMinimized={!isMinimized}
          onClick={() => setIsMinimized(!isMinimized)}
        />
      </HStack>

      {!isMinimized && (
        <SlideFade in={true} offsetY="-20px">
          <Divider color="gray.300" mb={4} />
          <OrderReviewForm orderId={orderId} />
        </SlideFade>
      )}
    </Stack>
  );
};

export default OrderReviewAction;
