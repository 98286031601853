import { Center, Container, Flex, HStack } from "@chakra-ui/react";
import { FC } from "react";
import AppLogo from "./AppLogo";
import { MobileHamburgerMenu } from "./MobileHamburgerMenu";
import { NavMenu } from "./NavMenu";
import NotificationMenu from "./NotificationMenu";
import { ProfileDropdown } from "./ProfileDropdown";
import { useMobileMenuState } from "./useMobileMenuState";
import { useLocalUser } from "hooks/useLocalUser";
interface Props {
  userType?: string;
}

const Header: FC<Props> = () => {
  const [userType] = useLocalUser("userType", "buyer");

  return (
    <Center
      // color={"#4A5568"}
      minH="16"
      py="2"
      boxShadow="xs"
      px={{ base: 10, md: 4 }}
      bg={userType === "buyer" ? "white" : "blue.600"}
    >
      <Flex
        display={{ base: "inherit", lg: "none" }}
        justify="space-between"
        w="full"
      >
        <MobileHeader />
      </Flex>
      <Container display={{ base: "none", lg: "inherit" }} maxW="7xl">
        <DesktopHeader />
      </Container>
    </Center>
  );
};

function DesktopHeader({ userType }: Props) {
  return (
    <Flex w="full" justify="space-between">
      <HStack spacing={4} alignSelf="center">
        <AppLogo isBetaVersion userType={userType} />
        <NavMenu.Desktop />
      </HStack>

      <HStack spacing={{ base: 3, md: 6 }}>
        <NotificationMenu />
        <ProfileDropdown />
      </HStack>
    </Flex>
  );
}

function MobileHeader() {
  const { isMenuOpen, toggle, onCloseModal } = useMobileMenuState();

  return (
    <>
      <>
        <MobileHamburgerMenu onClick={toggle} isOpen={isMenuOpen} />
        <NavMenu.Mobile isOpen={isMenuOpen} onClickNavItem={onCloseModal} />
      </>

      <Flex alignSelf="center">
        <AppLogo isBetaVersion />
      </Flex>

      <HStack spacing={{ base: 3, md: 6 }}>
        <NotificationMenu />
        <ProfileDropdown />
      </HStack>
    </>
  );
}

export default Header;
