import { useQuery } from "@tanstack/react-query";
import { offerRequirementsKeys } from "settings/constants/queryKeys";
import { Requirement } from "types/requirement.types";
import { getOfferRequirements } from "services/offerService";

const useOfferRequirements = (offerId: string) => {
  return useQuery<Requirement[]>({
    queryKey: offerRequirementsKeys(offerId),
    queryFn: () => getOfferRequirements(offerId),
    refetchOnWindowFocus: false,
  });
};

export default useOfferRequirements;
