import { FC } from "react";
import useOrder from "hooks/useOrder";
import DeliveryDetailsCard from "pages/OrderPage/ActivitiesSection/DeliveryActivity/DeliveryDetailsCard";
import useOrderDeliveries from "hooks/useOrderDeliveries";
import SpinnerLoading from "components/ui/Loaders/SpinnerLoading";
import Alert from "components/features/Alert";

interface Props {
  orderId: string;
  showActionButtons?: boolean;
}
const OrderDeliveryDetails: FC<Props> = ({
  orderId,
  showActionButtons = false,
}) => {
  const orderQuery = useOrder(orderId);
  const deliveriesQuery = useOrderDeliveries(orderId);

  if (!!orderQuery.isLoading || !!deliveriesQuery.isLoading)
    return <SpinnerLoading />;
  if (
    !!orderQuery.error ||
    !!deliveriesQuery.error ||
    !orderQuery.data ||
    !deliveriesQuery.data
  )
    return (
      <Alert
        status="error"
        title="Failed to fetch order or deliveries"
        description="Please try hard reload or contact support if error persists"
      />
    );

  const order = orderQuery.data;
  const deliveries = deliveriesQuery.data;

  const deliveryActivities = order.activities.filter(
    (activity) =>
      activity.action === "order_delivered" ||
      activity.action === "delivery_created"
  );
  const latestDeliveryActivity =
    deliveryActivities[deliveryActivities.length - 1];
  const latestDeliveryDetails = deliveries.find(
    (delivery) => delivery._id === latestDeliveryActivity.resourceId
  );

  if (!latestDeliveryDetails)
    return (
      <Alert
        status="error"
        title="Failed to retrieve latest delivery"
        description="Please try hard reload or contact support to find latest delivery details"
      />
    );

  return (
    <DeliveryDetailsCard
      delivery={latestDeliveryDetails}
      order={order}
      showActionButtons={showActionButtons}
    />
  );
};

export default OrderDeliveryDetails;
