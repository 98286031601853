/////////////////////
// Use Fetch Projects Hook
/////////////////////

// Basic Imports

// Design Imports
import useCustomToast from "./useCustomToast";

// Layout and Section Imports

// Data Imports
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

// Custom Hooks and Services
import {
  fetchAllProjects,
  updateProjectsOrder,
} from "services/projectsService";
import { Project } from "types/project.types";
import { projectsQueryKeys } from "settings/constants/queryKeys";

// Interfaces
interface Props {
  status?: Project["status"];
}

// Functions

const useFetchProjects = ({ status }: Props = {}) => {
  const { errorToast } = useCustomToast();
  const {
    data: projects,
    isLoading,
    error,
  } = useQuery<Project[]>({
    queryKey: projectsQueryKeys(status),
    queryFn: () => fetchAllProjects({ status }),
  });
  const queryClient = useQueryClient();

  const orderPriorityMutation = useMutation({
    onMutate: (data: Project[]) => {
      queryClient.setQueryData(projectsQueryKeys(status), data);
    },
    mutationFn: (data: Project[]) => {
      const projectIds = data.map((project) => project._id);
      return updateProjectsOrder(projectIds);
    },
    onError: (error) =>
      errorToast({
        title: "Failed to change priorities.",
        description: error.message,
      }),
  });

  return {
    projects,
    isLoading,
    error,
    onChangePriorities: orderPriorityMutation.mutate,
  };
};

export default useFetchProjects;
