/////////////////////
// New Project Page
/////////////////////

// Basic Imports

// Design Imports
import {
  Box,
  Button,
  ButtonProps,
  // Container,
  HStack,
  Icon,
  Wrap,
} from "@chakra-ui/react";

import ActionButton from "components/sections/ProjectsSection/Table/Body/ActionButton";

// Layout and Section Imports
import { Steps } from "components/ui/steps/Steps";
import ProjectInfoStep from "components/sections/ProjectsSection/Steps/AProjectInfo/ProjectInfoStep";
import DesiredAssetsStep from "components/sections/ProjectsSection/Steps/BDesiredAssets/DesiredAssetsStep";
import OutputStep from "components/sections/ProjectsSection/Steps/COutput/OutputStep";
import StyleStep from "components/sections/ProjectsSection/Steps/DStyle/StyleStep";
import SupportingDocumentsStep from "components/sections/ProjectsSection/Steps/ESupportingDocuments/SupportingDocumentsStep";
import SummaryStep from "components/sections/ProjectsSection/Steps/FSummary/SummaryStep";

import TableHeading from "components/sections/ProjectsSection/Table/TableHeading";
import ProjectsContainer from "components/sections/ProjectsSection/ProjectsContainer";

// Data Imports
import { assets } from "data/projectWizardData";
import ButtonTooltip from "components/ui/common/Tooltips/ButtonTooltip";
import useNewProjectPage, { BtnLoadingProps } from "./useNewProjectPage";
import Alert from "components/features/Alert";
// import { ROUTES } from "settings/constants/routes";
import Loader from "components/ui/common/Loader";
import { useNavigate, useParams } from "react-router-dom";
import { FaCheck } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { BiArrowBack } from "react-icons/bi";
import WrapStep from "components/ui/steps/WrapStep";
import { useEffect, useState } from "react";
// import { fetchProjectByIdAxiosRequest } from "services/projectsService";
// import { render_project_wizard_schema_from_db_project } from "schemas/project_wizard_schema";
import { TOOLTIP } from "settings/constants/projectWizard";
import { useAuth } from "context/AuthProvider";
import useProject from "hooks/useProject";
import { IProjectStatus } from "types/project.types";
import { render_project_wizard_schema_from_db_project } from "schemas/project_wizard_schema";

// Services Imports

// Interfaces

// Functions
const NewProjectsPageById = () => {
  const { id: projectId } = useParams();
  const { btnLoading, isLoading, handlers, stepState, projectContext } =
    useNewProjectPage({ projectId: projectId });
  const { data: project, isLoading: projectLoading } = useProject(projectId);
  const { user, isLoading: userLoading } = useAuth();
  // const [isLoading, setIsLoading] = useState(false);
  const [shouldAllowSave, setShouldAllowSave] = useState({
    isLoading: false,
    isTrue: false,
  }); // DOC: This loading means context api is loading and we can use to disable buttons that send context api state to backend
  const projectStatus = projectContext.projectWizard.projectStatus;

  useEffect(() => {
    if (!project) return;

    const projectWizard = render_project_wizard_schema_from_db_project(project);
    projectContext.onUpdateProjectWizard({
      ...projectContext.projectWizard,
      hasProjectInitialized: true,
      ...projectWizard,
    });

    // return () => {
    //   projectContext.onResetProjectWizard();
    // };
  }, [project]);
  // useEffect(() => {
  //   getAsyncData();
  //   async function getAsyncData() {
  //     try {
  //       setIsLoading(true);
  //       const { data } = await fetchProjectByIdAxiosRequest(projectId);
  //       const currentDraftProject = data?.data?.project;
  //       if (Object.keys(currentDraftProject).length === 0) return;
  //       const projectWizard =
  //         render_project_wizard_schema_from_db_project(currentDraftProject);
  //       projectContext.onUpdateProjectWizard({
  //         ...projectContext.projectWizard,
  //         hasProjectInitialized: true,
  //         ...projectWizard,
  //       });
  //     } catch (error) {
  //       console.log({ error });
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   }

  //   return () => {
  //     projectContext.onResetProjectWizard();
  //   };

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // if (!user || Object.keys(user).length === 0 || !!isLoading) return <Loader />;
  if (!!projectLoading || !!isLoading || !!userLoading) return <Loader />;
  // if (Object.keys(user).length > 0 && !user.verified)
  //   return (
  //     <Container centerContent>
  //       <Alert
  //         w="max"
  //         status="warning"
  //         title="Your account must be verified for creating project."
  //         link={{
  //           label: "Link here to activate ?",
  //           href: ROUTES.ACCOUNT_VERIFY,
  //         }}
  //       />
  //     </Container>
  //   );

  if (!user?.id)
    return (
      <Alert
        status="error"
        title="Authentication failed."
        description="Please try to authenticate again or contact support"
      />
    );
  return (
    <ProjectsContainer>
      <Header
        shouldDisablePositiveButtons={
          !shouldAllowSave.isTrue || shouldAllowSave.isLoading
        }
        projectStatus={projectStatus}
        btnLoading={btnLoading}
        onClick={(status) => {
          if (status === "active") {
            // handlers.submitProjectIntoDB({
            //   projectStatus: status,
            //   shouldRedirect: true,
            // });
            handlers.submitProjectMutation.mutate({
              projectStatus: status,
              shouldRedirect: true,
            });
          }
          if (status === "completed") {
            // handlers.submitProjectIntoDB({
            //   projectStatus: "completed",
            //   shouldRedirect: true,
            // });
            handlers.submitProjectMutation.mutate({
              projectStatus: "completed",
              shouldRedirect: true,
            });
          }
          if (status === "deleted") {
            // handlers.deleteProjectFromDB({ shouldRedirect: true });
            handlers.deleteProjectMutation.mutate({ shouldRedirect: true });
          }
          if (status === "draft") {
            // handlers.addDraftProjectIntoDB({ shouldRedirect: true });
            handlers.addDraftProjectMutation.mutate({ shouldRedirect: true });
          }
          if (status === "save") {
            // handlers.submitProjectIntoDB({ shouldRedirect: true });
            handlers.submitProjectMutation.mutate({ shouldRedirect: true });
          }
        }}
      />

      <Steps activeStep={stepState.activeStep}>
        <WrapStep
          title="Project Info"
          onStepClick={() => stepState.setStep(0)}
          nextStep={() => {
            stepState.nextStep();
            if (!shouldAllowSave.isTrue) return;

            // handlers.saveUpdatedProject();
            handlers.saveUpdatedProjectMutation.mutate();
          }}
        >
          <ProjectInfoStep />
        </WrapStep>
        <DesiredAssetsStep
          assets={assets}
          prevStep={stepState.prevStep}
          onStepClick={() => stepState.setStep(1)}
          nextStep={() => {
            stepState.nextStep();
            // handlers.saveUpdatedProject();
            handlers.saveUpdatedProjectMutation.mutate();
          }}
          isStepLoading={(value) =>
            setShouldAllowSave((prevState) => ({
              ...prevState,
              isLoading: value,
            }))
          }
          onSelected={(selectedItems) =>
            setShouldAllowSave((prevState) => ({
              ...prevState,
              isTrue: selectedItems.length > 0,
            }))
          }
        />

        <WrapStep
          title="Outputs"
          prevStep={stepState.prevStep}
          disableNextButton={!shouldAllowSave.isTrue}
          titleTooltip={
            !shouldAllowSave.isTrue ? TOOLTIP.MINIMUM_DESIRED_ASSETS : ""
          }
          disableNextButtonReason={TOOLTIP.MINIMUM_DESIRED_ASSETS}
          onStepClick={() => shouldAllowSave.isTrue && stepState.setStep(2)}
          nextStep={() => {
            stepState.nextStep();
            // handlers.saveUpdatedProject();
            handlers.saveUpdatedProjectMutation.mutate();
          }}
        >
          <OutputStep />
        </WrapStep>

        <WrapStep
          title="Styles"
          prevStep={stepState.prevStep}
          onStepClick={() => shouldAllowSave.isTrue && stepState.setStep(3)}
          disableNextButton={!shouldAllowSave.isTrue}
          disableNextButtonReason={TOOLTIP.MINIMUM_DESIRED_ASSETS}
          titleTooltip={
            !shouldAllowSave.isTrue ? TOOLTIP.MINIMUM_DESIRED_ASSETS : ""
          }
          nextStep={async () => {
            stepState.nextStep();
            // handlers.saveUpdatedProject();
            handlers.saveUpdatedProjectMutation.mutate();
          }}
        >
          <StyleStep />
        </WrapStep>

        <WrapStep
          title="Supporting Documents"
          prevStep={stepState.prevStep}
          onStepClick={() => shouldAllowSave.isTrue && stepState.setStep(4)}
          disableNextButton={!shouldAllowSave.isTrue}
          disableNextButtonReason={TOOLTIP.MINIMUM_DESIRED_ASSETS}
          titleTooltip={
            !shouldAllowSave.isTrue ? TOOLTIP.MINIMUM_DESIRED_ASSETS : ""
          }
          nextStep={async () => {
            stepState.nextStep();
            // handlers.saveUpdatedProject();
            handlers.saveUpdatedProjectMutation.mutate();
          }}
        >
          <SupportingDocumentsStep userId={user.id} />
        </WrapStep>

        <WrapStep
          title="Summary"
          prevStep={stepState.prevStep}
          onStepClick={() => shouldAllowSave.isTrue && stepState.setStep(5)}
          titleTooltip={
            !shouldAllowSave.isTrue ? TOOLTIP.MINIMUM_DESIRED_ASSETS : ""
          }
          disableNextButton={!shouldAllowSave.isTrue}
          disableNextButtonReason={TOOLTIP.MINIMUM_DESIRED_ASSETS}
          nextStep={() => {
            stepState.nextStep();
          }}
        >
          <SummaryStep userId={user.id} />
        </WrapStep>
      </Steps>

      <Box mt="8" ml="1">
        <ButtonTooltip
          hasArrow
          label={!shouldAllowSave.isTrue && TOOLTIP.MINIMUM_DESIRED_ASSETS}
        >
          <Button
            colorScheme="blue"
            isDisabled={!shouldAllowSave.isTrue}
            isLoading={
              btnLoading.btn === "active" ? btnLoading.isLoading : false
            }
            onClick={() => {
              // handlers.submitProjectIntoDB({
              //   shouldRedirect: true,
              //   projectStatus: "active",
              // });
              handlers.submitProjectMutation.mutate({
                shouldRedirect: true,
                projectStatus: "active",
              });
            }}
          >
            Submit
          </Button>
        </ButtonTooltip>
      </Box>
    </ProjectsContainer>
  );
};

interface Props {
  projectStatus: IProjectStatus;
  btnLoading: BtnLoadingProps;
  onClick: (status: "save" | "revert" | IProjectStatus) => void;
  shouldDisablePositiveButtons?: boolean;
}
function Header({
  projectStatus,
  btnLoading,
  onClick,
  shouldDisablePositiveButtons,
}: Props) {
  if (projectStatus === "paused")
    return (
      <HeaderWrapper
        deleteLoading={btnLoading.btn === "deleted" && btnLoading.isLoading}
        onDelete={() => onClick("deleted")}
      >
        <ButtonTooltip
          placement="top"
          label="It will save project in paused queue"
        >
          <SaveButton
            colorScheme="blue"
            variant="outline"
            rightIcon={undefined}
            title="Save as Paused"
            isLoading={btnLoading.btn === "save" && btnLoading.isLoading}
            onClick={() => onClick("save")}
            isDisabled={shouldDisablePositiveButtons}
          />
        </ButtonTooltip>
        <ButtonTooltip
          placement="top"
          label="It will save project in active queue"
        >
          <Button
            colorScheme="green"
            mr={2}
            isLoading={btnLoading.btn === "active" && btnLoading.isLoading}
            onClick={() => onClick("active")}
            rightIcon={<FaCheck />}
            isDisabled={shouldDisablePositiveButtons}
          >
            Save as Active
          </Button>
        </ButtonTooltip>
      </HeaderWrapper>
    );
  if (projectStatus === "draft")
    return (
      <HeaderWrapper
        deleteLoading={btnLoading.btn === "deleted" && btnLoading.isLoading}
        onDelete={() => onClick("deleted")}
      >
        <ButtonTooltip
          placement="top"
          label="It will save project in draft queue"
        >
          <Button
            colorScheme="blue"
            variant="outline"
            mr={2}
            isLoading={btnLoading.btn === "draft" && btnLoading.isLoading}
            onClick={() => onClick("draft")}
            isDisabled={shouldDisablePositiveButtons}
            // rightIcon={<MdIncompleteCircle />}
          >
            Save As Draft
          </Button>
        </ButtonTooltip>
        <ButtonTooltip
          placement="top"
          label="It will save project in active queue"
        >
          <Button
            colorScheme="green"
            mr={2}
            isLoading={btnLoading.btn === "active" && btnLoading.isLoading}
            onClick={() => onClick("active")}
            rightIcon={<FaCheck />}
            isDisabled={shouldDisablePositiveButtons}
          >
            Save as Active
          </Button>
        </ButtonTooltip>
      </HeaderWrapper>
    );
  return (
    <HeaderWrapper
      deleteLoading={btnLoading.btn === "deleted" && btnLoading.isLoading}
      onDelete={() => onClick("deleted")}
    >
      <ButtonTooltip placement="top" label="It will save your current changes">
        <Button
          colorScheme="blue"
          mr={2}
          isLoading={btnLoading.btn === "save" && btnLoading.isLoading}
          onClick={() => onClick("save")}
          rightIcon={<FaCheck />}
          isDisabled={shouldDisablePositiveButtons}
        >
          Save
        </Button>
      </ButtonTooltip>
    </HeaderWrapper>
  );
}

function HeaderWrapper({ children, deleteLoading, onDelete }: any) {
  const navigate = useNavigate();
  return (
    <Wrap justify="space-between" pb="6" align="center">
      <HStack spacing={4}>
        <Icon
          fontSize="2xl"
          as={BiArrowBack}
          onClick={() => navigate(-1)}
          cursor="pointer"
          userSelect="none"
        />
        <TableHeading>Edit Project Wizard</TableHeading>
      </HStack>
      <HStack>
        <ButtonTooltip label="It will remove this project.">
          <ActionButton
            btnText="Delete"
            colorScheme="red"
            mr={2}
            isLoading={deleteLoading}
            rightIcon={<MdDelete />}
            alertDialog={{
              title: "Delete Project Draft",
              description:
                "Are you sure? This action will delete all the information that you've entered.",
              onSuccess: onDelete,
            }}
          />
        </ButtonTooltip>
      </HStack>
      <HStack>{children}</HStack>
    </Wrap>
  );
}
const SaveButton = ({
  title = "Save",
  colorScheme = "blue",
  ...restProps
}: ButtonProps) => (
  <Button
    mr={2}
    // isLoading={btnLoading.btn === "save" && btnLoading.isLoading}
    // onClick={() => onClick("save")}
    rightIcon={<FaCheck />}
    colorScheme={colorScheme}
    {...restProps}
  >
    {title}
  </Button>
);

export default NewProjectsPageById;
