/////////////////////
// Custom Theme Components
/////////////////////

// Data Imports
import { customCheckboxTheme } from "styles/chakra/customCheckboxTheme";
import { customInputTheme } from "styles/chakra/customInputTheme";
import getChakraColorMode from "utils/getChakraColorMode";
import colors from "../../settings/theme/colors";
import { buttonTheme } from "./buttonTheme";

const components = {
  // Input Field
  Input: customInputTheme,
  Checkbox: customCheckboxTheme,

  /* Button */
  Button: buttonTheme, // Copied from HR Talent (updated version)
  // 👇 This is legacy and will be removed in the future
  // Button: {
  //   baseStyle: (props) => ({
  //     boxShadow: "none",
  //     _focus: {
  //       boxShadow: "none",
  //     },
  //     color:
  //       getChakraColorMode(props) === "light"
  //         ? colors.text.light
  //         : colors.text.dark,
  //   }),
  // },

  /* Form label */
  FormLabel: {
    baseStyle: (props: any) => ({
      color:
        getChakraColorMode(props) === "light"
          ? colors.text.light
          : colors.text.dark,
    }),
  },

  /* Select input fields */
  Select: {
    baseStyle: (props: any) => ({
      field: {
        color:
          getChakraColorMode(props) === "light"
            ? colors.text.light
            : colors.text.dark,
        colorScheme: "gray",
      },
      icon: {
        color:
          getChakraColorMode(props) === "light" ? colors.light : colors.dark,
      },
    }),
  },

  /* Text */
  Text: {
    baseStyle: (props: any) => ({
      color:
        getChakraColorMode(props) === "light"
          ? colors.text.light
          : colors.text.dark,
    }),
    variants: {
      textAsLink: {
        userSelect: "none",
        fontStyle: "italic",
        textDecor: "underline",
        cursor: "pointer",
      },
    },
  },

  /* Heading */
  Heading: {
    baseStyle: {
      textTransform: "uppercase",
    },
    variants: {
      "light-weight": {
        fontWeight: "normal",
      },
      "hero-title": (props: any) => ({
        size: "2xl",
        textColor:
          getChakraColorMode(props) === "light" ? "defaultLight" : "blue.400",
        textTransform: "uppercase",
        fontWeight: "extrabold",
      }),

      "section-title": (props: any) => ({
        textColor:
          getChakraColorMode(props) === "light" ? "defaultLight" : "blue.400",
        textAlign: "center",
        letterSpacing: "tight",
        fontWeight: "extrabold",
      }),
      head1: (props: any) => ({
        textColor:
          getChakraColorMode(props) === "light" ? "defaultLight" : "blue.400",
        my: { base: "4", md: "10" },
        fontSize: "2xl",
      }),
      head2: (props: any) => ({
        textColor:
          getChakraColorMode(props) === "light" ? "defaultLight" : "blue.400",
        my: "2",
        fontSize: "xl",
      }),
    },
  },
};

export default components;
