/////////////////////
// New Project Page
/////////////////////

// Basic Imports
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Design Imports
import { useSteps } from "components/ui/steps/useSteps";
import { useToast } from "@chakra-ui/react";

// Layout and Section Imports

// Data Imports
import { useProject } from "context/project/ProjectProvider";
import useFetchProjects from "hooks/useFetchProjects";

// Custom Hooks and Services
// import { updateArray } from "utils/arrays";
import useCustomToast from "hooks/useCustomToast";
import { ERRORS } from "settings/constants/toastMessages";
import { STATUS } from "settings/constants/project";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { IProjectStatus, Project } from "types/project.types";
import { draftProjectsQueryKeys } from "settings/constants/queryKeys";

// Interfaces
interface Props {
  projectId?: string;
  shouldFetchDraftProjects?: boolean;
}
export interface BtnLoadingProps {
  btn:
    | ""
    | "revert"
    | "save"
    | "delete"
    | "draft"
    | "close"
    | "step"
    | IProjectStatus;
  isLoading: boolean;
}

// Functions
const useNewProjectPage = (props?: Props) => {
  const [btnLoading, setBtnLoading] = useState<BtnLoadingProps>({
    btn: "",
    isLoading: false,
  });
  const {
    nextStep,
    prevStep,
    activeStep,
    reset: resetSteps,
    setStep,
  } = useSteps({ initialStep: 0 });
  const { projects, isLoading, onChangePriorities } = useFetchProjects(
    { status: "draft" }
    // REACT_QUERY_KEYS.DRAFT_PROJECTS,
    // draftProjectsQueryKeys(),
    // () => fetchDraftProjectsAxiosRequest()
    // { enabled: !!props?.shouldFetchDraftProjects }
  );

  const queryClient = useQueryClient();

  const navigate = useNavigate();
  const toast = useToast();
  const { errorToast } = useCustomToast();
  const projectContext = useProject();
  const projectId = props?.projectId || "";

  // useEffect triggers on changing project wizard
  useEffect(() => {
    // If project closes or deletes then default step should be first one
    if (!projectContext.projectWizard.hasProjectInitialized) resetSteps();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectContext.projectWizard.hasProjectInitialized]);

  // Handlers
  const renderProjectPage = (status: string) => {
    if (status === STATUS.DELETE || status === STATUS.ACTIVE || !status)
      return "/projects";
    return `/projects/${status}`;
  };

  const addDraftProjectMutation = useMutation({
    mutationFn: async (props: {
      shouldRedirect?: boolean;
      btnPressed?: "draft" | "close" | "step";
    }) => {
      setBtnLoading({ btn: props?.btnPressed || "draft", isLoading: true });
      const { data, error, statusCode } =
        await projectContext.onSaveProjectWizardAsDraft({
          shouldTrackActivity: !!projectId,
        });

      setBtnLoading({ btn: props?.btnPressed || "draft", isLoading: false });

      if (!!error) throw new Error("");

      return { data, statusCode };
    },
    onError: () => errorToast({ title: "Something went wrong..." }),
    onSuccess: (response, { shouldRedirect }: { shouldRedirect?: boolean }) => {
      const project = response.data.project;

      if (!!shouldRedirect) {
        return navigate(`${renderProjectPage(project.status)}`);
      }

      queryClient.invalidateQueries({ queryKey: draftProjectsQueryKeys() });

      // 200 --> Updated successfully
      // if (response.statusCode === 200) {
      // setProjects((projects) => {
      //   const targetIndex = projects.findIndex((p) => p.id === project._id);
      //   const updatedProjects = updateArray(
      //     projects,
      //     targetIndex,
      //     project_table_schema(project)
      //   );
      //   return updatedProjects;
      // });
      // return;
      // }
      // 201 --> Created successfully
      // if (response.statusCode === 201) {
      // setProjects((projects) => [project_table_schema(project), ...projects]);
      // }
    },
  });

  // const addDraftProjectIntoDB = async (props?: {
  //   btnPressed?: "draft" | "close" | "step";
  //   shouldRedirect?: boolean;
  // }) => {
  //   const btnPressed = props?.btnPressed;

  //   setBtnLoading({ btn: btnPressed || "draft", isLoading: true });
  //   const { data, error, statusCode } =
  //     await projectContext.onSaveProjectWizardAsDraft({
  //       shouldTrackActivity: !!projectId,
  //     });
  //   setBtnLoading({ btn: btnPressed || "draft", isLoading: false });

  //   if (!!error) {
  //     errorToast({ title: "Something went wrong..." });
  //     return;
  //   }

  //   const project = data.data.project;

  //   if (!!props?.shouldRedirect) {
  //     return navigate(`${renderProjectPage(project.status)}`);
  //   }

  //   // 200 --> Updated successfully
  //   if (statusCode === 200) {
  //     setProjects((projects) => {
  //       const targetIndex = projects.findIndex((p) => p.id === project._id);
  //       const updatedProjects = updateArray(
  //         projects,
  //         targetIndex,
  //         project_table_schema(project)
  //       );
  //       return updatedProjects;
  //     });
  //     return;
  //   }
  //   // 201 --> Created successfully
  //   if (statusCode === 201) {
  //     setProjects((projects) => [project_table_schema(project), ...projects]);
  //   }
  // };

  const submitProjectMutation = useMutation({
    mutationFn: async ({
      projectStatus,
      shouldDefaultProjectStatus,
    }: {
      projectStatus?: IProjectStatus;
      shouldRedirect?: boolean;
      shouldDefaultProjectStatus?: boolean;
    }) => {
      const { data, error } = await projectContext.onSubmitProjectWizard({
        projectStatus: shouldDefaultProjectStatus
          ? projectContext.projectWizard.projectStatus
          : projectStatus,
        shouldTrackActivity: !!projectId,
      });

      if (!!error) {
        throw new Error("");
      }

      return data;
    },
    onError: () => {
      errorToast({ title: ERRORS.GENERIC });
    },
    onSuccess: (data, { shouldRedirect }) => {
      if (!!shouldRedirect) {
        projectContext.onCloseProjectWizard();
        navigate(renderProjectPage(data.data.project.status));
      }
    },
  });

  const deleteProjectMutation = useMutation({
    mutationFn: async () => {
      const { data, error } = await projectContext.onDeleteProjectWizard();
      if (!!error) throw new Error();

      return data;
    },
    onSuccess: (
      project: Project,
      { shouldRedirect }: { shouldRedirect?: boolean }
    ) => {
      queryClient.setQueryData(
        draftProjectsQueryKeys(),
        (prevProjects: Project[]) =>
          prevProjects.filter((p) => p._id !== project._id)
      );

      if (!!shouldRedirect) {
        navigate("/projects");
      }
    },
  });

  // const deleteProjectFromDB = async (props?: { shouldRedirect: boolean }) => {
  //   setBtnLoading({ btn: "delete", isLoading: true });
  //   const { data, error } = await projectContext.onDeleteProjectWizard();
  //   setBtnLoading({ btn: "delete", isLoading: false });

  //   if (!!props?.shouldRedirect) {
  //     return navigate("/projects");
  //   }

  //   if (!!error) {
  //     errorToast({ title: ERRORS.DELETE_PROJECT });
  //     return;
  //   }

  //   if (!!data && !error) {
  //     const project = data.data;

  //     const filteredProjects = projects.filter((p) => p.id !== project.id);
  //     setProjects(filteredProjects);
  //   }
  // };

  const saveUpdatedProjectMutation = useMutation({
    mutationFn: async () => {
      const { error } = await projectContext.onSaveUpdatedProjectWizard({
        shouldTrackActivity: !!props?.projectId,
      });

      if (error) throw new Error("");
    },
    onError: () => errorToast({ title: ERRORS.UPDATE_PROJECT }),
  });

  // const saveUpdatedProject = async () => {
  //   setBtnLoading({ btn: "save", isLoading: true });
  //   const { error } = await projectContext.onSaveUpdatedProjectWizard({
  //     shouldTrackActivity: !!props.projectId,
  //   });
  //   setBtnLoading({ btn: "save", isLoading: false });

  //   if (!!error) {
  //     errorToast({ title: ERRORS.UPDATE_PROJECT });
  //     return;
  //   }
  // };

  return {
    btnLoading,
    setBtnLoading,
    isLoading,
    stepState: { nextStep, prevStep, activeStep, reset: resetSteps, setStep },
    draftProjects: {
      projects: projects || [],
      isLoading,
      onChangePriorities,
      // setProjects
    },
    projectContext,
    toast,
    navigate,
    handlers: {
      // addDraftProjectIntoDB,
      addDraftProjectMutation,
      // submitProjectIntoDB,
      submitProjectMutation,
      // deleteProjectFromDB,
      deleteProjectMutation,
      // saveUpdatedProject,
      saveUpdatedProjectMutation,
    },
  };
};

export default useNewProjectPage;
