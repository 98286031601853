import { useQuery } from "@tanstack/react-query";
import { getOfferByProfileId } from "services/profilesService";
import { profileOffersQueryKeys } from "settings/constants/queryKeys";

const useProfileOffers = ({ profileId }: { profileId: string }) => {
  return useQuery({
    queryKey: profileOffersQueryKeys(profileId),
    queryFn: () => getOfferByProfileId(profileId),
    enabled: !!profileId,
  });
};

export default useProfileOffers;
