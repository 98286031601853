import {
  Box,
  Text,
  Container,
  HStack,
  Spinner,
  Stack,
  VStack,
  Avatar,
  Flex,
  Alert,
  Center,
} from "@chakra-ui/react";
import useProfile from "hooks/useProfile";
import Expertise from "pages/ProfilesPage/ProfileSection/Expertise";
import GallerySection from "pages/ProfilesPage/ProfileSection/GallerySection";
import ProfileOffers from "pages/ProfilesPage/ProfileSection/ProfileOffers";
import { useParams } from "react-router-dom";
import { getAssetLabel } from "utils/profile";

const UserProfilePage = () => {
  const params = useParams();
  const { data, error, isLoading } = useProfile({
    profileId: params.profileId || "",
  });

  if (!!error)
    return (
      <Container centerContent>
        <Alert status="error" title={"Something went wrong"} />
      </Container>
    );
  if (!!isLoading)
    return (
      <Container centerContent>
        <Spinner />
      </Container>
    );
  if (!data?.profile)
    return (
      <Container centerContent>
        <Alert status="error" title={"Profile not found."} />
      </Container>
    );

  return (
    <Stack>
      <Container maxW="container.xl" my={10} px={{ base: 5, md: 10 }}>
        <Stack
          spacing={{ md: 9, sm: 4 }}
          backgroundColor="#F8F8F8"
          rounded="2xl"
          p={4}
        >
          <HStack spacing={2} flexDirection="row" alignItems="start">
            <HStack alignItems="center" spacing={5}>
              <Box pos="relative">
                <Avatar size="xl" src={data?.profile.avatar || ""} />
                <Box
                  bg="green.400"
                  boxSize={4}
                  rounded="full"
                  position="absolute"
                  right={0.5}
                  bottom={2}
                  border="2px solid"
                  borderColor="white"
                />
              </Box>
              <VStack spacing={2} flexDirection="column" align="start" gap="1">
                <Text fontSize="xl" fontWeight="bold" color="black">
                  {data?.profile?.title}
                </Text>
              </VStack>
            </HStack>
          </HStack>

          <VStack align="start">
            {data && (
              <VStack align={"start"} spacing={{ md: 4, sm: 2 }}>
                {data.profile?.availability && (
                  <Flex alignItems="center" justifyContent="space-evenly">
                    <Center
                      rounded="full"
                      px="2"
                      fontSize="sm"
                      border="1px solid"
                      borderColor={"gray.500"}
                    >
                      {getAssetLabel(data.profile.availability.assetsPerWeek)}
                    </Center>
                  </Flex>
                )}

                <Expertise defaultProfile={data.profile} />
                <Text noOfLines={6} color="gray.500">
                  {data.profile?.description}
                </Text>
              </VStack>
            )}
          </VStack>
        </Stack>
        {data.profile?.galleryId && (
          <GallerySection gallery={data.profile.galleryId.attachments} />
        )}
        <ProfileOffers profileId={data.profile.id} />
      </Container>
    </Stack>
  );
};

export default UserProfilePage;
