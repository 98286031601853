import axios from "libraries/axios";
import { OrderMessage } from "types/message.types";

export async function getOrderMessages(
  orderId: string
): Promise<OrderMessage[]> {
  const response = await axios.get(`/orders/${orderId}/messages`);
  return response.data.data.messages;
}

export async function createOrderMessage(
  body: Partial<Omit<OrderMessage, "attachments"> & { attachments: string[] }>
): Promise<OrderMessage> {
  const response = await axios.post(`/messages`, body);
  return response.data.data.message;
}
