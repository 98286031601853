/////////////////////
// Project Reducer Context
/////////////////////

// Basic Imports

// Design Imports

// Layout and Section Imports

// Data Imports
import {
  SET_COMPLETION_STAGE,
  SET_DRAFT_PROJECTS,
  SET_HAS_PROJECT_INITIALIZED,
  // SET_ITEMS,
  SET_NEW_SELECTED_ASSETS,
  SET_PROJECT_ADDRESS_LINE_1,
  SET_PROJECT_ADDRESS_LINE_2,
  SET_PROJECT_BATHROOMS,
  SET_PROJECT_BEDROOMS,
  SET_PROJECT_CITY,
  SET_PROJECT_COUNTRY,
  SET_PROJECT_DEPTH,
  SET_PROJECT_DESCRIPTION,
  SET_PROJECT_EMAIL,
  SET_PROJECT_ID,
  SET_PROJECT_INFO,
  SET_PROJECT_NAME,
  SET_PROJECT_POSTAL_CODE,
  SET_PROJECT_SIZE,
  SET_PROJECT_STATE,
  SET_PROJECT_STATUS,
  SET_PROJECT_STORIES,
  SET_PROJECT_STREET_ADDRESS,
  SET_PROJECT_WIDTH,
  SET_PROJECT_WIZARD,
  SET_SELECTED_ASSETS,
  SET_SELECTED_OUTPUTS,
  SET_SELECTED_STYLES,
  SET_SUPPORTING_DOCUMENTS,
} from "./ProjectTypes";

// Custom Hooks and Services

// Interfaces

// Functions
export const reducer = (state: any, action: any) => {
  switch (action.type) {
    case SET_PROJECT_ID:
      return {
        ...state,
        projectWizard: { ...state.projectWizard, id: action.payload },
      };
    case SET_PROJECT_STATUS:
      return {
        ...state,
        projectWizard: { ...state.projectWizard, status: action.payload },
      };
    case SET_HAS_PROJECT_INITIALIZED:
      return {
        ...state,
        projectWizard: {
          ...state.projectWizard,
          hasProjectInitialized: action.payload,
        },
      };
    case SET_PROJECT_INFO:
      return {
        ...state,
        projectWizard: { ...state.projectWizard, projectInfo: action.payload },
      };

    // PROJECT INFO
    case SET_PROJECT_NAME:
      return {
        ...state,
        projectWizard: {
          ...state.projectWizard,
          projectInfo: {
            ...state.projectWizard.projectInfo,
            name: action.payload,
          },
        },
      };
    case SET_PROJECT_STREET_ADDRESS:
      return {
        ...state,
        projectWizard: {
          ...state.projectWizard,
          projectInfo: {
            ...state.projectWizard.projectInfo,
            streetAddress: action.payload,
          },
        },
      };
    case SET_PROJECT_DESCRIPTION:
      return {
        ...state,
        projectWizard: {
          ...state.projectWizard,
          projectInfo: {
            ...state.projectWizard.projectInfo,
            description: action.payload,
          },
        },
      };
    case SET_PROJECT_EMAIL:
      return {
        ...state,
        projectWizard: {
          ...state.projectWizard,
          projectInfo: {
            ...state.projectWizard.projectInfo,
            emails: action.payload,
          },
        },
      };
    case SET_PROJECT_ADDRESS_LINE_1:
      return {
        ...state,
        projectWizard: {
          ...state.projectWizard,
          projectInfo: {
            ...state.projectWizard.projectInfo,
            addressLine1: action.payload,
          },
        },
      };
    case SET_PROJECT_ADDRESS_LINE_2:
      return {
        ...state,
        projectWizard: {
          ...state.projectWizard,
          projectInfo: {
            ...state.projectWizard.projectInfo,
            addressLine2: action.payload,
          },
        },
      };
    case SET_PROJECT_CITY:
      return {
        ...state,
        projectWizard: {
          ...state.projectWizard,
          projectInfo: {
            ...state.projectWizard.projectInfo,
            city: action.payload,
          },
        },
      };
    case SET_PROJECT_STATE:
      return {
        ...state,
        projectWizard: {
          ...state.projectWizard,
          projectInfo: {
            ...state.projectWizard.projectInfo,
            state: action.payload,
          },
        },
      };
    case SET_PROJECT_COUNTRY:
      return {
        ...state,
        projectWizard: {
          ...state.projectWizard,
          projectInfo: {
            ...state.projectWizard.projectInfo,
            country: action.payload,
          },
        },
      };
    case SET_PROJECT_POSTAL_CODE:
      return {
        ...state,
        projectWizard: {
          ...state.projectWizard,
          projectInfo: {
            ...state.projectWizard.projectInfo,
            postalCode: action.payload,
          },
        },
      };
    case SET_PROJECT_BEDROOMS:
      return {
        ...state,
        projectWizard: {
          ...state.projectWizard,
          projectInfo: {
            ...state.projectWizard.projectInfo,
            bedrooms: action.payload,
          },
        },
      };
    case SET_PROJECT_BATHROOMS:
      return {
        ...state,
        projectWizard: {
          ...state.projectWizard,
          projectInfo: {
            ...state.projectWizard.projectInfo,
            bathrooms: action.payload,
          },
        },
      };
    case SET_PROJECT_STORIES:
      return {
        ...state,
        projectWizard: {
          ...state.projectWizard,
          projectInfo: {
            ...state.projectWizard.projectInfo,
            stories: action.payload,
          },
        },
      };
    case SET_PROJECT_SIZE:
      return {
        ...state,
        projectWizard: {
          ...state.projectWizard,
          projectInfo: {
            ...state.projectWizard.projectInfo,
            size: action.payload,
          },
        },
      };
    case SET_PROJECT_WIDTH:
      return {
        ...state,
        projectWizard: {
          ...state.projectWizard,
          projectInfo: {
            ...state.projectWizard.projectInfo,
            width: action.payload,
          },
        },
      };
    case SET_PROJECT_DEPTH:
      return {
        ...state,
        projectWizard: {
          ...state.projectWizard,
          projectInfo: {
            ...state.projectWizard.projectInfo,
            depth: action.payload,
          },
        },
      };

    case SET_SELECTED_ASSETS:
      return {
        ...state,
        projectWizard: {
          ...state.projectWizard,
          selectedAssets: action.payload,
        },
      };
    case SET_SELECTED_OUTPUTS:
      return {
        ...state,
        projectWizard: {
          ...state.projectWizard,
          selectedOutputs: action.payload,
        },
      };
    case SET_SELECTED_STYLES:
      return {
        ...state,
        projectWizard: {
          ...state.projectWizard,
          selectedStyles: action.payload,
        },
      };
    case SET_SUPPORTING_DOCUMENTS:
      return {
        ...state,
        projectWizard: {
          ...state.projectWizard,
          supportingDocuments: action.payload,
        },
      };
    case SET_COMPLETION_STAGE:
      return { ...state, completionStage: action.payload };
    case SET_DRAFT_PROJECTS:
      return { ...state, draftProjects: action.payload };
    case SET_PROJECT_WIZARD:
      return { ...state, projectWizard: action.payload };
    case SET_NEW_SELECTED_ASSETS:
      return { ...state, newSelectedAssets: action.payload };
    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
};
