import { useState } from "react";
import { Box, Center, ImageProps, Image, Spinner } from "@chakra-ui/react";

interface ImageWithLoaderProps extends ImageProps {
  src: string;
  alt: string;
}

const ImageWithLoader: React.FC<ImageWithLoaderProps> = ({
  src,
  alt,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <Box w="full" h="full">
      {isLoading && (
        <Center
          className="loading"
          height="100%"
          width="100%"
          position="absolute"
        >
          <Spinner />
        </Center>
      )}
      <Image
        src={src}
        alt={alt}
        objectFit="cover"
        onLoad={() => setIsLoading(false)}
        display={isLoading ? "none" : "block"}
        {...props}
      />
    </Box>
  );
};

export default ImageWithLoader;
