import { useQuery } from "@tanstack/react-query";
import { getOrderRevisions } from "services/revisionService";
import { orderRevisions } from "settings/constants/queryKeys";

const useOrderRevisions = (orderId: string) => {
  return useQuery({
    queryKey: orderRevisions(orderId),
    queryFn: () => getOrderRevisions(orderId),
  });
};

export default useOrderRevisions;
