import { useQuery } from "@tanstack/react-query";
import { getOfferPrices } from "services/gigsService";
import { offerPricesQueryKeys } from "settings/constants/queryKeys";

const useOfferPrices = (offerId: string) => {
  return useQuery({
    queryKey: offerPricesQueryKeys(offerId),
    queryFn: () => getOfferPrices(offerId),
    enabled: !!offerId,
  });
};

export default useOfferPrices;
