import { FC } from "react";
import _ from "lodash";
import { Avatar, Box, Center, Flex, Stack, Text } from "@chakra-ui/react";
import { getAssetLabel } from "utils/profile";

interface Props {
  avatar: string;
  title: string;
  description: string;
  availability: {
    availableNow?: boolean;
    weeklyHours?: number;
    assetsPerWeek?: number;
  };
}
const ProfileCardInfo: FC<Props> = ({
  avatar,
  title,
  description,
  availability,
}) => {
  if (!title) return null;
  return (
    <Flex gap="4" align="start" direction="column">
      <Stack gap={1}>
        <Flex gap="2" align="center">
          <Box rounded="full" border="2px solid" borderColor="gray.200">
            <Avatar boxSize="7" src={avatar || ""} />
          </Box>
          <Box
            as="h1"
            fontWeight="500"
            noOfLines={1}
            fontSize="smaller"
            color="gray"
          >
            {title}
          </Box>
        </Flex>
        <Box as="h2" color="black" fontSize="md" noOfLines={2} h="3rem">
          <Text noOfLines={2}>{description}</Text>
        </Box>
      </Stack>
      <Flex alignItems="center" justifyContent="space-evenly">
        <Center
          rounded="full"
          px="2"
          fontSize="sm"
          border="1px solid"
          borderColor={"gray.500"}
        >
          {getAssetLabel(availability.assetsPerWeek)}
        </Center>
      </Flex>
    </Flex>
  );
};

export default ProfileCardInfo;
