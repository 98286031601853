import axios from "libraries/axios";
import { OrderRating } from "types/rating.types";

export async function getOrderRatings(orderId: string): Promise<OrderRating[]> {
  const response = await axios.get(`/orders/${orderId}/ratings`);
  return response.data.data.ratings;
}

export async function createOrderRating(
  data: Partial<OrderRating>
): Promise<OrderRating> {
  const response = await axios.post(`/ratings`, data);
  return response.data.data.rating;
}
