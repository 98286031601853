import { Box } from "@chakra-ui/react";

interface NotificationTagProps {
  priority: string; // Assuming priority is a string, adjust as needed
  children: React.ReactNode; // This type is appropriate for anything renderable
}

export const NotificationTag: React.FC<NotificationTagProps> = ({
  priority,
  children,
}) => {
  // Function to determine color based on priority
  function getTagColor(priority: string) {
    switch (priority.toLowerCase()) {
      case "high":
        return { color: "red.500", bg: "red.100" }; // Red for high priority
      case "medium":
        return { color: "green.500", bg: "green.100" }; // Green for medium priority
      case "low":
        return { color: "blue.500", bg: "blue.100" }; // Blue for low priority
      default:
        return { color: "gray.500", bg: "gray.100" }; // Default styling
    }
  }
  const { color, bg } = getTagColor(priority);

  return (
    <Box
      color={color}
      bg={bg}
      fontSize="10px"
      textTransform="uppercase"
      rounded="lg"
      px={2}
      fontWeight="bold"
    >
      {children}
    </Box>
  );
};
