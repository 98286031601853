import { Stack } from "@chakra-ui/react";
import React, { FC, isValidElement, ReactNode } from "react";

interface Props {
  children: ReactNode;
}
const OrderDetailsActionButtons: FC<Props> = ({ children }) => {
  const hasValidChildren = React.Children.toArray(children).some((child) =>
    isValidElement(child)
  );

  // If there are no valid children, do not render the card
  if (!hasValidChildren) {
    return null;
  }

  return <Stack mt={2}>{children}</Stack>;
};

export default OrderDetailsActionButtons;
