/////////////////////
// Verify Page
/////////////////////

// Basic Imports
import { useParams, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useMutation } from "@tanstack/react-query";

// Design Imports
import { Center, Progress } from "@chakra-ui/react";

// Layout and Section Imports
import AuthLayout from "components/layouts/AuthLayout";
import FormHeader from "components/forms/AuthForm/FormHeader";
import { verification } from "services/authService";
import Alert from "components/features/Alert";
import { ROUTES } from "settings/constants/routes";

// Data Imports

// Interfaces

// Custom Hooks and Services
import useCustomToast from "hooks/useCustomToast";

// Functions
const VerifyPage = () => {
  const { successToast } = useCustomToast();
  const { token } = useParams();
  const navigate = useNavigate();

  const { mutate, error } = useMutation({
    mutationFn: ({ token }: { token: string }) => verification({ token }),
    onSuccess: () => {
      successToast({
        title: "Verified successfully.",
        description: "Redirecting to login page...",
      });

      setTimeout(() => {
        navigate(ROUTES.LOGIN_IN, { replace: true });
      }, 1000);
    },
    onError: (error) => {
      console.log({ error });
    },
  });

  useEffect(() => {
    if (!!token) {
      mutate({ token });
    }
  }, [token]);

  if (!!error)
    return (
      <AuthLayout>
        <Center>
          <Alert
            status={"error"}
            title={"Failed to verify your account."}
            description={error?.message}
            showCloseIcon={false}
          />
        </Center>
      </AuthLayout>
    );

  return (
    <AuthLayout>
      <Center>
        <FormHeader
          title="Verifying your account"
          description="Redirecting..."
        />
        <Progress size="xs" isIndeterminate />
      </Center>
    </AuthLayout>
  );
};

export default VerifyPage;
