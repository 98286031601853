/////////////////////
// Index Page
/////////////////////

// Basic Imports
import React from "react";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Provider } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from "./App";
import { ChakraProvider } from "@chakra-ui/react";
import { PersistGate } from "redux-persist/integration/react";

// Design Imports
import "./styles/global.css";
import customTheme from "./styles/theme";

import { persistor, store } from "./app/store";

// Custom Hooks and Services
import { AuthProvider } from "./context/AuthProvider";
import { ProjectProvider } from "context/project/ProjectProvider";

// Create a client
const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <ChakraProvider theme={customTheme}>
              <ProjectProvider>
                <AuthProvider>
                  <Routes>
                    <Route path="*" element={<App />} />
                  </Routes>
                </AuthProvider>
              </ProjectProvider>
            </ChakraProvider>
          </PersistGate>
        </Provider>
      </BrowserRouter>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </React.StrictMode>
);
