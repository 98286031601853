import { useQuery } from "@tanstack/react-query";
import { getMultipleAttachmentsByIds } from "services/attachmentsService";
import { attachmentKeys } from "settings/constants/queryKeys";

const useAttachmentsByIds = (ids: string[]) => {
  return useQuery({
    queryKey: attachmentKeys(ids),
    queryFn: () => getMultipleAttachmentsByIds(ids),
    enabled: !!ids && ids.length > 0,
  });
};

export default useAttachmentsByIds;
