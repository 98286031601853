import { Container, Heading, Stack } from "@chakra-ui/react";
import RenderProfiles from "./RenderProfiles";
import SearchInputField from "components/ui/common/SearchInputField";
import CategoriesFilters from "pages/OffersPage/CategoriesFilters";
import { useState } from "react";

const ProfilesPage = () => {
  const [search, setSearch] = useState("");

  const onSearch = (value: string) => setSearch(value);
  const onClearSearch = () => setSearch("");

  return (
    <Container maxW="container.xl" centerContent pt={6}>
      <Stack
        justifyContent="center"
        spacing={{ base: 6, md: 10 }}
        alignItems="center"
        w="full"
      >
        <Stack
          w="full"
          align="center"
          spacing={6}
          py={6}
          bg="white"
          rounded="lg"
        >
          <Heading color="black" textTransform="capitalize" textAlign="left">
            All Public Profiles
          </Heading>
          <Stack w={{ base: "90%", md: "80%" }}>
            <SearchInputField
              value={search}
              onChangeValue={onSearch}
              onClearValue={onClearSearch}
            />
            <CategoriesFilters />
          </Stack>
        </Stack>
        <RenderProfiles search={search} />
      </Stack>
    </Container>
  );
};

export default ProfilesPage;
