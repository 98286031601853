/////////////////////
// Forgot (Password) Form
/////////////////////

// Basic Imports
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

// Design Imports
import { Button, Center, Stack, StackProps, Text } from "@chakra-ui/react";

import FormAlert from "../FormAlert";

// Layout and Section Imports
import useFormState, { ForgotFormTypes } from "../useAuthForm";
import FormHeader from "../FormHeader";
import FormWrapper from "../FormWrapper";

// Data Imports
import FormInputField from "components/forms/ReactHookForm/FormInputField";
import { ROUTES } from "settings/constants/routes";

// Interfaces

// Custom Hooks and Services

// Functions

const schema = yup.object().shape({
  email: yup.string().email().required().label("Email"),
});

export const ForgotForm = (props: StackProps) => {
  const methods = useForm<ForgotFormTypes>({ resolver: yupResolver(schema) });
  const { forgotPasswordMutation } = useFormState();

  if (!!forgotPasswordMutation.isSuccess)
    return (
      <Center>
        <Stack>
          <FormAlert
            title="Forgot password"
            description="Please check you email, you have to click on link to redirect for reset password"
          />
          <Text textAlign="center">
            Did't you receive reset password email yet ?
            <Text
              cursor="pointer"
              userSelect="none"
              textDecor="underline"
              fontStyle="italic"
              as="span"
              mx="2"
              onClick={() =>
                forgotPasswordMutation.mutate({
                  email: methods.getValues()["email"],
                })
              }
            >
              {forgotPasswordMutation.isPending ? "Sending..." : "Resend"}
            </Text>
          </Text>
        </Stack>
      </Center>
    );
  return (
    <Center>
      <Stack spacing="8" {...props}>
        <FormHeader
          title="Lost your password?"
          description="Already have an account?"
          link={{
            label: "Sign in",
            to: ROUTES.LOGIN_IN,
          }}
        />

        <FormProvider {...methods}>
          <FormWrapper
            onSubmit={methods.handleSubmit((data) =>
              forgotPasswordMutation.mutate(data)
            )}
          >
            <Stack spacing="5">
              <FormInputField
                type="email"
                name="email"
                label="Email"
                placeholder="Enter your mail"
              />
            </Stack>
            <Stack spacing="4">
              <Button
                variant="primary"
                color="white"
                bg="blue.500"
                _hover={{ backgroundColor: "blue.600" }}
                type="submit"
                isLoading={forgotPasswordMutation.isPending}
              >
                Reset Password
              </Button>
            </Stack>
          </FormWrapper>
        </FormProvider>
      </Stack>
    </Center>
  );
};
