/////////////////////
// Drag Drop Table
/////////////////////

// Basic Imports
import { useMemo, useState } from "react";

// Design Imports
import {
  Table,
  Tbody,
  Tr,
  Td,
  Alert,
  AlertIcon,
  Thead,
  Th,
} from "@chakra-ui/react";
import TableHeading from "../TableHeading";

import SkeletonLoading from "components/ui/Loaders/SkeletonLoading";

// Layout and Section Imports
import { useTable, Row, HeaderGroup } from "react-table";
import { DraggableTableRow } from "./DraggableTableRow";
import { StaticTableRow } from "./StaticTableRow";

// Data Imports

// Custom Hooks and Services
import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";

import { restrictToVerticalAxis } from "@dnd-kit/modifiers";

import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

// Interfaces
interface Props {
  columns: any[];
  data: any[];
  updateDataOnPriority?: (data: any) => void;
  setData?: (data: any) => void;
  title?: string;
  isLoading?: boolean;
}

// Functions
function DragDropTable({
  columns,
  data,
  updateDataOnPriority,
  setData,
  title,
  isLoading,
}: Props) {
  const [activeId, setActiveId] = useState();
  const items = useMemo(() => data?.map(({ id }) => id), [data]);

  const updateMyData = (rowIndex: string, columnId: string, value: string) => {
    if (setData) {
      setData((old: any) =>
        old.map((row: any, index: number) => {
          if (index === Number(rowIndex)) {
            return {
              ...old[rowIndex],
              [columnId]: value,
            };
          }
          return row;
        })
      );
    }
  };

  const { getTableProps, getTableBodyProps, rows, prepareRow, headerGroups } =
    useTable({
      columns,
      data,
      // @ts-ignore
      updateMyData,
      updateRows: (_: any, updatedData: any) => setData && setData(updatedData),
    });
  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {})
  );

  function handleDragStart(event: any) {
    setActiveId(event.active.id);
  }

  function handleDragEnd(event: any) {
    const { active, over } = event;
    if (active.id !== over.id) {
      // updateDataOnPriority((data) => {
      const oldIndex = items.indexOf(active.id);
      const newIndex = items.indexOf(over.id);

      const updatedArray = arrayMove(data, oldIndex, newIndex);
      const priorityUpdatedArray = updatedArray.map(
        (item: any, index: number) => {
          return { ...item, priority: index + 1 };
        }
      );

      if (updateDataOnPriority) {
        updateDataOnPriority(priorityUpdatedArray);
      }
    }

    setActiveId(undefined);
  }

  function handleDragCancel() {
    setActiveId(undefined);
  }

  const selectedRow = useMemo(() => {
    if (!activeId) {
      return null;
    }
    const row = rows.find(({ original }) => original.id === activeId);
    if (!row) {
      return;
    }
    prepareRow(row);
    return row;
  }, [activeId, rows, prepareRow]);

  if (!!isLoading)
    return (
      <>
        {title && <TableHeading>{title}</TableHeading>}
        <SkeletonLoading />
      </>
    );
  // Render the UI for your table
  return (
    <>
      {title && <TableHeading>{title}</TableHeading>}
      <DndContext
        sensors={sensors}
        onDragEnd={handleDragEnd}
        onDragStart={handleDragStart}
        onDragCancel={handleDragCancel}
        collisionDetection={closestCenter}
        modifiers={[restrictToVerticalAxis]}
      >
        <Table
          variant="striped"
          fontSize={{ base: "xs", lg: "md" }}
          p="4"
          {...getTableProps()}
        >
          <Thead>
            {headerGroups.map((headerGroup: HeaderGroup) => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <Th {...column.getHeaderProps()} border="none">
                    {column.render("Header")}
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            <SortableContext
              items={items}
              strategy={verticalListSortingStrategy}
            >
              {rows.length > 0 ? (
                rows.map((row: Row) => {
                  prepareRow(row);
                  // @ts-ignore
                  return <DraggableTableRow key={row.original.id} row={row} />;
                })
              ) : (
                <Tr>
                  <Td colSpan={5} rounded="xl" border="none">
                    <Alert status="info" rounded="xl">
                      <AlertIcon />
                      Nothing to show
                    </Alert>
                  </Td>
                </Tr>
              )}
            </SortableContext>
          </Tbody>
        </Table>
        <DragOverlay>
          {activeId && (
            <Table fontSize={{ base: "xs", lg: "md" }} p="4">
              <Tbody>
                <StaticTableRow row={selectedRow} />
              </Tbody>
            </Table>
          )}
        </DragOverlay>
      </DndContext>
    </>
  );
}

export default DragDropTable;
