///////////////////////////////
// Project Table: Action Column
///////////////////////////////

// Basic Imports
import { useState } from "react";
import { useNavigate } from "react-router-dom";

// Design Imports
import { HStack, Icon, Text, Flex, Spacer } from "@chakra-ui/react";

import { GrTransaction } from "react-icons/gr";
import { GoPencil } from "react-icons/go";
import {
  FaPlay,
  FaPause,
  FaCheck,
  FaFileDownload,
  FaUndo,
  FaRegPaperPlane,
} from "react-icons/fa";

import ActionButton from "../ActionButton";

// Layout and Section Imports

// Data Imports
import { NEW_PROJECT_PAGE } from "settings/site-navigation";
import { TABLE_NAME } from "settings/constants/QueryTable";
import { STATUS } from "settings/constants/project";

// Custom Hooks and Services
import { updateProjectByStatus } from "services/projectsService";
import { updateArray } from "utils/arrays";
import useCustomToast from "hooks/useCustomToast";
import { ERRORS, SUCCESS } from "settings/constants/toastMessages";

// Functions

// Column Header
const Header = () => {
  return (
    <HStack>
      <Icon as={GrTransaction} />
      <Text>Actions</Text>
    </HStack>
  );
};

// Button names in Constants
const RESUME = "Resume";
const PAUSE = "Pause";
const COMPLETE = "Complete";
const SUBMIT = "Submit";
const DOWNLOAD = "Download";
const REVERT = "Revert";
const EDIT = "Edit";

// Column Cell
const Cell = (props: any) => {
  const { successToast, errorToast } = useCustomToast();
  const [btnLoading, setBtnLoading] = useState({
    btn: "Resume",
    isLoading: false,
  });
  const {
    row: { original: selectedRow },
    updateRows,
  } = props;
  const { state, id: projectId }: any = selectedRow;
  const navigate = useNavigate();

  const handlePauseOnSuccess = async () => {
    setBtnLoading({ btn: PAUSE, isLoading: true });
    const { error } = await updateProjectByStatus(selectedRow.id, {
      status: STATUS.PAUSE,
    });
    setBtnLoading({ btn: PAUSE, isLoading: false });

    if (!!error) {
      errorToast({ title: ERRORS.GENERIC });
      return;
    }

    successToast({ title: SUCCESS.PROJECT_PAUSED });

    if (props.tableName === TABLE_NAME.ALL_TABLE) {
      const updateSelectedRow = { ...selectedRow, state: STATUS.PAUSE };
      const targetIndex = props.data.findIndex(
        (item: any) => item.id === selectedRow.id
      );
      const updatedArray = updateArray(
        props.data,
        targetIndex,
        updateSelectedRow
      );
      updateRows(props.data, updatedArray);
    } else {
      const filteredRows = props.data.filter(
        (item: any) => item.id !== selectedRow.id
      );
      updateRows(props.data, filteredRows);
    }
  };
  const handleCompleteOnSuccess = async () => {
    setBtnLoading({ btn: COMPLETE, isLoading: true });
    const { error } = await updateProjectByStatus(selectedRow.id, {
      status: STATUS.COMPLETE,
    });
    setBtnLoading({ btn: COMPLETE, isLoading: false });

    if (!!error) {
      errorToast({ title: ERRORS.GENERIC });
      return;
    }

    successToast({ title: SUCCESS.PROJECT_MARKED_AS_COMPLETED });

    if (props.tableName === TABLE_NAME.ALL_TABLE) {
      const updateSelectedRow = { ...selectedRow, state: STATUS.COMPLETE };
      const targetIndex = props.data.findIndex(
        (item: any) => item.id === selectedRow.id
      );
      const updatedArray = updateArray(
        props.data,
        targetIndex,
        updateSelectedRow
      );
      updateRows(props.data, updatedArray);
    } else {
      const filteredRows = props.data.filter(
        (item: any) => item.id !== selectedRow.id
      );
      updateRows(props.data, filteredRows);
    }
  };
  const handleResumeOnSuccess = async () => {
    setBtnLoading({ btn: RESUME, isLoading: true });
    const { error } = await updateProjectByStatus(selectedRow.id, {
      status: STATUS.ACTIVE,
    });
    setBtnLoading({ btn: RESUME, isLoading: false });

    if (!!error) {
      errorToast({ title: ERRORS.GENERIC });
      return;
    }

    successToast({ title: SUCCESS.PROJECT_RESUMED });

    if (props.tableName === TABLE_NAME.ALL_TABLE) {
      const updateSelectedRow = { ...selectedRow, state: STATUS.ACTIVE };
      const targetIndex = props.data.findIndex(
        (item: any) => item.id === selectedRow.id
      );
      const updatedArray = updateArray(
        props.data,
        targetIndex,
        updateSelectedRow
      );
      updateRows(props.data, updatedArray);
    } else {
      const filteredRows = props.data.filter(
        (item: any) => item.id !== selectedRow.id
      );
      updateRows(props.data, filteredRows);
    }
  };
  const handleSubmitOnSuccess = async () => {
    setBtnLoading({ btn: SUBMIT, isLoading: true });
    const { error } = await updateProjectByStatus(selectedRow.id, {
      status: STATUS.ACTIVE,
    });
    setBtnLoading({ btn: SUBMIT, isLoading: false });

    if (!!error) {
      errorToast({ title: ERRORS.GENERIC });
      return;
    }

    successToast({ title: SUCCESS.PROJECT_SUBMITTED });

    if (props.tableName === TABLE_NAME.ALL_TABLE) {
      const updateSelectedRow = { ...selectedRow, state: STATUS.ACTIVE };
      const targetIndex = props.data.findIndex(
        (item: any) => item.id === selectedRow.id
      );
      const updatedArray = updateArray(
        props.data,
        targetIndex,
        updateSelectedRow
      );
      updateRows(props.data, updatedArray);
    } else {
      const filteredRows = props.data.filter(
        (item: any) => item.id !== selectedRow.id
      );
      updateRows(props.data, filteredRows);
    }
  };
  const handleDownloadOnSuccess = () => {
    setBtnLoading({ btn: DOWNLOAD, isLoading: true });
    setTimeout(() => {
      setBtnLoading({ btn: DOWNLOAD, isLoading: false });
    }, 3000);
  };
  const handleRevertOnSuccess = async () => {
    setBtnLoading({ btn: REVERT, isLoading: true });
    const { error } = await updateProjectByStatus(selectedRow.id, {
      status: STATUS.ACTIVE,
    });
    setBtnLoading({ btn: REVERT, isLoading: false });

    if (!!error) {
      errorToast({ title: ERRORS.GENERIC });
      return;
    }

    successToast({ title: SUCCESS.PROJECT_REVERTED_TO_ACTIVE });

    if (props.tableName === TABLE_NAME.ALL_TABLE) {
      const updateSelectedRow = { ...selectedRow, state: STATUS.ACTIVE };
      const targetIndex = props.data.findIndex(
        (item: any) => item.id === selectedRow.id
      );
      const updatedArray = updateArray(
        props.data,
        targetIndex,
        updateSelectedRow
      );
      updateRows(props.data, updatedArray);
    } else {
      const filteredRows = props.data.filter(
        (item: any) => item.id !== selectedRow.id
      );
      updateRows(props.data, filteredRows);
    }
  };

  return (
    <Flex>
      {state === STATUS.PAUSE && (
        <ActionButton
          btnText={RESUME}
          isLoading={btnLoading.btn === RESUME && btnLoading.isLoading}
          leftIcon={<FaPlay />}
          colorScheme="blue"
          mr={2}
          alertDialog={{
            title: "Resume Project",
            description: "Resume this project?",
            onSuccess: handleResumeOnSuccess,
          }}
        />
      )}
      {state === STATUS.ACTIVE && (
        <>
          <ActionButton
            btnText={PAUSE}
            isLoading={btnLoading.btn === PAUSE && btnLoading.isLoading}
            leftIcon={<FaPause />}
            colorScheme="blue"
            mr={2}
            alertDialog={{
              title: "Pause Project",
              description: "Pause this project?",
              onSuccess: handlePauseOnSuccess,
            }}
          />
          <ActionButton
            btnText={COMPLETE}
            isLoading={btnLoading.btn === COMPLETE && btnLoading.isLoading}
            leftIcon={<FaCheck />}
            colorScheme="blue"
            mr={2}
            alertDialog={{
              title: "Complete Project",
              description: "Mark this project as complete?",
              onSuccess: handleCompleteOnSuccess,
            }}
          />
        </>
      )}
      {state === STATUS.DRAFT && (
        <>
          <ActionButton
            btnText={SUBMIT}
            isLoading={btnLoading.btn === SUBMIT && btnLoading.isLoading}
            leftIcon={<FaRegPaperPlane />}
            colorScheme="blue"
            mr={2}
            alertDialog={{
              title: "Submit Project",
              description: "Submit this project?",
              onSuccess: handleSubmitOnSuccess,
            }}
          >
            Submit
          </ActionButton>
          <ActionButton
            btnText={EDIT}
            isLoading={btnLoading.btn === EDIT && btnLoading.isLoading}
            leftIcon={<GoPencil />}
            colorScheme="blue"
            mr={2}
            onClick={() => navigate(`${NEW_PROJECT_PAGE.href}/${projectId}`)}
          />
        </>
      )}
      {state === STATUS.COMPLETE && (
        <>
          <ActionButton
            btnText={REVERT}
            isLoading={btnLoading.btn === REVERT && btnLoading.isLoading}
            leftIcon={<FaUndo />}
            colorScheme="blue"
            mr={2}
            alertDialog={{
              title: "Revert Project",
              description:
                "Are you sure? It will revert the project from completed to active.",
              onSuccess: handleRevertOnSuccess,
            }}
          />
          <ActionButton
            btnText={DOWNLOAD}
            isLoading={btnLoading.btn === DOWNLOAD && btnLoading.isLoading}
            leftIcon={<FaFileDownload />}
            colorScheme="blue"
            mr={2}
            alertDialog={{
              title: "Download Project",
              description: "Download project's marketing asset files?",
              onSuccess: handleDownloadOnSuccess,
            }}
          />
        </>
      )}
      <Spacer />
    </Flex>
  );
};

const exportObject = {
  Header,
  Cell,
};
export default exportObject;
