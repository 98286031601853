/////////////////////
// Form / Auth Layout Page
/////////////////////

// Basic Imports
import { FC } from "react";

// Design Imports
import {
  Heading,
  Stack,
  useBreakpointValue,
  Text,
  Button,
  HStack,
} from "@chakra-ui/react";
import { Logo } from "components/ui/logo/Logo2";
import { Link } from "react-router-dom";

// Layout and Section Imports

// Data Imports

// Functions

// Hooks

// Interfaces
interface Props {
  title: string;
  description?: string;
  showLogo?: boolean;
  link?: {
    label: string;
    to: string;
  };
}
const FormHeader: FC<Props> = ({
  title,
  showLogo = true,
  description,
  link,
}) => {
  return (
    <Stack spacing="4" my={6}>
      {showLogo ? <Logo h={10} color="black" /> : null}
      <Stack spacing={{ base: "2", md: "3" }} textAlign="center">
        <Heading
          size={useBreakpointValue({ base: "xs", md: "sm" })}
          mt={4}
          textTransform="none"
        >
          {title}
        </Heading>
        <HStack spacing="1" justify="center">
          {!!description && <Text color="muted">{description}</Text>}
          {!!link?.label && (
            <Button as={Link} to={link.to} variant="link" colorScheme="blue">
              {link.label}
            </Button>
          )}
        </HStack>
      </Stack>
    </Stack>
  );
};

export default FormHeader;
