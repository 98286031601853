// Basic Imports
import { useQuery } from "@tanstack/react-query";
import axios from "libraries/axios";

const useBillingInvoices = (customerId: string) => {
  const invoicesQuery = useQuery({
    queryKey: ["invoices", customerId],
    queryFn: () => axios.get(`/subscriptions/invoices/${customerId}`),
    enabled: !!customerId,
  });

  return {
    ...invoicesQuery,
    invoices: invoicesQuery?.data?.data?.data?.invoices || [],
  };
};

export default useBillingInvoices;
