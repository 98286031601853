/**
 * Formats a numeric amount as a currency string based on the specified currency code and options.
 *
 * @param {number} amount - The numeric value to be formatted as currency.
 * @param {string} [currency="usd"] - The currency code in which the amount should be formatted. Defaults to "usd".
 * @param {Object} [options] - Optional configuration object to modify the formatting.
 * @param {boolean} [options.excludeDecimals=false] - If true, decimals will be excluded from the formatted string.
 * @param {boolean} [options.showOnlySign=false] - If true, only the currency sign will be displayed (e.g., "$" instead of "USD").
 * @returns {string} - The formatted currency string.
 *
 * @example
 * // Returns "$1,000.00" for the amount 1000
 * formatCurrency(1000);
 *
 * @example
 * // Returns "€1.000" for the amount 1000 in euros with no decimals
 * formatCurrency(1000, "eur", { excludeDecimals: true });
 *
 * @example
 * // Returns "US$1,000.00" for the amount 1000 with a narrow symbol display
 * formatCurrency(1000, "usd", { showOnlySign: true });
 */
export function formatCurrency(
  amount: number,
  currency: string = "usd",
  options?: {
    excludeDecimals?: boolean;
    showOnlySign?: boolean;
  }
): string {
  // Validate amount is a number
  if (typeof amount !== "number" || isNaN(amount)) {
    throw new Error("Amount must be a valid number.");
  }

  // Ensure currency is a valid string and fallback to "USD" if it's not valid
  const validCurrency =
    typeof currency === "string" && currency.trim() ? currency : "USD";

  return new Intl.NumberFormat(undefined, {
    style: "currency",
    currency: validCurrency.toUpperCase(), // Convert currency code to uppercase to ensure correct format
    maximumFractionDigits: options?.excludeDecimals ? 0 : 2,
    currencyDisplay: options?.showOnlySign ? "narrowSymbol" : "symbol", // Use narrowSymbol for sign-only display
  }).format(amount);
}

/**
 * Converts a Stripe price (in the smallest currency unit) to a formatted dollar amount.
 *
 * Stripe typically represents prices in cents (for USD). This function divides the
 * Stripe price by 100 to convert it to dollars.
 *
 * @param {number} stripePrice - The price amount from Stripe (e.g., 2999 for $29.99).
 * @returns {number} The converted price in dollars (e.g., 29.99).
 *
 * @example
 * // Returns 29.99
 * convertStripePriceToDollars(2999);
 *
 * @example
 * // Returns 10.00
 * convertStripePriceToDollars(1000);
 */
export function convertStripePriceToDollars(stripePrice: number): number {
  if (typeof stripePrice !== "number") {
    throw new TypeError("stripePrice must be a number");
  }

  return stripePrice / 100;
}
