import { FC } from "react";
import { Spinner } from "@chakra-ui/react";
import useOrderDeliveries from "hooks/useOrderDeliveries";
import { OrderActivity } from "types/order.types";
import Alert from "components/features/Alert";
import DeliveryDetailsCard from "./DeliveryDetailsCard";
import useOrder from "hooks/useOrder";

interface Props {
  orderId: string;
  resourceId: OrderActivity["resourceId"];
}
const DeliveryActivity: FC<Props> = ({ orderId, resourceId }) => {
  const { isLoading, data: orderDeliveries } = useOrderDeliveries(orderId);
  const { data: order, isLoading: orderLoading } = useOrder(orderId);
  const delivery = orderDeliveries?.find(
    (delivery) => delivery._id === resourceId
  );

  if (!!isLoading || !!orderLoading) return <Spinner />;
  if (!delivery || !order)
    return (
      <Alert
        status="error"
        description="Delivery or order not found! Try hard reload or contact support"
      />
    );
  return (
    <DeliveryDetailsCard
      delivery={delivery}
      order={order}
      showActionButtons={false}
    />
  );
};

export default DeliveryActivity;
