import { HStack } from "@chakra-ui/react";
import useOrderActions from "hooks/useOrderActions";
import { FC } from "react";
import { Order } from "types/order.types";
import OrderAcceptButton from "../OrderAcceptButton";
import OrderRevisionButton from "../OrderRivisionButton";

interface Props {
  order: Order;
}
const DeliveryActionButtons: FC<Props> = ({ order }) => {
  const { shouldShowAcceptButton, shouldShowRevisionButton } = useOrderActions(
    order.activities,
    order.status
  );
  return (
    <HStack>
      {shouldShowAcceptButton && <OrderAcceptButton orderId={order.id} />}
      {shouldShowRevisionButton && <OrderRevisionButton />}
    </HStack>
  );
};

export default DeliveryActionButtons;
