/////////////////////
// Require Plan Layout
// Anything with this layout require a plan.
/////////////////////

// Basic Imports
// import { useAuth } from "context/AuthProvider";
import {
  useLocation,
  // Navigate,
  Outlet,
} from "react-router-dom";

// Design Imports

// Layout and Section Imports

// Data Imports

// Custom Hooks and Services
import { ROUTES } from "settings/constants/routes";

// Interfaces

// Functions

const RequirePlanLayout = () => {
  // const { user } = useAuth();
  const location = useLocation();

  if (location.pathname === ROUTES.ACCOUNT_BILLING) {
    return <Outlet />;
  }

  // ! Remove the negation for production so it prevents accessing the page without a plan
  // TODO: User schema has changed so consult with Tasnim to use new field if available otherwise skip this
  // return !user?.hasPlan  ? (
  //   <Outlet />
  // ) : (
  //   <Navigate to={ROUTES.ACCOUNT_BILLING}   />
  // );
  return <Outlet />;
};

export default RequirePlanLayout;
