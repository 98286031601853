import { Button } from "@chakra-ui/react";
import useQueryParams from "hooks/useQueryParams";

const OrderReviewButton = () => {
  const { setMultipleParams } = useQueryParams();

  const handleClickSubmitReview = () => {
    setMultipleParams({ tab: "activity", highlight: "actions" });
  };

  return <Button onClick={handleClickSubmitReview}>Leave a review</Button>;
};

export default OrderReviewButton;
