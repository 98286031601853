import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { getAllOffers } from "services/offerService";
import useDebounce from "./useDebounce";
import { useSearchParams, useLocation } from "react-router-dom";

const useOffers = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  // State for search text
  const [searchText, setSearchText] = useState("");
  const { debouncedValue, isLoading } = useDebounce(searchText, 500);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const categoryId = searchParams.get("category") || "";
  const query = useQuery({
    queryKey: ["offers", debouncedValue, categoryId],
    queryFn: () => getAllOffers(searchText, categoryId),
  });

  useEffect(() => {
    const searchParam = queryParams.get("search");
    if (searchParam) {
      setSearchText(searchParam);
    }
  }, []);

  useEffect(() => {
    if (!!searchText) {
      queryParams.set("search", searchText);
    } else {
      queryParams.delete("search");
    }
    setSearchParams(queryParams);
  }, [searchText]);

  return {
    ...query,
    searchText,
    searchLoading: isLoading,
    onSearch: (text: string) => setSearchText(text),
  };
};

export default useOffers;
