import { FC } from "react";
import { Card, CardHeader, Circle, HStack, Icon, Text } from "@chakra-ui/react";
import { RxLapTimer } from "react-icons/rx";

interface Props {
  wrapInCard?: boolean;
}
const OrderProgressAlert: FC<Props> = ({ wrapInCard }) => {
  if (!!wrapInCard)
    return (
      <Card>
        <CardHeader>
          <RenderContent />
        </CardHeader>
      </Card>
    );

  return <RenderContent />;
};

const RenderContent = () => {
  return (
    <HStack>
      <Circle bg="blue.100" p={2}>
        <Icon color="blue.700" as={RxLapTimer} />
      </Circle>
      <Text fontWeight="semibold">
        Talent is working hard to deliver on time; for any issues, please
        contact via chat or support.
      </Text>
    </HStack>
  );
};

export default OrderProgressAlert;
