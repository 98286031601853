import { Stack, chakra } from "@chakra-ui/react";
import { FC, ReactNode } from "react";

interface Props {
  children: ReactNode;
  onSubmit: React.FormEventHandler<HTMLFormElement>;
}
const FormWrapper: FC<Props> = ({ onSubmit, children }) => {
  return (
    <chakra.form onSubmit={onSubmit}>
      <Stack spacing="6">{children}</Stack>
    </chakra.form>
  );
};

export default FormWrapper;
