import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "settings/constants/routes";

export const useLocalUser = (
  key: string,
  initialValue: "buyer" | "subscriber"
) => {
  const [storedValue, setStoredValue] = useState<"buyer" | "subscriber">(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? (JSON.parse(item) as "buyer" | "subscriber") : initialValue;
    } catch (error) {
      console.error("Error retrieving localStorage key", error);
      return initialValue;
    }
  });
  const location = useLocation();
  const navigate = useNavigate();
  const setValue = (
    value:
      | "buyer"
      | "subscriber"
      | ((val: "buyer" | "subscriber") => "buyer" | "subscriber")
  ) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;

      // Add a delay of 1 second for the transition to complete
      setTimeout(() => {
        setStoredValue(valueToStore);
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
        if (
          location.pathname.startsWith("/projects") &&
          valueToStore === "buyer"
        ) {
          navigate(ROUTES.ORDERS);
        }

        // Dispatch a custom event to notify about the localStorage change
        const event = new Event("localStorageUpdate");
        window.dispatchEvent(event);
      }, 1000); // 1 second delay
    } catch (error) {
      console.error("Error setting localStorage key", error);
    }
  };

  // Listen for storage changes (both localStorage and custom event)
  useEffect(() => {
    const handleStorageChange = (event: StorageEvent | Event) => {
      const newValue = window.localStorage.getItem(key);
      if (newValue) {
        setStoredValue(JSON.parse(newValue) as "buyer" | "subscriber");
      }
    };

    window.addEventListener("storage", handleStorageChange);
    window.addEventListener("localStorageUpdate", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
      window.removeEventListener("localStorageUpdate", handleStorageChange);
    };
  }, [key]);

  return [storedValue, setValue] as const;
};
