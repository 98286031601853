import { FC, useState } from "react";
import { Alert } from "@chakra-ui/react";
import RenderPricingDetails from "./RenderPricingDetails";
import PricingDetailsLoading from "./PricingDetailsLoading";
import PricingCardLayout from "./PricingCardLayout";
import PricingCardTabs, {
  SkeletonLoading as TabsSkeletonLoading,
} from "./PricingCardTabs";
import useOfferPrices from "hooks/useOfferPrices";

interface Props {
  gigId: string;
}
const OfferPricingCard: FC<Props> = ({ gigId }) => {
  const { data, isLoading, error } = useOfferPrices(gigId);
  const [activeTierIndex, setActiveTierIndex] = useState(0);
  const activeTier = data?.find((_, index) => index === activeTierIndex);

  if (!!isLoading)
    return (
      <PricingCardLayout>
        <TabsSkeletonLoading />
        <PricingDetailsLoading />
      </PricingCardLayout>
    );
  if (!!error)
    return (
      <PricingCardLayout>
        <Alert status="error" title="Invalid prices! Please try again." />
      </PricingCardLayout>
    );
  if (!data)
    return (
      <PricingCardLayout>
        <Alert status="error" title="Offer prices are not found!" />
      </PricingCardLayout>
    );
  return (
    <PricingCardLayout>
      <PricingCardTabs
        onChangeIndex={setActiveTierIndex}
        data={data.map((price, index) => ({
          label: price.title,
          isActive: index === activeTierIndex,
        }))}
      />

      {activeTier && <RenderPricingDetails data={activeTier} />}
    </PricingCardLayout>
  );
};

export default OfferPricingCard;
