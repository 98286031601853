// import { ActiveImage } from "components/sections/ProjectsSection/Steps/ESupportingDocuments/UploadingForm";
import axios from "libraries/axios";
import { API_URL } from "settings/site-credentials";
import { CreateAttachmentService } from "types/attachments.types";
import { Attachment, UpdateAttachmentService } from "types/attachments.types";
import { getAuthToken } from "./authService";

export interface MediaResponse {
  fileName: string;
  type: string;
  url: string;
  size: number;
}

// This function is latest one that is being used in useGalleryForm
export async function createAttachment({
  mediaResponse,
  folderName,
  resourceId,
  isFeatured,
  resourceType,
  projectId,
  mediaDescription,
}: CreateAttachmentService): Promise<Attachment> {
  const token = getAuthToken();
  const formData = new FormData();

  if (mediaDescription) {
    formData.append("description", mediaDescription);
  }
  if (projectId) {
    formData.append("projectId", projectId);
  }
  if (folderName) {
    formData.append("folderName", folderName);
  }
  if (resourceId) {
    formData.append("resourceId", resourceId);
  }
  if (isFeatured) {
    formData.append("featured", String(isFeatured));
  }
  formData.append("files", mediaResponse);
  if (resourceType) formData.append("referenceType", String(resourceType));

  const response = await fetch(`${API_URL}/attachments`, {
    method: "POST",
    body: formData,
    headers: {
      Authorization: `Bearer ${token}`, // Assuming 'userToken' is already defined
    },
  });

  const resData = await response.json(); // Assuming the server responds with JSON.
  return resData.data.attachment;
}

export async function createMultipleAttachments(
  attachments: CreateAttachmentService[],
  resourceType?: string
): Promise<{ success: boolean; attachments: Attachment[] }> {
  let promises = attachments.map((file) => {
    return createAttachment({
      mediaResponse: file.mediaResponse,
      folderName: file.folderName,
      resourceId: file.resourceId,
      isFeatured: file.isFeatured,
      resourceType,
    });
  });

  try {
    const allAttachmentsRes = await Promise.all(promises);

    return {
      success: true,
      // attachments: allAttachmentsRes.map((att) => att.data.data.attachment),
      attachments: allAttachmentsRes,
    };
  } catch (error) {
    console.log({ error });
    return { success: false, attachments: [] };
  }
}

export async function fetchAttachmentByIdService(attachmentId: string) {
  const response = await axios.get(`/attachments/${attachmentId}`);
  return response.data.data.attachment;
}

export async function deleteAttachmentByIdService(attachmentId: string) {
  try {
    const { data } = await axios.delete(`/attachments/${attachmentId}`);
    return { data };
  } catch (error) {
    return { error: "Something went wrong!" };
  }
}

export async function getOfferAttachments(
  offerId: string
): Promise<Attachment[]> {
  const response = await axios.get(`/offers/${offerId}/attachments`);
  return response.data.data.attachments;
}

export async function getAttachmentById(id: string): Promise<Attachment> {
  const response = await axios.get(`/attachments/${id}`);
  return response.data.data.attachment;
}

export async function getMultipleAttachmentsByIds(
  ids: string[]
): Promise<Attachment[]> {
  let promises = ids.map((id) => {
    return getAttachmentById(id);
  });

  const allAttachmentsRes = await Promise.all(promises);
  return allAttachmentsRes;
}

export async function deleteAttachment(id: string): Promise<Attachment> {
  const { data } = await axios.delete(`/attachments/${id}`);
  return data.data;
}
export async function deleteMultipleAttachments(ids: string[]) {
  let promises = ids.map((id) => {
    return deleteAttachment(id);
  });

  try {
    const allAttachmentsRes = await Promise.all(promises);
    return {
      success: true,
      attachments: allAttachmentsRes,
    };
  } catch (error) {
    console.log({ error });
    return { success: false, attachments: [] };
  }
}

export async function updateAttachment(
  attachmentId: string,
  body: Partial<Attachment>
): Promise<Attachment> {
  const res = await axios.put(`${API_URL}/attachments/${attachmentId}`, body);
  return res.data.data.attachment;
}

export async function updateMultipleAttachments(
  attachments: UpdateAttachmentService[]
) {
  let promises = attachments.map((file) => {
    return updateAttachment(file.attachmentId, file.body);
  });

  return Promise.all(promises);
}
