import { Badge, Box, Link, Text, VStack } from "@chakra-ui/react";
import { FC } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Logo } from "../logo/Logo2";

interface AppLogoProps {
  isBetaVersion?: boolean;
  userType?: string;
}
const AppLogo: FC<AppLogoProps> = ({ isBetaVersion, userType = "buyer" }) => {
  // const logoSize = useBreakpointValue(
  //   { base: "5px", md: "xs" },
  //   { ssr: false }
  // );
  return (
    <VStack position="relative">
      <Box>
        <Link as={RouterLink} to="/">
          <Logo h={10} color={userType === "buyer" ? "black" : "white"} />
        </Link>
        {!!isBetaVersion && (
          <Box position="absolute" right={-6} top={0}>
            <Badge
              colorScheme="red"
              rounded="lg"
              variant="solid"
              position="relative"
              transform="translate3d(-50%, -50%, 0)"
            >
              <Text color="white">Beta</Text>
            </Badge>
          </Box>
        )}
      </Box>
    </VStack>
  );
};

export default AppLogo;
