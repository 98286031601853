import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { OrderActivity, OrderStatus } from "types/order.types";

const useOrderActions = (
  activities: OrderActivity[],
  orderStatus: OrderStatus
) => {
  const [searchParams] = useSearchParams();
  const actionParam = searchParams.get("action");

  const isOrderDelivered = orderStatus === "delivered";
  const isOrderInProgress = orderStatus === "in_progress";
  const isOrderInRevision = orderStatus === "in_revision";
  const isOrderCompleted = orderStatus === "completed";

  const hasOrderDeliveredAlready = useMemo(
    () =>
      activities.some(
        (activity) =>
          activity.action === "order_delivered" ||
          activity.action === "delivery_created"
      ),
    [orderStatus]
  );
  const hasOrderAlreadyCompleted = useMemo(
    () => activities.some((activity) => activity.action === "order_completed"),
    [activities]
  );
  const hasBuyerLeftReviewAlready = useMemo(
    () => activities.some((activity) => activity.action === "customer_ratings"),
    [activities]
  );

  const shouldShowProgressAlert = !!isOrderInProgress;
  const shouldShowRevisionAction =
    !!hasOrderDeliveredAlready && actionParam === "revision";
  const shouldShowRevisionButton =
    !!hasOrderDeliveredAlready && !isOrderInRevision && !isOrderCompleted;
  const shouldShowRevisionAlert = !!isOrderInRevision;
  const shouldShowDeliveredAlert =
    !!isOrderDelivered && actionParam !== "revision";
  const shouldShowAcceptButton = !!isOrderDelivered;
  const shouldShowSellerReviewForm =
    !!hasOrderAlreadyCompleted && !hasBuyerLeftReviewAlready;
  const shouldShowSellerReviewButton =
    !!isOrderCompleted && !hasBuyerLeftReviewAlready;
  const shouldShowFinalDeliveryButton =
    !!isOrderCompleted && !!hasOrderDeliveredAlready;
  const shouldShowFinalDeliveryAction =
    !!shouldShowFinalDeliveryButton && actionParam === "final_delivery";

  return {
    shouldShowRevisionAction,
    shouldShowSellerReviewForm,
    shouldShowProgressAlert,
    shouldShowRevisionButton,
    shouldShowRevisionAlert,
    shouldShowDeliveredAlert,
    shouldShowAcceptButton,
    shouldShowSellerReviewButton,
    shouldShowFinalDeliveryButton,
    shouldShowFinalDeliveryAction,
  };
};

export default useOrderActions;
