/////////////////////
// Project Info Step Section
/////////////////////

// TODO: Must work on these things
/**
 * 1- Fix the completion state problem (it should be updated before sending data to Db)
 * 2- Centralize a single function that will update the database with the current current wizard step.
 * 3- Remove all the unnecessary functions.
 * 4- Try to destructure the code into files (may be separate the handlers and database handlers).
 */

// Basic Imports
import { FC, useEffect, useState } from "react";

// Design Imports
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Divider,
  FormControl,
  FormLabel,
  HStack,
  Input,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberDecrementStepper,
  NumberIncrementStepper,
  RadioGroup,
  Stack,
  Text,
  Textarea,
  useColorModeValue as mode,
  Wrap,
  Select,
} from "@chakra-ui/react";

import { useProject } from "context/project/ProjectProvider";
import useValidatePostalCode from "hooks/useValidatePostalCode";
import EmailTagsInput from "components/ui/common/EmailTagsInput";

// Layout and Section Imports

// Data Imports

// Interfaces
interface Props {
  onSelect?: (props: any) => void;
  nextStep?: () => any;
  defaultValues?: any;
  onStepClick?: () => void;
}

// Functions
const ProjectInfoStep: FC<Props> = ({ nextStep, onStepClick }) => {
  // State of context api
  const {
    projectInfo,
    onChangeProjectName,
    onChangeProjectDescription,
    onChangeProjectEmail,
    onChangeProjectAddressLine1,
    onChangeProjectAddressLine2,
    onChangeProjectCity,
    onChangeProjectState,
    onChangeProjectCountry,
    onChangeProjectPostalCode,
    onChangeProjectBedrooms,
    onChangeProjectBathrooms,
    onChangeProjectStories,
    onChangeProjectSize,
    onChangeProjectWidth,
    onChangeProjectDepth,
  } = useProject();
  // Local state of the component
  const [projectInput, setProjectInput] = useState({
    name: projectInfo.name,
    description: projectInfo.description,
    emails: projectInfo.emails,
    addressLine1: projectInfo.addressLine1,
    addressLine2: projectInfo.addressLine2,
    city: projectInfo.city,
    state: projectInfo.state,
    country: projectInfo.country || "CA",
    postalCode: projectInfo.postalCode,
    bedrooms: projectInfo.bedrooms,
    bathrooms: projectInfo.bathrooms,
    stories: projectInfo.stories,
    size: projectInfo.size,
    width: projectInfo.width,
    depth: projectInfo.depth,
  });

  useEffect(() => {
    setProjectInput({
      name: projectInfo.name,
      description: projectInfo.description,
      emails: projectInfo.emails,
      addressLine1: projectInfo.addressLine1,
      addressLine2: projectInfo.addressLine2,
      city: projectInfo.city,
      state: projectInfo.state,
      country: projectInfo.country,
      postalCode: projectInfo.postalCode,
      bedrooms: projectInfo.bedrooms,
      bathrooms: projectInfo.bathrooms,
      stories: projectInfo.stories,
      size: projectInfo.size,
      width: projectInfo.width,
      depth: projectInfo.depth,
    });
  }, [projectInfo]);
  const [projectNameOption, setProjectNameOption] = useState("CustomName");
  const { isValid } = useValidatePostalCode({
    postalCode: projectInput.postalCode,
    country: projectInput.country,
  });

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        nextStep && nextStep();
      }}
    >
      <Stack shouldWrapChildren spacing="4">
        <Stack rounded="lg" w="full" spacing="2">
          <FormControl id="Project">
            <RadioGroup
              onChange={(value) => setProjectNameOption(value)}
              value={projectNameOption}
            >
              <Stack pt="4">
                <Wrap>
                  <FormLabel>Project Name</FormLabel>
                  <Input
                    name="CustomName"
                    required
                    autoComplete="off"
                    bg={mode("white", "gray.700")}
                    fontSize="md"
                    roundedBottom="0"
                    value={projectInput.name}
                    onChange={(e) =>
                      setProjectInput({
                        ...projectInput,
                        name: e.currentTarget.value,
                      })
                    }
                    onBlur={() => onChangeProjectName(projectInput.name)}
                  />
                </Wrap>
              </Stack>
            </RadioGroup>
          </FormControl>
          <FormControl id="ProjectDescription">
            <FormLabel>Project Description</FormLabel>
            <Textarea
              bg={mode("white", "gray.700")}
              placeholder="Anything you want to tell us about the project. (i.e. 2 story single family home)"
              value={projectInput.description}
              onChange={(e) =>
                setProjectInput({
                  ...projectInput,
                  description: e.currentTarget.value,
                })
              }
              onBlur={() =>
                onChangeProjectDescription(projectInput.description)
              }
            />
          </FormControl>
          <EmailTagsInput
            defaultValues={projectInput.emails}
            onAddEmail={(emails) => {
              onChangeProjectEmail(emails);
            }}
          />
        </Stack>
        <Accordion allowToggle>
          <AccordionItem w="full" bg={mode("gray.50", "gray.600")}>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  Optional Address Info
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4} bg={mode("gray.50", "gray.600")}>
              <Text fontSize="lg" as="em">
                Why is this needed?
              </Text>
              <Text>
                So we can a context of the project and understand the area for
                vegetation, lighting, and surroundings. It also can help with
                classifying the project and marketing later on if needed.
              </Text>
              <Divider m={5} />
              <FormControl id="ProjectAddress1">
                <FormLabel>Address Line 1</FormLabel>
                <Input
                  name="ProjectAddress1"
                  placeholder="i.e., Unit 1234"
                  bg={mode("white", "gray.700")}
                  fontSize="md"
                  roundedBottom="0"
                  value={projectInput.addressLine1}
                  onChange={(e) =>
                    setProjectInput({
                      ...projectInput,
                      addressLine1: e.currentTarget.value,
                    })
                  }
                  onBlur={() =>
                    onChangeProjectAddressLine1(projectInput.addressLine1)
                  }
                />
              </FormControl>
              <FormControl id="ProjectAddress2">
                <FormLabel>Address Line 2</FormLabel>
                <Input
                  name="ProjectAddress2"
                  autoComplete="off"
                  placeholder="i.e. Unit 1234"
                  bg={mode("white", "gray.700")}
                  fontSize="md"
                  roundedBottom="0"
                  value={projectInput.addressLine2}
                  onChange={(e) =>
                    setProjectInput({
                      ...projectInput,
                      addressLine2: e.currentTarget.value,
                    })
                  }
                  onBlur={() =>
                    onChangeProjectAddressLine2(projectInput.addressLine2)
                  }
                />
              </FormControl>
              <FormControl id="City">
                <FormLabel>City</FormLabel>
                <Input
                  name="City"
                  autoComplete="off"
                  placeholder={
                    projectInput.country === "US"
                      ? "i.e. Washington"
                      : "i.e. Vancouver"
                  }
                  bg={mode("white", "gray.700")}
                  fontSize="md"
                  roundedBottom="0"
                  value={projectInput.city}
                  onChange={(e) =>
                    setProjectInput({
                      ...projectInput,
                      city: e.currentTarget.value,
                    })
                  }
                  onBlur={() => onChangeProjectCity(projectInput.city)}
                />
              </FormControl>
              <FormControl id="ProvinceState">
                <FormLabel>State/Province</FormLabel>
                <Input
                  name="ProvinceState"
                  autoComplete="off"
                  placeholder={
                    projectInput.country === "US" ? "i.e. D.C" : "i.e. BC"
                  }
                  bg={mode("white", "gray.700")}
                  fontSize="md"
                  roundedBottom="0"
                  value={projectInput.state}
                  onChange={(e) =>
                    setProjectInput({
                      ...projectInput,
                      state: e.currentTarget.value,
                    })
                  }
                  onBlur={() => onChangeProjectState(projectInput.state)}
                />
              </FormControl>
              <FormControl id="Country">
                <FormLabel>Country</FormLabel>
                <Select
                  bg={mode("white", "gray.700")}
                  value={projectInfo.country}
                  onChange={(e) =>
                    onChangeProjectCountry(e.currentTarget.value)
                  }
                >
                  <option value="CA">CA</option>
                  <option value="US">US</option>
                </Select>
              </FormControl>
              <FormControl id="PostalZipCode">
                <FormLabel>ZIP/Postal Code</FormLabel>
                <Input
                  isInvalid={!isValid}
                  name="PostalZipCode"
                  autoComplete="off"
                  placeholder={
                    projectInput.country === "US"
                      ? "i.e. 12345"
                      : "i.e. V6B 3Y8"
                  }
                  bg={mode("white", "gray.700")}
                  fontSize="md"
                  roundedBottom="0"
                  type="text"
                  value={projectInput.postalCode}
                  onChange={(e) =>
                    setProjectInput({
                      ...projectInput,
                      postalCode: e.currentTarget.value,
                    })
                  }
                  onBlur={() =>
                    onChangeProjectPostalCode(projectInput.postalCode)
                  }
                />
                {!isValid && (
                  <Text
                    color="red"
                    fontStyle="italic"
                    fontWeight="medium"
                    p="2"
                  >
                    Code is invalid
                  </Text>
                )}
              </FormControl>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem w="full" bg={mode("gray.50", "gray.600")}>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  Optional Project Details
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4} bg={mode("gray.50", "gray.600")}>
              <Text fontSize="lg" as="em">
                Why is this needed?
              </Text>
              <Text>
                This serves as a means to double check what we receive.
              </Text>
              <Divider m={5} />
              <HStack>
                <FormControl id="Bedrooms">
                  <FormLabel>Bedrooms</FormLabel>
                  <NumberInput
                    step={1}
                    defaultValue={1}
                    min={0}
                    bg={mode("white", "gray.700")}
                    value={projectInput.bedrooms}
                    onChange={(value) =>
                      setProjectInput({ ...projectInput, bedrooms: value })
                    }
                    onBlur={() =>
                      onChangeProjectBedrooms(projectInput.bedrooms)
                    }
                  >
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </FormControl>
                <FormControl id="Bathrooms">
                  <FormLabel>Bathrooms</FormLabel>
                  <NumberInput
                    step={1}
                    defaultValue={1}
                    min={0}
                    bg={mode("white", "gray.700")}
                    value={projectInput.bathrooms}
                    onChange={(value) =>
                      setProjectInput({ ...projectInput, bathrooms: value })
                    }
                    onBlur={() =>
                      onChangeProjectBathrooms(projectInput.bathrooms)
                    }
                  >
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </FormControl>
                <FormControl id="Stories">
                  <FormLabel>Stories</FormLabel>
                  <NumberInput
                    step={1}
                    defaultValue={1}
                    min={0}
                    bg={mode("white", "gray.700")}
                    value={projectInput.stories}
                    onChange={(value) =>
                      setProjectInput({ ...projectInput, stories: value })
                    }
                    onBlur={() => onChangeProjectStories(projectInput.stories)}
                  >
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </FormControl>
              </HStack>
              <HStack my={5}>
                <FormControl id="SizeSqFt">
                  <FormLabel>Size (in sq ft) </FormLabel>
                  <Input
                    type="number"
                    step="any"
                    name="SizeSqFt"
                    bg={mode("white", "gray.700")}
                    fontSize="md"
                    roundedBottom="0"
                    value={projectInput.size}
                    onChange={(e) =>
                      setProjectInput({
                        ...projectInput,
                        size: e.currentTarget.value,
                      })
                    }
                    onBlur={() => onChangeProjectSize(projectInput.size)}
                  />
                </FormControl>
                <FormControl id="SizeSqFt">
                  <FormLabel>Width (in ft) </FormLabel>
                  <Input
                    type="number"
                    step="any"
                    name="WidthInFt"
                    bg={mode("white", "gray.700")}
                    fontSize="md"
                    roundedBottom="0"
                    value={projectInput.width}
                    onChange={(e) =>
                      setProjectInput({
                        ...projectInput,
                        width: e.currentTarget.value,
                      })
                    }
                    onBlur={() => onChangeProjectWidth(projectInput.width)}
                  />
                </FormControl>
                <FormControl id="SizeSqFt">
                  <FormLabel>Depth (in ft) </FormLabel>
                  <Input
                    type="number"
                    step="any"
                    name="DepthInFt"
                    bg={mode("white", "gray.700")}
                    fontSize="md"
                    roundedBottom="0"
                    value={projectInput.depth}
                    onChange={(e) =>
                      setProjectInput({
                        ...projectInput,
                        depth: e.currentTarget.value,
                      })
                    }
                    onBlur={() => onChangeProjectDepth(projectInput.depth)}
                  />
                </FormControl>
              </HStack>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Stack>
    </form>
  );
};

export default ProjectInfoStep;
