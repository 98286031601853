///////////////////////////////
// Billing Page
///////////////////////////////

// Basic Imports
import { FC } from "react";

// Design Imports
import { FaDownload } from "react-icons/fa";
import {
  Badge,
  Button,
  HStack,
  Icon,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

// Sections and Layouts
import SectionWrapper from "./SectionWrapper";
import useBillingInvoices from "hooks/useBillingInvoices";
import { formatSimpleDate } from "utils/date";
import { BiLinkExternal } from "react-icons/bi";
import { formatCurrency } from "utils/currency";

// Interfaces
interface Props {
  customerId: string;
}

const Invoices: FC<Props> = ({ customerId }) => {
  const { invoices, error } = useBillingInvoices(customerId);

  const renderStripePrice = (amount: number) => {
    if (typeof amount !== "number") return "-";
    return (amount / 100).toFixed(2);
  };

  if (invoices.length === 0) return null;
  if (!!error) return null;
  return (
    <SectionWrapper
      title="Invoices"
      Button={
        <Button
          as="a"
          href="https://homerender.io/contact"
          target="_blank"
          colorScheme="blue"
          w="max"
        >
          Contact ?
        </Button>
      }
    >
      <TableContainer>
        <Table variant="striped" colorScheme="gray">
          <Thead>
            <Tr>
              <Th>Date</Th>
              <Th>Amount Paid</Th>
              <Th>Status</Th>
              <Th isNumeric></Th>
            </Tr>
          </Thead>
          <Tbody>
            {invoices.map((invoice: any) => (
              <Tr key={invoice.id}>
                <Td>
                  <HStack
                    as="a"
                    href={invoice.hosted_invoice_url}
                    target="_blank"
                    _hover={{
                      textDecor: "underline",
                    }}
                  >
                    <Text>
                      {formatSimpleDate(new Date(invoice.created * 1000))}
                    </Text>
                    <Icon as={BiLinkExternal} />
                  </HStack>
                </Td>
                <Td>
                  {formatCurrency(
                    Number(renderStripePrice(invoice.amount_paid)),
                    invoice.currency
                  )}
                </Td>
                <Td>
                  <Badge
                    colorScheme={invoice.status === "paid" ? "green" : "yellow"}
                  >
                    {invoice.status}
                  </Badge>
                </Td>
                <Td isNumeric>
                  <form method="get" action={invoice.invoice_pdf}>
                    <Button
                      colorScheme="blue"
                      variant="outline"
                      rightIcon={<FaDownload />}
                      type="submit"
                    >
                      Download
                    </Button>
                  </form>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </SectionWrapper>
  );
};

export default Invoices;
