import React, { FC, isValidElement, ReactNode } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Divider,
  Heading,
  HStack,
  Stack,
} from "@chakra-ui/react";
import { GrAction } from "react-icons/gr";
import { useHighlightAnimation } from "hooks/useHighlightAnimation";

interface Props {
  children: ReactNode;
}
const OrderActionCard: FC<Props> = ({ children }) => {
  // Use the custom hook with combined animation logic
  const { animate, elementRef, animationStyles } = useHighlightAnimation({
    paramName: "highlight",
    paramValue: "actions",
  });

  const hasValidChildren = React.Children.toArray(children).some((child) =>
    isValidElement(child)
  );

  // If there are no valid children, do not render the card
  if (!hasValidChildren) {
    return null;
  }

  return (
    <Card
      ref={elementRef}
      variant="outline"
      animation={animate ? animationStyles.animation : "none"}
    >
      <CardHeader>
        <HStack spacing={4}>
          <Heading textTransform="capitalize" fontSize="2xl" fontWeight="bold">
            Actions
          </Heading>
          <GrAction size={22} />
        </HStack>
      </CardHeader>
      <Divider borderColor="gray.300" />
      <CardBody>
        <Stack spacing={4}>{children}</Stack>
      </CardBody>
    </Card>
  );
};

export default OrderActionCard;
