import { FC } from "react";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Text,
} from "@chakra-ui/react";
import { AsyncSelect, GroupBase } from "chakra-react-select";
import { FieldError, useFormContext, useWatch } from "react-hook-form";
import useCountryCity from "hooks/useCountryCity";

export interface ISelectOption {
  label: string;
  value: string;
}

interface Props {
  city: string;
}
const CityField: FC = () => {
  const { getCitiesByCountry, getCityByName } = useCountryCity();
  const {
    formState: { errors },
    setValue,
  } = useFormContext<Props>();
  const country = useWatch({ name: "country" });
  const city = useWatch({ name: "city" });
  const error = errors.city as FieldError;
  const selectValue = getCityByName(city);

  return (
    <FormControl isInvalid={!!error}>
      <FormLabel>
        City
        <Text ml={2} as="span" color="dimgrey" fontSize="sm" fontStyle="italic">
          (To replace text, type over the current contents)
        </Text>
      </FormLabel>
      <AsyncSelect<ISelectOption, false, GroupBase<ISelectOption>>
        placeholder="Select city..."
        isMulti={false}
        value={
          selectValue && {
            label: selectValue?.name || "",
            value: selectValue?.name || "",
          }
        }
        onChange={(values) => {
          setValue("city", values?.value || "", {
            shouldDirty: true,
            shouldTouch: true,
            shouldValidate: true,
          });
        }}
        loadOptions={(value, callback) => {
          setTimeout(() => {
            const cities = getCitiesByCountry(country, value);
            callback(
              cities.map((c) => ({
                label: c.name,
                value: c.name,
              }))
            );
          }, 1000);
        }}
      />

      <FormErrorMessage>{error?.message}</FormErrorMessage>
    </FormControl>
  );
};

export default CityField;
