/////////////////////
// User Profile and Authentication Services
/////////////////////

// Basic Imports
import axios from "libraries/axios";
import { User } from "types/user.types";

// Design Imports

// Layout and Section Imports

// Data Imports

// Interfaces

// Functions
export async function getUser(token: string) {
  try {
    const { data } = await axios.get(`/users/account`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return { user: data.data };
  } catch (error) {
    return { error: "Something went wrong!" };
  }
}

export async function resetPassword({
  token,
  newPassword,
  confirmPassword,
}: {
  token: string;
  newPassword: string;
  confirmPassword: string;
}) {
  try {
    const { data } = await axios.post(`/auth/password/reset/${token}`, {
      newPassword,
      confirmPassword,
    });
    return { data };
  } catch (error) {
    return { error: "Something went wrong!" };
  }
}

export async function updateUserProfile(body: Partial<User>): Promise<User> {
  const response = await axios.patch("/users/account", body);
  return response.data.data.user;
}

export async function updateCommunicationService({ projects }: any) {
  const response = await axios.patch("/users/account", {
    preferences: {
      notifications: {
        projects,
      },
    },
  });
  return response.data.data.user;
}

export async function updateEmailService({
  currEmail,
  newEmail,
  password,
}: {
  currEmail: string;
  newEmail: string;
  password: string;
}): Promise<void> {
  await axios.post(`/users/email/change`, {
    currentEmail: currEmail,
    newEmail,
    password,
  });
}

export async function updatePasswordService({
  password,
  newPassword,
}: {
  password: string;
  newPassword: string;
}): Promise<void> {
  await axios.post("/users/password/change", {
    password,
    newPassword,
  });
}

export async function editProfile(user: any): Promise<User> {
  const { data } = await axios.put(`/users/account`, {
    fullName: user.fullName,
    phone: user.phone,
    addressLine1: user.addressLine1,
    addressLine2: user.addressLine2,
    city: user.city,
    province: user.province,
    postalCode: user.postalCode,
    country: user.country,
    company: user.company,
  });

  return data.data.user;
}
