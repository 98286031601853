/////////////////////
// Project Services
/////////////////////

// Basic Imports
import axios from "libraries/axios";

// Design Imports

// Layout and Section Imports

// Data Imports
import { render_project_info__db_project } from "schemas/project_wizard_schema";
import { Project } from "types/project.types";
// import { Project } from "types/project.types";

// Interfaces

// Custom Hooks and Services

// Functions
export async function initializeProjectService(
  projectWizard: any,
  projectStatus?: string,
  shouldTrackActivity?: boolean
) {
  const { id, projectInfo, selectedOutputs, selectedStyles } = projectWizard;

  const project = {
    selectedOutputs: selectedOutputs,
    selectedStyles: selectedStyles,
    status: projectStatus || "draft",
    ...render_project_info__db_project(projectInfo),
  };

  if (!id) {
    return createNewProject(project);
  } else {
    return updateCurrentProject(id, project, shouldTrackActivity || false);
  }
}

export async function submitProjectService(
  projectWizard: any,
  shouldTrackActivity: boolean
) {
  const { id, projectInfo, selectedOutputs, selectedStyles, projectStatus } =
    projectWizard;

  const project = {
    selectedOutputs: selectedOutputs,
    selectedStyles: selectedStyles,
    status: projectStatus,
    ...render_project_info__db_project(projectInfo),
  };

  if (!id) {
    return createNewProject(project);
  } else {
    return updateCurrentProject(id, project, shouldTrackActivity);
  }
}

export async function deleteProjectService(id: string | number) {
  try {
    const { data } = await axios.delete(`/projects/${id}`);

    return { data };
  } catch (error) {
    return { error: "Something went wrong!" };
  }
}

export async function fetchProjectsService(userId: string) {
  try {
    const { data } = await axios.get(`users/${userId}/projects`);

    return { data };
  } catch (error) {
    return { error: "Something went wrong..." };
  }
}

export async function fetchProjectByIdService(id: string): Promise<Project> {
  // TODO: Add user who logged in
  const response = await axios.get(`/projects/${id}`);
  return response.data.data.project;
}

export const updateProjectByStatus = async (id: string, statusData: any) => {
  try {
    const { data } = await axios.patch(`/projects/${id}`, statusData);
    return { data };
  } catch (error) {
    return { error: "Something went wrong..." };
  }
};

export const fetchAllProjects = async ({
  status,
}: {
  status?: string;
}): Promise<Project[]> => {
  let url = "/projects";
  const params = new URLSearchParams();

  if (status) {
    params.append("status", status);
  }

  const queryString = params.toString();
  const response = await axios.get(`${url}?${queryString}`);
  return response.data.data.projects;
};

export const fetchProjectById = async (projectId: string) => {
  const response = await axios.get(`/projects/${projectId}`);
  return response.data.data.project;
};

export const fetchProjectAttachments = async (projectId: string) => {
  const response = await axios.get(`/projects/${projectId}/attachments`);
  return response.data.data.attachments;
};

// // Simple axios request without handling errors
// export const fetchProjectsAxiosRequest = () => axios.get(`/projects`);
// export const fetchCompletedProjectsAxiosRequest = () =>
//   axios.get(`/projects?status=completed`);

// export const fetchDraftProjectsAxiosRequest = async (): Promise<Project[]> => {
//   const response = await axios.get(`/projects?status=draft`);
//   return response.data.data.projects;
// };

// export const fetchPausedProjectsAxiosRequest = () =>
//   axios.get(`/projects?status=paused`);
// export const fetchActiveProjectsAxiosRequest = (userId: string) => {
//   return axios.get(`/users/${userId}/projects?status=active`);
// };
// export const fetchDeletedProjectsAxiosRequest = () =>
//   axios.get(`/projects?status=deleted`);
// export const fetchProjectByIdAxiosRequest = (id: string) => {
//   return axios.get(`/projects/${id}`);
// };
// export const fetchProjectAttachmentsAxiosRequest = (projectId: string) =>
//   axios.get(`/projects/${projectId}/attachments`);

// Handlers
async function createNewProject(project: any) {
  try {
    const { data, status } = await axios.post(`/projects`, project);

    return { data, statusCode: status };
  } catch (error) {
    return { error: "Something went wrong!" };
  }
}

async function updateCurrentProject(
  id: string,
  project: any,
  shouldTrackActivity: boolean
) {
  try {
    const { data, status } = await axios.patch(
      `/projects/${id}?trackActivity=${shouldTrackActivity ? 1 : 0}`,
      project
    );

    return { data, statusCode: status };
  } catch (error) {
    return { error: "Something went wrong!" };
  }
}

export async function updateProjectsOrder(projectsIDs: any[]) {
  const response = await axios.put(`/projects/orders/`, {
    projectIds: projectsIDs,
  });

  return response.data.data.projects;
}
