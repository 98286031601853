/////////////////////
// Schemas
/////////////////////

// Util Import
import { Project } from "types/project.types";
import { getColorOnPercentage } from "utils/getColorOnPercentage";
import { getCompletionPercentage } from "utils/getCompletionPercentage";

// Functions
export function project_table_schema(project: Project) {
  if (!project || Object.keys(project).length === 0) return {};

  const projectInfo = {
    name: project.name,
    description: project.description,
    ...project.details,
  };

  const completionPercentage = getCompletionPercentage({
    projectInfo,
    selectedAssets: project.selectedStyles,
    selectedOutputs: project.selectedStyles,
    selectedStyles: project.selectedStyles,
    attachments: project.attachments,
  });

  const row = {
    id: project._id,
    name: project.name,
    state: project.status,
    priority: 3,
    // completion: { // TODO: completionsPercentage has been removed from project schema, consult Tasnim.
    //   stage_name: "Modelling",
    //   percent: project?.completionPercentage || "0",
    //   color: project?.completionPercentage ? getColorOnPercentage(project.completionPercentage) : "",
    // },
    next_step: project.tasks,
    information: {
      percent: completionPercentage,
      color: getColorOnPercentage(completionPercentage),
    },
  };

  return row;
}
