///////////////////////////////
// Help and Support Page
///////////////////////////////

// Basic Imports

// Design Imports
import {
  Box,
  Heading,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue as mode,
} from "@chakra-ui/react";

// Layout and Section Imports
// import HelpSection from "components/sections/HelpSection";
import { ContactForm } from "components/ui/common/ContactForm";

// Data Imports

// Services Imports

// Interfaces

// Functions
const HelpPage = ({ project = [] }) => {
  return (
    <SimpleGrid columns={1} width="100%" spacing="6" id="SimpleGrid">
      {/* Help section */}
      {/* <HelpSection /> */}

      <Box as="section" pb={{ lg: "12" }} px="2">
        <Stack
          w="full"
          align="center"
          spacing={6}
          py={6}
          bg="white"
          rounded="lg"
        >
          <Heading color="black" textTransform="capitalize" textAlign="left">
            Contact our admin staff
          </Heading>
        </Stack>
        <Box
          bg={mode("gray.800", "gray.700")}
          rounded={{ base: "xl", lg: "2xl" }}
          w="full"
          mx="auto"
          px={{ base: "6", sm: "8", lg: "10" }}
          py={{ base: "12", sm: "16" }}
        >
          <Box
            maxW={{ base: "full", md: "2xl" }}
            mx="auto"
            color="white"
            textAlign="center"
          >
            <Text color="gray.300" fontSize="lg" maxW="xl" mx="auto">
              Fill out this form for assistance.
            </Text>

            <ContactForm />
          </Box>
        </Box>
      </Box>
    </SimpleGrid>
  );
};

export default HelpPage;
