import { useAuth } from "context/AuthProvider";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { ROUTES } from "settings/constants/routes";

const AuthPagesLayout = () => {
  const { user } = useAuth();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const redirectPath = params.get("redirect");

  if (!!user) {
    // Check if the redirect path starts with "offers"
    if (redirectPath && redirectPath.startsWith(ROUTES.OFFERS)) {
      return <Navigate to={redirectPath} state={{ from: location }} replace />;
    }

    // Default redirect if no specific path or not starting with "offers/"
    return <Navigate to={ROUTES.ORDERS} state={{ from: location }} replace />;
  }

  return <Outlet />;
};

export default AuthPagesLayout;
