/////////////////////
// Project Wizard Utilities
/////////////////////

// Basic Imports
import _ from "lodash";

// Design Imports

// Layout and Section Imports

// Data Imports
import { updateArray } from "./arrays";

// Interfaces
interface FilterNextTypesFromCurrSelectionProps {
  currAssetTitle?: any;
  allOptions: any[];
  currSelectedOption: any;
  selectedOptions?: any[];
}

// Custom Hooks and Services

// Functions
export const filter_selected_from_selectedStyles = ({
  selectedStyles,
  assetKey,
  currOption,
}: any) => {
  const selectedTypes =
    selectedStyles.find((style: any) => style.title === assetKey)?.types ||
    null;
  const selectedItems =
    selectedTypes?.find((selected: any) => selected.title === currOption.title)
      ?.selectedItems || [];

  return selectedItems;
};

export const target_style_from_assetTitle = ({
  selectedStyles,
  title,
}: any) => {
  const targetStyle = _.find(selectedStyles, { title });
  const targetStyleIndex = _.findIndex(selectedStyles, { title });

  return { targetStyle, targetStyleIndex };
};

export const target_type_from_all_types_by_title = ({
  allTypes,
  title,
}: any) => {
  const targetType = allTypes.find((option: any) => option.title === title);
  const targetTypeIndex = allTypes.findIndex(
    (option: any) => option.title === title
  );

  return { targetType, targetTypeIndex };
};

export const target_style_type_from_targetStyle = ({
  targetStyle,
  selectedType,
}: any) => {
  const targetStyleType = targetStyle.types.find(
    (type: any) => type.title === selectedType
  );
  const targetStyleTypeIndex = targetStyle.types.findIndex(
    (type: any) => type.title === selectedType
  );

  return { targetStyleType, targetStyleTypeIndex };
};

export const filter_next_types_from_curr_selection = ({
  currAssetTitle,
  allOptions,
  currSelectedOption,
  selectedOptions,
}: FilterNextTypesFromCurrSelectionProps) => {
  // const { targetType, targetTypeIndex } = target_type_from_all_types_by_title({ allTypes: allOptions, title: currSelectedOption.title });
  const { targetTypeIndex } = target_type_from_all_types_by_title({
    allTypes: allOptions,
    title: currSelectedOption.title,
  });
  let nextArrays = [...allOptions.slice(targetTypeIndex + 2)];
  if (!!selectedOptions && selectedOptions.length > 0) {
    nextArrays = [...selectedOptions.slice(targetTypeIndex + 2)];
  }

  // All values from current selected option
  const subStyleValues = currSelectedOption.items.map(
    (item: any) => item.value
  );

  // Filters sub styles by prereq
  const filteredStyles = [];
  // for (let i = 0; i < nextArrays.length; i++) {
  const style = allOptions[targetTypeIndex + 1];
  let filteredSubStyles = style?.items.filter((subStyle: any) => {
    return subStyleValues.some(
      (selectedValue: any) =>
        subStyle.prereq === selectedValue || subStyle.prereq === ""
    );
  });
  filteredStyles.push({ ...style, items: filteredSubStyles }, ...nextArrays);
  // filteredStyles.push({ ...style, items: filteredSubStyles });
  // }

  return [...filteredStyles];
};

export const filter_next_type_from_curr_selection = ({
  allOptions,
  currSelectedOption,
}: any) => {
  const targetTypeIndex = allOptions.findIndex(
    (option: any) => option.title === currSelectedOption.title
  );

  const nextArrays = [...allOptions.slice(targetTypeIndex + 2)];

  // All values from current selected option
  const subStyleValues = currSelectedOption.items.map(
    (item: any) => item.value
  );

  // Filters sub styles by prereq
  const filteredStyles = [];
  const style = allOptions[targetTypeIndex + 1];
  let filteredSubStyles = style?.items.filter((subStyle: any) => {
    return subStyleValues.some(
      (selectedValue: any) =>
        subStyle.prereq === selectedValue || subStyle.prereq === ""
    );
  });
  filteredStyles.push({ ...style, items: filteredSubStyles }, ...nextArrays);

  return [...filteredStyles];
};

export const updateProjectWizardAssetsByPrereq = ({
  prevAssets,
  selectedAssets,
  currAssetTitle,
  currSelectedOption,
}: any) => {
  let prevTypes = [];
  // Eric: nextTypes is never really used
  let newSelectedAssetsTest = [...prevAssets];

  const { targetStyle } = target_style_from_assetTitle({
    selectedStyles: selectedAssets,
    title: currAssetTitle,
  });
  if (!!targetStyle) {
    const types = targetStyle.stylesData;
    const targetTypesIndex = types.findIndex(
      (option: any) => option.title === currSelectedOption.title
    );

    // If selected style is from last type then no need filteration
    if (types.length === targetTypesIndex + 1) return [...selectedAssets];

    prevTypes = [...types.slice(0, targetTypesIndex + 1)];
    newSelectedAssetsTest = [...selectedAssets];
  } else {
    const { targetStyle } = target_style_from_assetTitle({
      selectedStyles: prevAssets,
      title: currAssetTitle,
    });
    const types = targetStyle.stylesData;
    const targetTypesIndex = types.findIndex(
      (option: any) => option.title === currSelectedOption.title
    );

    prevTypes = [...types.slice(0, targetTypesIndex + 1)];
  }

  const { targetStyle: targetAsset, targetStyleIndex: targetAssetIndex } =
    target_style_from_assetTitle({
      selectedStyles: prevAssets,
      title: currAssetTitle,
    });
  const nextTypesFromCurrSelection = filter_next_types_from_curr_selection({
    currAssetTitle: currAssetTitle,
    allOptions: targetAsset?.stylesData,
    currSelectedOption: currSelectedOption,
    selectedOptions: targetStyle?.stylesData,
  });

  const assetWithFilteredNextTypes = {
    ...targetAsset,
    stylesData: [...prevTypes, ...nextTypesFromCurrSelection],
  };
  newSelectedAssetsTest[targetAssetIndex] = assetWithFilteredNextTypes;
  return [...newSelectedAssetsTest];
};

export const update_selected_styles_by_selected_type_option = (
  selectedStyles: any[],
  currAssetTitle: string,
  selectedTypeOption?: any
) => {
  // Create copy of selectedStyles
  const newSelectedStyles = [...selectedStyles];

  // First find the parent style and index (Asset)
  const { targetStyle, targetStyleIndex } = target_style_from_assetTitle({
    selectedStyles: newSelectedStyles,
    title: currAssetTitle,
  });
  const { targetStyleType, targetStyleTypeIndex } =
    target_style_type_from_targetStyle({
      targetStyle,
      selectedType: selectedTypeOption.type,
    });

  // Update all that type with new selections.
  const updatedTypes = updateArray(targetStyle.types, targetStyleTypeIndex, {
    ...targetStyleType,
    selectedItems: selectedTypeOption.items,
  });
  newSelectedStyles[targetStyleIndex] = { ...targetStyle, types: updatedTypes };

  return newSelectedStyles;
};

export const update_selected_styles_by_selected_assets = (
  selectedStyles: any[],
  selectedAssets: any[],
  selectedTypeOption?: any
) => {
  const newSelectedStyles = [];
  for (let i = 0; i < selectedAssets.length; i++) {
    const newSelectedItems = [];
    for (let j = 0; j < selectedAssets[i].stylesData.length; j++) {
      const styleDataOfAsset = {
        asset: { title: selectedAssets[i].title, id: selectedAssets[i].id },
        ...selectedAssets[i].stylesData[j],
      };

      const styleDataOfSelectedStyle = {
        asset: { id: selectedStyles[i].id, title: selectedStyles[i].title },
        ...selectedStyles[i].types[j],
      };

      const extractSimilar = _.intersectionBy(
        styleDataOfAsset.items,
        styleDataOfSelectedStyle.selectedItems,
        "value"
      );

      if (
        selectedTypeOption &&
        styleDataOfSelectedStyle.type === selectedTypeOption.type
      ) {
        newSelectedItems.push({
          ...styleDataOfSelectedStyle,
          selectedItems: selectedTypeOption.items,
        });
      } else if (extractSimilar.length > 0) {
        newSelectedItems.push({
          ...styleDataOfSelectedStyle,
          selectedItems: extractSimilar,
        });
      } else {
        newSelectedItems.push({
          ...styleDataOfSelectedStyle,
          selectedItems: [styleDataOfAsset.items[0]],
        });
      }
    }
    newSelectedStyles.push({
      ...selectedStyles[i],
      types: newSelectedItems,
    });
  }

  return newSelectedStyles;
};
