//////////////////////////////////////
// Styles Cards for Summary Section
/////////////////////////////////////

// Basic Imports
import { SimpleGrid } from "@chakra-ui/react";

// Context API
import { useProject } from "context/project/ProjectProvider";

// UI or Layouts
import { StyleCardForSteps } from "components/ui/common/StyleCardForSteps";

const StyleCardsReadOnly = () => {
  const { projectWizard } = useProject();

  return (
    <SimpleGrid maxW="7xl" columns={1} spacing={5} pb={5}>
      {projectWizard.selectedAssets.map((currentValue: any, index: number) => {
        return (
          <StyleCardForSteps
            key={index}
            id={currentValue?.id}
            title={currentValue?.title}
            options={currentValue.stylesData}
            isReadOnly={true}
            showSelectedOnly={true}
            onSelected={() => {}}
          />
        );
      })}
    </SimpleGrid>
  );
};

export default StyleCardsReadOnly;
