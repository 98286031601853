import { API_URL } from "settings/site-credentials";
import axios from "libraries/axios";
import { Comment } from "types/comment.types";

interface DataProps {
  text: string;
  html: string;
  projectId: string;
  attachments: any[];
}
export async function createComment(data: DataProps): Promise<Comment> {
  const body = {
    text: data.text,
    htmlText: data.html,
    projectId: data.projectId,
    attachments: data.attachments,
  };

  const response = await axios.post(`/comments`, body);
  return response.data.data.comment;
}

export async function fetchAllCommentsByProjectId(
  projectId: string
): Promise<Comment[]> {
  const response = await axios.get(`/projects/${projectId}/comments`);
  return response.data.data.comments;
}

export async function getCommentById(commentId: string, userToken: string) {
  try {
    const { data } = await axios.get(`${API_URL}/comments/${commentId}`, {
      headers: { Authorization: `Bearer ${userToken}` },
    });

    return { data };
  } catch (error) {
    return { error: "Something went wrong!" };
  }
}

export async function fetchAllCommentsByAttachmentId(
  attachmentId: string,
  userToken: string
) {
  try {
    const { data } = await axios.get(
      `${API_URL}/attachments/${attachmentId}/comments`,
      { headers: { Authorization: `Bearer ${userToken}` } }
    );

    return { data };
  } catch (error) {
    return { error: "Something went wrong!" };
  }
}
