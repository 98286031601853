import { Outlet } from "react-router-dom";
import { Box, Container, Flex } from "@chakra-ui/react";
import AccountSidebar from "./AccountSidebar";

const AccountLayout = () => {
  return (
    <Box as="section" pt={{ base: "4", md: "8" }} pb={{ base: "12", md: "24" }}>
      <Container maxW="container.xl">
        <Flex
          as="section"
          minH="100vh"
          direction={{ base: "column", md: "row" }}
        >
          <AccountSidebar />
          <Box flex="1" bg="bg.surface">
            <Outlet />
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};

export default AccountLayout;
