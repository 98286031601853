import axios from "libraries/axios";
import { Revision } from "types/revision.types";

export async function createRevision(
  body: Partial<Revision>
): Promise<Revision> {
  const response = await axios.post(`/revisions`, body);
  return response.data.data.revision;
}

export async function getOrderRevisions(orderId: string): Promise<Revision[]> {
  const response = await axios.get(`/orders/${orderId}/revisions`);
  return response.data.data.revisions;
}
