/////////////////////
// Project Table Body
/////////////////////

// Basic Imports
import { Column } from "react-table";

// Design Imports

// Layout and Section Imports
import ActionColumn from "./Columns/Action";
import NextStep from "./Columns/NextStep";
import Name from "./Columns/Name";
import Priority from "./Columns/Priority";
import { TABLE_NAME } from "settings/constants/QueryTable";
import Information from "./Columns/Information";

// Data Imports

// Custom Hook Imports

// Functions

// Interfaces
interface Props {
  tableName: string;
}

const columns = (tableProps?: Props): Column[] => {
  return [
    {
      accessor: "priority", // accessor is the "key" in the data
      Header: Priority.Header, // Header is wrapper on column (e.g., you can change text color, background-color etc)
      Cell: Priority.Cell, // Cell is wrapper on row (e.g., you can change text color, background-color etc)
    },
    {
      accessor: "name", // accessor is the "key" in the data
      Header: Name.Header, // Header is wrapper on column (e.g., you can change text color, background-color etc)
      Cell: Name.Cell, // Cell is wrapper on row (e.g., you can change text color, background-color etc)
    },
    // INFO: 👇(Conditional rendering of element in array) If table is draft projects then render completion column otherwise remove
    ...(tableProps?.tableName === TABLE_NAME.DRAFT_TABLE
      ? [
          {
            accessor: "information",
            Header: (headerProps: any) => (
              <Information.Header
                tableName={tableProps?.tableName}
                {...headerProps}
              />
            ),
            Cell: Information.Cell,
          },
        ]
      : []),
    // COMBAK: Hiding actions as there are other items more important to complete at this time.
    {
      accessor: "actions",
      Header: ActionColumn.Header,
      Cell: (cellProps) => (
        <ActionColumn.Cell tableName={tableProps?.tableName} {...cellProps} />
      ),
    },
    {
      accessor: "next_step",
      Header: NextStep.Header,
      Cell: NextStep.Cell,
    },
  ];
};

export { columns };
