/////////////////////
// Profile/Form Page
/////////////////////

// Basic Imports
import { FC, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import TextInputField from "components/ui/common/TextInputField";

// Design Imports
import {
  Avatar,
  Box,
  BoxProps,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

// Layout and Section Imports

// Hooks
import useProfilePage, { FormDataTypes } from "./useProfilePage";
import useCustomToast from "hooks/useCustomToast";
import { Dropzone } from "hr-design-system";
import CountryField from "./CountryField";
import CityField from "./CityField";

// Validation Schema
const schema = yup.object().shape({
  firstName: yup.string().required().label("First name"),
  lastName: yup.string().required().label("Last name"),
  company: yup.string().label("Company"),
  addressLine1: yup.string().label("Address Line 1"),
  addressLine2: yup.string().label("Address Line 2"),
  country: yup.string().required().label("Country"),
  city: yup.string().label("City"),
  province: yup.string().label("Province or State"),
  avatar: yup.string(),
  postalCode: yup.string().label("Postal Code or ZIP Code"),
});

interface Props {
  styleProps?: BoxProps;
  defaultValues: FormDataTypes;
}
interface ProfilePreview {
  file: File | null;
  url: string;
}
const ProfileForm: FC<Props> = ({ styleProps, defaultValues }) => {
  const boxShadow = useColorModeValue("sm", "sm-dark");
  const { updateProfileMutation } = useProfilePage();
  const methods = useForm<FormDataTypes>({
    defaultValues,
    resolver: yupResolver(schema),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;
  const { errorToast } = useCustomToast();
  const [profilePreview, setProfilePreview] = useState<ProfilePreview>({
    file: null,
    url: defaultValues.avatar,
  });

  return (
    <FormProvider {...methods}>
      <Box
        as="form"
        bg="bg-surface"
        boxShadow={boxShadow}
        border="1px solid"
        borderColor="#e2e8f0"
        rounded="md"
        {...styleProps}
        onSubmit={handleSubmit(async (formData) => {
          console.log({ formData, profilePreview });
          updateProfileMutation.mutate({
            ...formData,
            avatar: profilePreview.file,
          });
        })}
      >
        <Stack
          spacing="5"
          px={{ base: "4", md: "6" }}
          py={{ base: "5", md: "6" }}
        >
          <FormControl id="picture">
            <FormLabel>
              Picture{" "}
              <Text as="span" color="dimgrey" fontSize="sm" fontStyle="italic">
                (You have to press "save" button to reflect changes in backend)
              </Text>
            </FormLabel>
            <Stack
              spacing={{ base: "3", md: "5" }}
              direction={{ base: "column", sm: "row" }}
            >
              <Avatar size="xl" src={profilePreview.url} />
              <Box w="full">
                <Dropzone
                  acceptOnlyImages={true}
                  showPreviews={false}
                  wrapInModal={false}
                  allowMultiple={false}
                  onUpload={(file) => {
                    const profileImage = Array.isArray(file) ? file[0] : file;
                    if (!profileImage) {
                      errorToast({
                        title: "Failed to upload file",
                        description: "Please try reload or contact support",
                      });
                      return;
                    }

                    const url = URL.createObjectURL(profileImage);
                    setProfilePreview({ file: profileImage, url });
                  }}
                />
              </Box>
            </Stack>
          </FormControl>
          <Stack spacing="6" direction={{ base: "column", md: "row" }}>
            <TextInputField
              label="First Name"
              errorMessage={errors?.firstName?.message}
              {...register("firstName")}
            />
            <TextInputField
              label="Last Name"
              {...register("lastName")}
              errorMessage={errors.lastName?.message}
            />
          </Stack>
          <Stack spacing="6" direction={{ base: "column", md: "row" }}>
            <TextInputField
              label="Company"
              {...register("company")}
              isDisabled
              errorMessage={errors.company?.message}
            />
            <TextInputField
              label="AddressLine 1"
              {...register("addressLine1")}
              errorMessage={errors.addressLine1?.message}
            />
            <TextInputField
              label="AddressLine 2"
              {...register("addressLine2")}
              errorMessage={errors.addressLine2?.message}
            />
          </Stack>

          <Stack spacing="6" direction={{ base: "column", md: "row" }}>
            <CountryField />
            <CityField />
          </Stack>
          <Stack spacing="6" direction={{ base: "column", md: "row" }}>
            <TextInputField
              label="State / Province"
              {...register("province")}
              errorMessage={errors.province?.message}
            />
            <TextInputField
              label="ZIP / Postal Code"
              {...register("postalCode")}
              errorMessage={errors.postalCode?.message}
            />
          </Stack>
        </Stack>
        <Divider />
        <Flex direction="row-reverse" py="4" px={{ base: "4", md: "6" }}>
          <Button
            type="submit"
            variant="primary"
            bg="blue.500"
            color="white"
            isLoading={updateProfileMutation.isPending}
          >
            Save
          </Button>
        </Flex>
      </Box>
    </FormProvider>
  );
};

export default ProfileForm;
