import { FC } from "react";
import { Button, ButtonProps } from "@chakra-ui/react";
import { useLocation, Link as RouterLink } from "react-router-dom";

interface Props extends ButtonProps {
  href?: string;
}

export const NavButton: FC<Props> = ({ href, ...rest }) => {
  const location = useLocation();
  const isActive = location.pathname === href;

  if (!href) {
    return (
      <Button
        variant={isActive ? "solid" : "ghost"}
        justifyContent="start"
        iconSpacing="3"
        {...rest}
      />
    );
  }

  return (
    <RouterLink to={href}>
      <Button
        variant={isActive ? "solid" : "ghost"}
        w="full"
        justifyContent="start"
        iconSpacing="3"
        {...rest}
      />
    </RouterLink>
  );
};
