/////////////////////
// New Project Page
/////////////////////

// Basic Imports
import { FC, Fragment, useEffect, useState } from "react";

// Design Imports
import {
  Box,
  Button,
  // Container,
  Flex,
  HStack,
  Icon,
  Tooltip,
  Wrap,
} from "@chakra-ui/react";
import { HiPlus } from "react-icons/hi";

// Layout and Section Imports
import { Steps } from "components/ui/steps/Steps";
import ProjectInfoStep from "components/sections/ProjectsSection/Steps/AProjectInfo/ProjectInfoStep";
import DesiredAssetsStep from "components/sections/ProjectsSection/Steps/BDesiredAssets/DesiredAssetsStep";
import OutputStep from "components/sections/ProjectsSection/Steps/COutput/OutputStep";
import StyleStep from "components/sections/ProjectsSection/Steps/DStyle/StyleStep";
import SupportingDocumentsStep from "components/sections/ProjectsSection/Steps/ESupportingDocuments/SupportingDocumentsStep";
import SummaryStep from "components/sections/ProjectsSection/Steps/FSummary/SummaryStep";

import ProjectsContainer from "components/sections/ProjectsSection/ProjectsContainer";

// Data Imports
import { assets } from "data/projectWizardData";
import useNewProjectPage from "./useNewProjectPage";
// import Alert from "components/features/Alert";
import Loader from "components/ui/common/Loader";
import WrapStep from "components/ui/steps/WrapStep";
import DragDropTable from "components/sections/ProjectsSection/Table/DragDropTable";
import { columns } from "components/sections/ProjectsSection/Table/Body";
import { TABLE_NAME } from "settings/constants/QueryTable";
import TableHeading from "components/sections/ProjectsSection/Table/TableHeading";
import ButtonTooltip from "components/ui/common/Tooltips/ButtonTooltip";
import ActionButton from "components/sections/ProjectsSection/Table/Body/ActionButton";
import { TOOLTIP } from "settings/constants/projectWizard";
import Alert from "components/features/Alert";
import { useAuth } from "context/AuthProvider";
import { project_table_schema } from "schemas/project_table";
import useCustomToast from "hooks/useCustomToast";
import useUserSubscriptions from "hooks/useUserSubscriptions";

// Services Imports

// Interfaces

// Functions
const NewProjectsPage: FC = () => {
  const { user, isLoading: userLoading } = useAuth();
  const { stepState, draftProjects, projectContext, handlers, btnLoading } =
    useNewProjectPage({ shouldFetchDraftProjects: true });
  const { errorToast } = useCustomToast();
  const { data: subscriptions } = useUserSubscriptions();
  const activeSubscription = subscriptions?.find(
    (sub) => sub.status === "active"
  );
  const [loading, setLoading] = useState(true);
  const [shouldAllowSave, setShouldAllowSave] = useState({
    isLoading: false,
    isTrue: false,
  }); // DOC: This loading means context api is loading and we can use to disable buttons that send context api state to backend

  useEffect(() => {
    projectContext.loadProjectWizardFromLocalStorage();
    setLoading(false);

    return () => {
      projectContext.onResetProjectWizard();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // TODO: This should be set in parent component, if not available then create a new one.
  if (!!userLoading || !!loading || draftProjects.isLoading) return <Loader />;
  if (!user)
    return (
      <Alert
        status="error"
        title="Authentication failed."
        description="Please try to authenticate or contact support"
      />
    );

  return (
    <Fragment>
      <DragDropTable
        isLoading={draftProjects.isLoading}
        title="Resume a Draft"
        columns={columns({ tableName: TABLE_NAME.DRAFT_TABLE })}
        data={draftProjects.projects.map((project) =>
          project_table_schema(project)
        )}
        updateDataOnPriority={draftProjects.onChangePriorities}
        setData={(data) => console.log({ data })}
      />

      {/* INFO: First initialize the project with button then show the form */}
      {!projectContext.projectWizard.hasProjectInitialized ? (
        <Flex justifyContent="right">
          <Tooltip
            hasArrow
            placement="bottom"
            // label={
            //   user?.subscription?.plan === "none" && remainingDays > 1
            //     ? `Subscription has been canceled but ${remainingDays} days are remaining in your account`
            //     : ""
            // }
          >
            <Button
              isLoading={projectContext.initializeLoading}
              colorScheme="blue"
              leftIcon={<Icon fontSize="24" as={HiPlus} />}
              onClick={() => {
                if (!!activeSubscription) {
                  projectContext.onInitializeProject();
                } else {
                  errorToast({
                    title: "Unable to create new project.",
                    description:
                      "You are not subscribed with any plan. So please go to billing and subscribe to create new project.",
                  });
                }
              }}
            >
              Create new project
            </Button>
          </Tooltip>
        </Flex>
      ) : (
        <ProjectsContainer>
          <Wrap justify="space-between" pb="6" align="center">
            <TableHeading>New Project Wizard</TableHeading>
            <ButtonTooltip label="It will remove this project from draft projects.">
              <ActionButton
                btnText="Delete Draft"
                colorScheme="red"
                mr={2}
                isLoading={btnLoading.btn === "delete" && btnLoading.isLoading}
                alertDialog={{
                  title: "Delete Project Draft",
                  description:
                    "Are you sure? This action will delete all the information that you've entered.",
                  onSuccess: () =>
                    handlers.deleteProjectMutation.mutate({
                      shouldRedirect: true,
                    }),
                }}
              />
            </ButtonTooltip>
            <HStack>
              <ButtonTooltip
                label="It will reset the project wizard"
                placement="top"
              >
                <ActionButton
                  btnText="Close"
                  colorScheme="blue"
                  variant="outline"
                  mr={2}
                  isLoading={btnLoading.btn === "close" && btnLoading.isLoading}
                  // isLoading={handlers.addDraftProjectMutation.isPending}
                  alertDialog={{
                    title: "Close Draft",
                    description:
                      "Are you sure? Any recent unsaved changes will be discarded",
                    onSuccess: async () => {
                      handlers.addDraftProjectMutation.mutate({});
                      projectContext.onCloseProjectWizard();
                    },
                  }}
                />
              </ButtonTooltip>
              <ButtonTooltip
                placement="top"
                label="It will save project in draft projects"
              >
                <Button
                  colorScheme="blue"
                  mr={2}
                  isDisabled={!shouldAllowSave.isTrue}
                  isLoading={btnLoading.btn === "draft" && btnLoading.isLoading}
                  onClick={() => handlers.addDraftProjectMutation.mutate({})}
                >
                  Save As Draft
                </Button>
              </ButtonTooltip>
            </HStack>
          </Wrap>
          <Steps activeStep={stepState.activeStep}>
            <WrapStep
              title="Project Info"
              nextStep={() => {
                stepState.nextStep();
                if (!shouldAllowSave.isTrue) return;

                handlers.addDraftProjectMutation.mutate({});
              }}
              onStepClick={() => stepState.setStep(0)}
            >
              <ProjectInfoStep />
            </WrapStep>
            <DesiredAssetsStep
              assets={assets}
              prevStep={stepState.prevStep}
              onStepClick={() => stepState.setStep(1)}
              onSelected={(selectedItems) =>
                setShouldAllowSave((prevState) => ({
                  ...prevState,
                  isTrue: selectedItems.length > 0,
                }))
              }
              isStepLoading={(value) =>
                setShouldAllowSave((prevState) => ({
                  ...prevState,
                  isLoading: value,
                }))
              }
              nextStep={() => {
                stepState.nextStep();
                handlers.addDraftProjectMutation.mutate({});
              }}
            />

            <WrapStep
              title="Outputs"
              prevStep={stepState.prevStep}
              disableNextButton={!shouldAllowSave.isTrue}
              titleTooltip={
                !shouldAllowSave.isTrue ? TOOLTIP.MINIMUM_DESIRED_ASSETS : ""
              }
              disableNextButtonReason={TOOLTIP.MINIMUM_DESIRED_ASSETS}
              onStepClick={() => stepState.setStep(2)}
              nextStep={() => {
                stepState.nextStep();
                handlers.addDraftProjectMutation.mutate({});
              }}
            >
              <OutputStep />
            </WrapStep>

            <WrapStep
              title="Styles"
              prevStep={stepState.prevStep}
              onStepClick={() => shouldAllowSave.isTrue && stepState.setStep(3)}
              disableNextButton={!shouldAllowSave.isTrue}
              disableNextButtonReason={TOOLTIP.MINIMUM_DESIRED_ASSETS}
              titleTooltip={
                !shouldAllowSave.isTrue ? TOOLTIP.MINIMUM_DESIRED_ASSETS : ""
              }
              nextStep={async () => {
                stepState.nextStep();
                handlers.addDraftProjectMutation.mutate({});
              }}
            >
              <StyleStep />
            </WrapStep>

            <WrapStep
              title="Supporting Documents"
              prevStep={stepState.prevStep}
              onStepClick={() => shouldAllowSave.isTrue && stepState.setStep(4)}
              disableNextButton={!shouldAllowSave.isTrue}
              disableNextButtonReason={TOOLTIP.MINIMUM_DESIRED_ASSETS}
              titleTooltip={
                !shouldAllowSave.isTrue ? TOOLTIP.MINIMUM_DESIRED_ASSETS : ""
              }
              nextStep={async () => {
                stepState.nextStep();
                handlers.addDraftProjectMutation.mutate({});
              }}
            >
              <SupportingDocumentsStep userId={user.id} />
            </WrapStep>

            <WrapStep
              title="Summary"
              prevStep={stepState.prevStep}
              onStepClick={() => shouldAllowSave.isTrue && stepState.setStep(5)}
              titleTooltip={
                !shouldAllowSave.isTrue ? TOOLTIP.MINIMUM_DESIRED_ASSETS : ""
              }
              disableNextButton={!shouldAllowSave.isTrue}
              disableNextButtonReason={TOOLTIP.MINIMUM_DESIRED_ASSETS}
              nextStep={() => {
                stepState.nextStep();
              }}
            >
              <SummaryStep userId={user.id} />
            </WrapStep>
          </Steps>

          <Box mt="8" ml="1">
            <ButtonTooltip
              hasArrow
              label={!shouldAllowSave.isTrue && TOOLTIP.MINIMUM_DESIRED_ASSETS}
            >
              <Button
                colorScheme="blue"
                isLoading={
                  btnLoading.btn === "active" ? btnLoading.isLoading : false
                }
                isDisabled={!shouldAllowSave.isTrue}
                onClick={() => {
                  handlers.submitProjectMutation.mutate({
                    shouldRedirect: true,
                    projectStatus: "active",
                  });
                }}
              >
                Submit
              </Button>
            </ButtonTooltip>
          </Box>
        </ProjectsContainer>
      )}
    </Fragment>
  );
};
export default NewProjectsPage;
