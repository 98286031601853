/////////////////////
// Notification (appPages) UI
/////////////////////

// Basic Imports

// Design Imports
import {
  Box,
  Center,
  CenterProps,
  useColorModeValue as mode,
} from "@chakra-ui/react";

import { IoNotifications } from "react-icons/io5";
// Layout and Section Imports

// Data Imports

// Interfaces

// Functions
const NotificationBadge = (props: CenterProps) => (
  <Center
    color="white"
    p="2"
    bg="red.500"
    fontSize="xs"
    fontWeight="bold"
    position="absolute"
    rounded="lg"
    textAlign="center"
    top="-4px"
    insetEnd="0"
    right={{ base: "-4px", md: "-12px" }}
    w={{ base: "16px", md: "22px" }}
    h={{ base: "16px", md: "20px" }}
    {...props}
  />
);

interface NotificationProps extends CenterProps {
  count: number;
}
export const Notification = (props: NotificationProps) => (
  <Center
    as="button"
    outline="0"
    h="8"
    position="relative"
    rounded="lg"
    color={mode("gray.700", "gray.600")}
    _hover={{ bg: "whiteAlpha.200" }}
    _focus={{ shadow: "outline" }}
    {...props}
  >
    {props.count > 0 && <NotificationBadge>{props.count}</NotificationBadge>}
    <Box
      as={IoNotifications}
      fontSize={{ base: "md", md: "lg" }}
      color={"gray.600"}
    />
  </Center>
);
