import { differenceInDays } from "date-fns";
import { ISubscription, ISubscriptionPlan } from "types/stripe.types";
import axiosInstance from "libraries/axios";

export async function giveRemainingDaysForSubscription(
  currentPeriodEnd: number,
  canceledAt: number | null
) {
  const result = differenceInDays(
    new Date(currentPeriodEnd * 1000), //currentPeriodEnd
    new Date(canceledAt ? canceledAt * 1000 : new Date()) //canceledAt
  );
  if (result < 1) return 0;
  return result;
}

export async function getUserSubscriptions(): Promise<ISubscription[]> {
  const response = await axiosInstance.get("/subscriptions");
  return response.data.data.subscriptions;
}

export async function getStripePlans(
  currency: string
): Promise<ISubscriptionPlan> {
  const response = await axiosInstance.get(
    `/subscriptions/get-plans?currency=${currency}`
  );
  return response.data.data.plans;
}

export async function createCheckoutSession(body: { priceId: string }) {
  const response = await axiosInstance.post(
    "/subscriptions/create-checkout-session",
    body
  );
  return response.data.data.sessionUrl;
}

export async function createPortalSession() {
  const response = await axiosInstance.post(
    "/subscriptions/create-portal-session"
  );

  return response.data.data.url;
}
