import { OrderMessage } from "types/message.types";
import { Order } from "types/order.types";

export const checkIfMessageFromTalent = (
  message: OrderMessage,
  order: Order
): boolean => {
  // Ensure message and order objects are defined
  if (!message || !order) {
    return false;
  }

  // Ensure message.createdBy and order.profileId are defined
  if (!message.createdBy || !order.profileId) {
    return false;
  }

  // Ensure message.createdBy.profiles is an array
  if (!Array.isArray(message.createdBy.profiles)) {
    return false;
  }

  // Ensure order.profileId.id is defined
  if (!order.profileId.id) {
    return false;
  }

  // Check if the message's creator has a profileId that matches the order's profileId
  return message.createdBy.profiles.includes(order.profileId.id);
};
