import { useQuery } from "@tanstack/react-query";
import { getOfferAttachments } from "services/attachmentsService";
import { offerAttachmentsKey } from "settings/constants/queryKeys";

const useOfferAttachments = (offerId: string) => {
  return useQuery({
    queryKey: offerAttachmentsKey(offerId),
    queryFn: () => getOfferAttachments(offerId),
    enabled: !!offerId,
  });
};

export default useOfferAttachments;
