import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { fetchAssetsByProjectId } from "services/assetService";

const useFetchAssets = () => {
  const { id: projectId } = useParams();
  const { data, ...restQueryResponse } = useQuery({
    queryKey: ["assets", projectId],
    queryFn: () => fetchAssetsByProjectId(projectId!),
    enabled: !!projectId,
  });

  return {
    assets: data?.assets || [],
    ...restQueryResponse,
  };
};

export default useFetchAssets;
