import {
  Box,
  Heading,
  SimpleGrid,
  useColorModeValue as mode,
} from "@chakra-ui/react";
import { pricingFeatures } from "data/pricingData";
import { FaCheckCircle } from "react-icons/fa";

const BillingFeatures = () => {
  return (
    <Box className="samFeatures">
      <Box
        bg={mode("gray.800", "gray.700")}
        px={{ base: 5, md: 20 }}
        py={5}
        borderRadius="2xl"
        my={5}
      >
        <Heading
          fontSize="xl"
          mb="4"
          fontWeight="bold"
          textAlign="center"
          color="white"
        >
          Features
        </Heading>
        <SimpleGrid
          columns={{ base: 1, lg: 4 }}
          spacing={{ base: "5", lg: "5" }}
          my={5}
        >
          {pricingFeatures.map((feature, idx) => (
            <Box
              key={idx}
              display="flex"
              alignItems="start"
              color="white"
              gap="2"
            >
              <Box mt={1} as={FaCheckCircle} color="white.400" />
              {feature}
            </Box>
          ))}
        </SimpleGrid>
      </Box>
    </Box>
  );
};

export default BillingFeatures;
