import { Td, Tr } from "@chakra-ui/react";

export const StaticTableRow = ({ row }: any) => {
  return (
    <Tr
      style={{ borderRadius: "20px" }}
      shadow="dark-lg"
      outline="red"
      {...row.getRowProps()}
    >
      {row.cells.map((cell: any) => {
        if (cell.column.id === "priority") {
          return (
            <Td cursor="grabbing" bg="white" {...cell.getCellProps()}>
              <span>{cell.render("Cell", {})}</span>
            </Td>
          );
        }
        return (
          <Td background="white" {...cell.getCellProps()}>
            {cell.render("Cell")}
          </Td>
        );
      })}
    </Tr>
  );
};
