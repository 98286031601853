"use client";
import { FC } from "react";
import { Card, Divider, Stack } from "@chakra-ui/react";
import OfferCardPricing from "./OfferCardPricing";
import OfferCardTitle from "./OfferCardTitle";
import OfferCardProfile from "./OfferCardProfile";
import OfferCardImages from "./OfferCardImages";
import { Offer } from "types/offer.types";
import { Link } from "react-router-dom";
import Alert from "components/features/Alert";

interface OfferCard {
  id?: string;
  offer?: Offer;
}

const OfferCard: FC<OfferCard> = ({ offer }) => {
  if (!offer)
    return (
      <Alert
        status="error"
        title="Offer not found!"
        description="Try refresh or contact support"
      />
    );
  return (
    <Link to={`/offers/${offer._id}`}>
      <Card
        variant="outline"
        cursor="pointer"
        overflow="hidden"
        colorScheme="red"
        rounded="xl"
        pos="relative"
      >
        <OfferCardImages attachments={offer?.attachments || []} />
        <Stack p="4" w="full" justifyContent="space-between" h="11rem">
          <Stack>
            <OfferCardTitle title={offer?.title} />
            <OfferCardPricing
              startingPrice={offer?.prices[0]?.amount}
              startingDelivery={offer?.prices[0]?.duration}
            />
          </Stack>
          <Stack>
            <Divider />
            <OfferCardProfile
              talent={offer?.profileId}
              ratings={offer?.ratings}
            />
          </Stack>
        </Stack>
      </Card>
    </Link>
  );
};

export default OfferCard;
