import { Box, Stack, StackDivider, Switch, Text } from "@chakra-ui/react";
import { useAuth } from "context/AuthProvider";
import useCustomToast from "hooks/useCustomToast";
import { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateCommunicationService } from "services/userProfileService";
import Alert from "components/features/Alert";
import { authUserQueryKeys } from "settings/constants/queryKeys";

const CommunicationsPage = () => {
  const { user } = useAuth();
  const { errorToast } = useCustomToast();
  const [preferences, setPreferences] = useState<{
    projects: boolean;
    newsletters: boolean;
    promotional: boolean;
  }>({ projects: false, newsletters: false, promotional: false });
  const queryClient = useQueryClient();
  const updateUserMutationQuery = useMutation({
    mutationFn: async (formData: {
      projects: boolean;
      newsletters: boolean;
      promotional: boolean;
    }) => {
      const user = await updateCommunicationService(formData);
      return user;
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: authUserQueryKeys() });
    },
    onError: (error) => {
      errorToast({
        title: "Failed to update profile",
        description: error.message,
      });
    },
  });

  useEffect(() => {
    if (!user || Object.keys(user).length === 0) return;

    setPreferences({
      projects: user?.preferences?.notifications?.projects || false,
      newsletters: user?.preferences?.emails?.newsLetters || false,
      promotional: user?.preferences?.emails?.promotional || false,
    });
  }, [user]);

  if (!user)
    return (
      <Alert
        status="error"
        title="User account not found."
        description="Please attempt a hard reload or re-authenticate. If the issue continues, kindly contact support."
      />
    );
  return (
    <Box
      as="section"
      py={{ base: "4", md: "8" }}
      border="1px solid"
      borderColor="#e2e8f0"
      rounded="md"
    >
      <Box bg="bg-surface" p={{ base: "4", md: "6" }}>
        <Stack spacing="5" divider={<StackDivider />}>
          <Stack spacing="1">
            <Text fontSize="lg" fontWeight="medium">
              Notifications
            </Text>
            <Text fontSize="sm" color="muted">
              Receive notifications from HomeRender
            </Text>
          </Stack>
          <NotificationOption
            type="Application Notifications"
            description="Receive email for application/project updates"
            isActive={preferences?.projects}
            onChange={async (value: boolean) => {
              setPreferences({ ...preferences, projects: value });
              updateUserMutationQuery.mutate({
                projects: value,
                newsletters: preferences.newsletters,
                promotional: preferences.promotional,
              });
            }}
          />
        </Stack>
      </Box>
    </Box>
  );
};

function NotificationOption({
  type,
  description,
  isActive,
  isDisabled = false,
  onChange,
}: {
  type: string;
  description: string;
  isActive: boolean;
  isDisabled?: boolean;
  onChange: (checked: boolean) => void;
}) {
  return (
    <Stack justify="space-between" direction="row" spacing="4">
      <Stack spacing="0.5" fontSize="sm">
        <Text color="emphasized" fontWeight="medium">
          {type}
        </Text>
        <Text color="muted">{description}</Text>
      </Stack>
      <Switch
        onChange={(e) => onChange(e.target.checked)}
        isChecked={isActive}
        isDisabled={isDisabled}
      />
    </Stack>
  );
}

export default CommunicationsPage;
