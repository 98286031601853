/////////////////////
// Active Projects Page
/////////////////////

// Basic Imports
import { FC, Fragment } from "react";

// Design Imports

// Layout and Section Imports
import DragDropTable from "components/sections/ProjectsSection/Table/DragDropTable";
import { columns } from "components/sections/ProjectsSection/Table/Body";

// Data Imports
import useFetchProjects from "hooks/useFetchProjects";

// Interfaces

// Custom Hooks and Services
import { TABLE_NAME } from "settings/constants/QueryTable";
import { project_table_schema } from "schemas/project_table";
import Loader from "components/ui/common/Loader";

// Functions
const ActiveProjects: FC = () => {
  const { projects, onChangePriorities, isLoading } = useFetchProjects({
    status: "active",
  });
  const formattedProjects =
    projects?.map((project) => project_table_schema(project)) || [];

  if (!!isLoading) return <Loader />;
  return (
    <Fragment>
      <DragDropTable
        columns={columns({ tableName: TABLE_NAME.ACTIVE_TABLE })}
        data={formattedProjects}
        // setData={setProjects}
        updateDataOnPriority={onChangePriorities}
      />
    </Fragment>
  );
};

export default ActiveProjects;
