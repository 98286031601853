import { NavItemProps } from "App";
import DashboardLayout from "./DashboardLayout";

const PROJECT_NAV_ITEMS: NavItemProps[] = [
  { label: "Active", href: "/projects" },
  { label: "Completed", href: "/projects/completed" },
  { label: "Draft", href: "/projects/draft" },
  { label: "Paused", href: "/projects/paused" },
  { label: "All", href: "/projects/all" },
];

const ProjectsLayout = () => {
  return (
    <DashboardLayout
      title="Projects"
      navItems={PROJECT_NAV_ITEMS}
      isProjectContainer
      isFor="projects"
    />
  );
};

export default ProjectsLayout;
