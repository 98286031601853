/////////////////////
// Profile Page
/////////////////////

// Basic Imports

// Design Imports

// Layout and Section Imports

// Custom Hooks and Services
import { updateUserProfile } from "services/userProfileService";
import useCustomToast from "hooks/useCustomToast";
import { useAuth } from "context/AuthProvider";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createAttachment } from "services/attachmentsService";
import { authUserQueryKeys } from "settings/constants/queryKeys";

// Interfaces and Types
export type FormDataTypes = {
  firstName: string;
  lastName: string;
  company: string;
  addressLine1: string;
  addressLine2: string;
  country: string;
  city: string;
  province: string;
  postalCode: string;
  avatar: string;
  email: string;
};
export type UpdateEmailFormDataTypes = {
  currEmail: string;
  newEmail: string;
  password: string;
};

const useProfilePage = () => {
  const { user, isLoading: userLoading } = useAuth();
  const { successToast, errorToast } = useCustomToast();
  const queryClient = useQueryClient();

  const updateProfileMutation = useMutation({
    mutationFn: async (
      data: Omit<FormDataTypes, "avatar"> & { avatar: File | null }
    ) => {
      let avatarUrl = "";
      if (data.avatar) {
        try {
          const attachment = await createAttachment({
            mediaResponse: data.avatar,
          });
          avatarUrl = attachment.cdnUrl;
        } catch (error) {
          errorToast({
            title: "Failed to upload avatar",
            description: "Please try again or contact support.",
          });
        }
      }

      return updateUserProfile({
        firstName: data.firstName,
        lastName: data.lastName,
        avatar: avatarUrl,
        address: {
          city: data.city,
          country: data.country,
          line1: data.addressLine1,
          line2: data.addressLine2,
          postalCode: data.postalCode,
          province: data.province,
          zipCode: data.postalCode,
          state: "",
        },
      });
    },
    onSuccess: () => {
      successToast({ title: "User profile updated successfully!" });
      queryClient.invalidateQueries({ queryKey: authUserQueryKeys() });
    },
    onError: (error) => {
      errorToast({
        title: "Failed to update profile",
        description: error.message,
      });
    },
  });

  return {
    user,
    userLoading,
    updateProfileMutation,
  };
};

export default useProfilePage;
