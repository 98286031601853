/////////////////////
// Use Selected Styles Hook
/////////////////////

// Basic Imports

// Design Imports

// Layout and Section Imports

// Data Imports
import { SET_SELECTED_STYLES } from "context/project/ProjectTypes";

// Custom Hooks and Services

// Interfaces

// Functions
const useSelectedStyles = (state: any, dispatch: any) => {
  const handleAddSelectedStyles = (selectedStyles: any) => {
    dispatch({ type: SET_SELECTED_STYLES, payload: selectedStyles });
  };

  return {
    selectedStyles: state.projectWizard.selectedStyles,
    handleAddSelectedStyles,
  };
};

export default useSelectedStyles;
