import Alert from "components/features/Alert";
import Loader from "components/ui/common/Loader";
import { CurrencySwitch } from "pages/PlanPage/CurrencySwitch";
import { RadioPricePage } from "pages/PlanPage/RadioPricePage";
import { useLocation, useSearchParams } from "react-router-dom";
import CurrentPlanInfo from "./CurrentPlanInfo";
import SectionWrapper from "./SectionWrapper";
import { Box, Stack, Button, useDisclosure } from "@chakra-ui/react";
import BillingFeatures from "./BillingFeatures";
import SuccessModal from "./SuccessModal";
import useUserSubscriptions from "hooks/useUserSubscriptions";
import { useMutation } from "@tanstack/react-query";
import { createPortalSession } from "services/stripeService";
import useCustomToast from "hooks/useCustomToast";
import Invoices from "./Invoices";

const BillingPage = () => {
  const { data: subscriptions, isLoading, error } = useUserSubscriptions();
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const { isOpen: showPlans } = useDisclosure();
  const { errorToast } = useCustomToast();
  const currency = searchParams.get("currency") || "usd";
  const isSuccess =
    searchParams.get("status") === "success" &&
    !!searchParams.get("session_id");
  const onManageBillingMutation = useMutation({
    mutationFn: () => createPortalSession(),
    onError: () =>
      errorToast({
        title: "Failed to open portal session.",
        description: "Please try reload or contact support if error persists.",
      }),
    onSuccess: (url) => window.location.replace(url),
  });

  const customerId = subscriptions?.find((sub) => sub.customerId)?.customerId;
  const hasSubscription = subscriptions && subscriptions.length > 0;
  const currSubscription = subscriptions && subscriptions[0];

  const activeSubscription = subscriptions?.find(
    (sub) => sub.status === "active"
  );

  if (isLoading) return <Loader />;
  if (!!error || !subscriptions)
    return (
      <Alert
        status="error"
        title="Failed to fetch user subscriptions"
        description="Please attempt a hard reload or re-authenticate. If the issue continues, kindly contact support."
      />
    );
  return (
    <Stack>
      <SectionWrapper title="Billing details">
        {currSubscription && (
          <Stack spacing={6}>
            <CurrentPlanInfo
              amount={currSubscription?.amount}
              currPlan={currSubscription?.plan || ""}
              subscription={currSubscription}
            />
            <Button
              w="max"
              isLoading={onManageBillingMutation.isPending}
              onClick={() => onManageBillingMutation.mutate()}
            >
              Manage billings
            </Button>
          </Stack>
        )}

        {(!hasSubscription || showPlans) && (
          <Stack w="full">
            <BillingFeatures />
            <Stack w="full" align="center" spacing={10}>
              <CurrencySwitch
                defaultValue={currency.toUpperCase()}
                onChange={(value) => {
                  const queryParams = new URLSearchParams(location.search);
                  if (value === "CAD") {
                    queryParams.set("currency", "cad");
                  } else {
                    queryParams.delete("currency");
                  }

                  setSearchParams(queryParams);
                }}
              />
              <Box w="full">
                <RadioPricePage activePriceId={activeSubscription?.priceId} />
              </Box>
            </Stack>
          </Stack>
        )}

        <SuccessModal
          isOpen={isSuccess}
          description="You have successfully subscribed the plan."
          onClose={() => {
            const queryParams = new URLSearchParams(location.search);
            queryParams.delete("status");
            queryParams.delete("session_id");
            setSearchParams(queryParams);
          }}
        />
      </SectionWrapper>
      {!!customerId && <Invoices customerId={customerId} />}
    </Stack>
  );
};

export default BillingPage;
