import { FC } from "react";
import { Button, ButtonProps, useDisclosure } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import ConfirmationModal from "components/ui/Modals/ConfirmationModal";
import useCustomToast from "hooks/useCustomToast";
import { onUpdateOrderStatus } from "services/orderService";
import { orderKeys } from "settings/constants/queryKeys";

interface Props {
  orderId: string;
  size?: ButtonProps["size"];
}
const OrderAcceptButton: FC<Props> = ({ orderId, size = "md" }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const queryClient = useQueryClient();
  const { errorToast } = useCustomToast();
  const { mutate, isPending } = useMutation({
    mutationFn: () => onUpdateOrderStatus(orderId, "completed"),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: orderKeys(orderId) }),
    onError: () => {
      errorToast({
        title: "Failed to mark order as complete.",
        description:
          "Something went wrong, please try again or contact support",
      });
    },
  });

  const handleConfirm = () => {
    mutate();
  };

  return (
    <>
      <Button size={size} onClick={onOpen} isLoading={isPending}>
        Accept Delivery
      </Button>
      <ConfirmationModal
        onOpen={onOpen}
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={handleConfirm}
        confirmButtonColorScheme="blue"
      />
    </>
  );
};

export default OrderAcceptButton;
