import { Checkbox, Text } from "@chakra-ui/react";
import { FC } from "react";

interface Props {
  isChecked: boolean;
  onChange: (isChecked: boolean) => void;
}
const ConsentCheckbox: FC<Props> = ({ isChecked, onChange }) => {
  return (
    <Checkbox
      isChecked={isChecked}
      onChange={(value) => onChange(value.target.checked)}
    >
      <Text>
        I consent and agree to HomeRender Inc's
        <Text as="span" ml="2" color="blue.600">
          <a
            href="https://homerender.io/legal#privacy-policy"
            target="_blank"
            rel="noreferrer noopener"
          >
            Privacy Policy,
          </a>
        </Text>
        <Text as="span" ml="2" color="blue.600">
          <a
            href="https://homerender.io/legal#cookie-notice"
            target="_blank"
            rel="noreferrer noopener"
          >
            Cookie and Pixel Notice
          </a>
        </Text>
        , and
        <Text as="span" ml="1" color="blue.600">
          <a
            href="https://homerender.io/legal#terms-of-use"
            target="_blank"
            rel="noreferrer noopener"
          >
            Terms of Use.
          </a>
        </Text>
      </Text>
    </Checkbox>
  );
};

export default ConsentCheckbox;
