////////////////
// React Table Custom Hook
///////////////

// Basic Imports
import React from "react";
import { Column, useTable } from "react-table";

// Functions
const useCompleted = ({ columnsData, rowsData, ...restProps }: any) => {
  const columns: Column[] = React.useMemo(() => columnsData, []);
  const data = React.useMemo(() => rowsData, [rowsData]);

  const tableInstance = useTable({ columns, data, ...restProps });

  return tableInstance;
};

export default useCompleted;
