/////////////////////
// Use Supporting Documents Hook
/////////////////////

// Basic Imports

// Design Imports

// Layout and Section Imports

// Data Imports
import { SET_SUPPORTING_DOCUMENTS } from "context/project/ProjectTypes";

// Custom Hooks and Services

// Interfaces

// Functions
const useSupportingDocuments = (state: any, dispatch: any) => {
  const handleAddSupportingDocuments = (supportingDocuments: any) => {
    dispatch({ type: SET_SUPPORTING_DOCUMENTS, payload: supportingDocuments });
  };

  return {
    supportingDocuments: state.projectWizard.supportingDocuments,
    handleAddSupportingDocuments,
  };
};

export default useSupportingDocuments;
