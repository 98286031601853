import { Button } from "@chakra-ui/react";
import useQueryParams from "hooks/useQueryParams";

const OrderFinalDeliveryButton = () => {
  const { setMultipleParams } = useQueryParams();

  const handleClickSubmitReview = () => {
    setMultipleParams({
      tab: "activity",
      action: "final_delivery",
      highlight: "actions",
    });
  };

  return (
    <Button colorScheme="blue" onClick={handleClickSubmitReview}>
      Show final delivery
    </Button>
  );
};

export default OrderFinalDeliveryButton;
