import { useQuery } from "@tanstack/react-query";
import { getOrderDeliveries } from "services/orderService";
import { orderDeliveryKeys } from "settings/constants/queryKeys";

const useOrderDeliveries = (orderId: string) => {
  return useQuery({
    queryKey: orderDeliveryKeys(orderId),
    queryFn: () => getOrderDeliveries(orderId),
    enabled: !!orderId,
  });
};

export default useOrderDeliveries;
