import { FC } from "react";
import { FaBriefcase, FaTag } from "react-icons/fa6";
import RadioCheckboxes from "components/ui/common/RadioCheckboxes";

interface Props {
  link: string;
}
const RoleRadioButtons: FC<Props> = ({ link }) => {
  return (
    <RadioCheckboxes
      options={[
        {
          label: "Customer",
          value: "buyer",
          description: "I want to buy services or hire freelancers.",
          icon: FaBriefcase,
        },
        {
          label: "Talent",
          value: "talent",
          description: "I want to sell my services.",
          icon: FaTag,
          isExternal: false,
          href: link,
        },
      ]}
      onChange={() => {}}
      defaultValue="buyer"
    />
  );
};

export default RoleRadioButtons;
