import {
  Flex,
  Hide,
  IconButton,
  Show,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
import { MdMenu } from "react-icons/md";
import SidebarContent from "./SidebarContent";
import SidebarDrawer from "./SidebarDrawer";

const AccountSidebar = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  return (
    <>
      <Show above="md">
        <Flex as="section">
          <Stack
            flex="1"
            py={"2"}
            px={{ base: "4", sm: "10" }}
            justifyContent="space-between"
          >
            <SidebarContent />
          </Stack>
        </Flex>
      </Show>

      <Hide above="md">
        <IconButton
          size="sm"
          variant="ghost"
          w="max-content"
          ml={-2}
          aria-label="Sidebar Menu"
          icon={<MdMenu size={20} />}
          onClick={onOpen}
        />

        <SidebarDrawer isOpen={isOpen} onClose={onClose}>
          <SidebarContent isFullWidth />
        </SidebarDrawer>
      </Hide>
    </>
  );
};

export default AccountSidebar;
