import { useQuery } from "@tanstack/react-query";
import { getOrderRatings } from "services/ratingService";
import { orderRatings } from "settings/constants/queryKeys";

const useOrderRatings = (orderId: string) => {
  return useQuery({
    queryKey: orderRatings(orderId),
    queryFn: () => getOrderRatings(orderId),
    enabled: !!orderId,
  });
};

export default useOrderRatings;
