/////////////////////
// Login Form
/////////////////////

// Basic Imports
import { Link } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

// Design Imports
import { Button, Checkbox, HStack, Stack, StackProps } from "@chakra-ui/react";

import { GoogleIcon } from "../../../ui/common/ProviderIcons";

// Layout and Section Imports
import FormHeader from "../FormHeader";
import FormWrapper from "../FormWrapper";

// Data Imports
import { ROUTES } from "settings/constants/routes";

// Custom Hooks and Services
import useAuthForm, { LoginFormTypes } from "../useAuthForm";
import { useRef, useState } from "react";
import { API_URL } from "settings/site-credentials";
import FormInputField from "components/forms/ReactHookForm/FormInputField";
import ConsentCheckbox from "../ConsentCheckbox";
import TurnTileWidget from "pages/AuthPage/TurnsTile/TurnTile";
import RoleRadioButtons from "../RoleRadioButtons";
import { isProduction } from "../../../../constants";

// Interface

const schema = yup.object().shape({
  email: yup.string().email().required().label("Email"),
  password: yup.string().min(6).max(20).label("Password"),
});

export const LoginForm = (props: StackProps) => {
  const methods = useForm<LoginFormTypes>({ resolver: yupResolver(schema) });
  const { loginMutation } = useAuthForm();
  const formContainer = useRef(null);
  const [isAgreed, setIsAgreed] = useState(true);
  const [verified, setVerified] = useState(false);

  const getDisabledStatus = () => {
    //Removed isAgreed as requested to login without checking checkbox
    if (!!verified) return false;

    return true;
  };

  return (
    <Stack spacing="6" {...props} h="full">
      <FormHeader
        title="Login to your account"
        description="Don't have an account?"
        link={{
          label: "Sign up",
          to: ROUTES.SIGN_UP,
        }}
      />
      <RoleRadioButtons
        link={
          isProduction
            ? "https://talent.homerender.io/login"
            : "https://staging-talent.vercel.app/login"
        }
      />
      <FormProvider {...methods}>
        <FormWrapper
          onSubmit={methods.handleSubmit((data) => {
            loginMutation.mutate(data);
          })}
        >
          <Stack ref={formContainer} spacing="5">
            <FormInputField
              type="email"
              name="email"
              label="Email"
              placeholder="Enter your mail"
            />
            <FormInputField
              name="password"
              type="password"
              label="Password"
              placeholder="Enter you password"
            />
          </Stack>
          <Stack spacing={2}>
            <HStack justify="space-between">
              <Checkbox defaultChecked>Remember me</Checkbox>
              <Button
                as={Link}
                to={ROUTES.FORGOT}
                variant="link"
                colorScheme="blue"
                size="sm"
              >
                Forgot password
              </Button>
            </HStack>
            <ConsentCheckbox isChecked={isAgreed} onChange={setIsAgreed} />
            <TurnTileWidget setVerified={setVerified} />
          </Stack>
          <Stack spacing="4">
            <Button
              isDisabled={getDisabledStatus()}
              variant="primary"
              color="white"
              bg="blue.500"
              _hover={{ backgroundColor: "blue.600" }}
              type="submit"
              isLoading={loginMutation.isPending}
            >
              Sign in
            </Button>

            <Button
              variant="secondary"
              border="1px"
              borderColor="gray.200"
              isDisabled={getDisabledStatus()}
              leftIcon={<GoogleIcon boxSize="5" />}
              iconSpacing="3"
              onClick={() => {
                window.open(`${API_URL}/auth/google`, "_self");
              }}
            >
              Sign in with Google
            </Button>
          </Stack>
        </FormWrapper>
      </FormProvider>
    </Stack>
  );
};
