/////////////////////
// Use Selected Assets Hook
/////////////////////

// Basic Imports

// Design Imports

// Layout and Section Imports

// Data Imports
import {
  SET_NEW_SELECTED_ASSETS,
  SET_SELECTED_ASSETS,
} from "context/project/ProjectTypes";

// Custom Hooks and Services

// Interfaces

// Functions
const useSelectedAssets = (state: any, dispatch: any) => {
  const handleAddSelectedAssets = (selectedAssets: any) => {
    dispatch({ type: SET_SELECTED_ASSETS, payload: selectedAssets });
  };

  const handleAddSelectedAssetsToDb = () => {
    // TODO: handleAddSelectedAssetsToDb should do something
  };

  const handleUpdateNewSelectedAssets = (newSelectedAssets: any) => {
    dispatch({ type: SET_NEW_SELECTED_ASSETS, payload: newSelectedAssets });
  };

  return {
    selectedAssets: state.projectWizard.selectedAssets,
    handleAddSelectedAssets,
    handleAddSelectedAssetsToDb,
    handleUpdateNewSelectedAssets,
  };
};

export default useSelectedAssets;
