import axios from "libraries/axios";
import { API_URL } from "settings/site-credentials";
import { FAQ } from "types/faq.types";
import { Offer } from "types/offer.types";
import { Requirement } from "types/requirement.types";
import { Review } from "types/review.types";

export async function getAllOffers(
  searchText: string = "",
  categoryId: string = ""
): Promise<Offer[]> {
  let url = `${API_URL}/offers`;
  const params = new URLSearchParams();
  if (searchText) {
    params.append("search", encodeURIComponent(searchText));
  }

  if (categoryId) {
    params.append("category", categoryId);
  }

  const queryString = params.toString();
  if (queryString) {
    url = `${url}?${queryString}`;
  }

  const response = await axios.get(url);
  const offers = response.data.data.offers || [];
  const offersWithProfiles = offers.filter(
    (offer: Offer) => offer.profileId !== null
  );

  return offersWithProfiles;
}

export async function getOfferReviews(offerId: string): Promise<Review> {
  const response = await axios.get(`/offers/${offerId}/reviews`);
  return response.data.data.reviews;
}

export const getOfferFaqs = async (offerId: string): Promise<FAQ[]> => {
  const response = await axios.get(`/offers/${offerId}/faqs`);
  return response.data.data.faqs;
};

export const getOfferRequirements = async (
  offerId: string
): Promise<Requirement[]> => {
  const response = await axios.get(`/offers/${offerId}/requirements`);
  return response.data.data.requirements;
};
