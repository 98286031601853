import axios from "libraries/axios";
import { Billing } from "types/billing.types";

export function getBillingInvoices() {
  return null;
}

export async function createCheckoutSession({
  priceId,
  offerId,
}: {
  priceId: string;
  offerId: string;
}) {
  const { data } = await axios.post(`/billings/create-checkout-session`, {
    priceId,
    offerId,
  });

  return data.data.session;
}

export async function getBilling(billingId: string): Promise<Billing> {
  const response = await axios.get(`/billings/${billingId}`);
  return response.data.data.billing;
}
