/////////////////////
// Use Draft Hook
/////////////////////

// Basic Imports

// Design Imports

// Layout and Section Imports

// Data Imports
// Context API Types
import { SET_DRAFT_PROJECTS } from "context/project/ProjectTypes";

// Custom Hooks and Services

// Interfaces

// Functions
const useDraftProject = (state: any, dispatch: any) => {
  const handleAddDraftProjects = (projects: any) => {
    dispatch({ type: SET_DRAFT_PROJECTS, payload: projects });
  };

  // Function for storing the current state of project wizard in the db
  const handleUpdateProjectDraft = async () => {
    // TODO: Asad, is this used at all or should this be deleted.
    //const restProjects = state.draftProjects.filter(project => project.id !== state.projectWizard.id);
    // let newDraftProjects: any[] = restProjects;
    // newDraftProjects.unshift(state.projectWizard);
    // await axios.post("/api/utils/createJSONFile", { path: './data/sample/draftProjects.json', body: newDraftProjects });
  };

  return {
    draftProjects: state.draftProjects,
    handleAddDraftProjects,
    handleUpdateProjectDraft,
  };
};

export default useDraftProject;
