/////////////////////
// App Layout
/////////////////////

// Basic Imports
import { FC } from "react";
import { Outlet } from "react-router-dom";

// Design Imports
import { Box } from "@chakra-ui/react";

import Header from "components/ui/appMenu/Header";

// Layout and Section Imports

// Data Imports

// Custom Hooks and Services

// Interfaces

// Functions
const AppLayout: FC = () => {
  return (
    <Box minH="100vh" overflowX="hidden">
      <Header />
      <Outlet />
    </Box>
  );
};

export default AppLayout;
