/////////////////////////////////////////////////
/// New Project Wizard Hook (useSelectedOutputs)
////////////////////////////////////////////////

// Basic Imports

// Design Imports

// Layout and Section Imports

// Data Imports
import { SET_SELECTED_OUTPUTS } from "context/project/ProjectTypes";

// Custom Hooks and Services

// Interfaces

// Functions
const useSelectedOutputs = (state: any, dispatch: any) => {
  const handleAddSelectedOutputs = (selectedOutputs: any) => {
    dispatch({ type: SET_SELECTED_OUTPUTS, payload: selectedOutputs });
  };

  const handleAddSelectedOutputsToDb = () => {
    // TODO: handleAddSelectedOutputsToDb
  };

  return {
    selectedOutputs: state.projectWizard.selectedOutputs,
    handleAddSelectedOutputs,
    handleAddSelectedOutputsToDb,
  };
};

export default useSelectedOutputs;
