import { FC, ReactNode } from "react";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
} from "@chakra-ui/react";
import RenderActivity from ".";
import { OrderActivity } from "types/order.types";

interface Props {
  children: ReactNode;
  activity: OrderActivity;
  defaultOpen?: boolean;
}

const ActivityAccordion: FC<Props> = ({
  children,
  activity,
  defaultOpen = false,
}) => {
  return (
    <Accordion allowToggle defaultIndex={defaultOpen ? [0] : undefined}>
      <AccordionItem border="none" _hover={{ bg: "transparent" }}>
        <h2>
          <AccordionButton p={0} _hover={{ bg: "transparent" }}>
            <Box flex="1" textAlign="left">
              <RenderActivity activity={activity} />
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel py={4} pb={2} px={4}>
          {children}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default ActivityAccordion;
