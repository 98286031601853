import { Button } from "@chakra-ui/react";
import useQueryParams from "hooks/useQueryParams";

const OrderRevisionButton = () => {
  const { setMultipleParams } = useQueryParams();

  const handleClickRequestRevision = () => {
    setMultipleParams({
      tab: "activity",
      highlight: "actions",
      action: "revision",
    });
  };

  return (
    <Button
      variant="ghost"
      colorScheme="red"
      onClick={handleClickRequestRevision}
    >
      Request Revision?
    </Button>
  );
};

export default OrderRevisionButton;
