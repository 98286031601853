import axios, { AxiosError } from "axios";
import Cookies from "js-cookie";
import { API_URL } from "settings/site-credentials";

export interface CustomError {
  message: string;
  statusCode: number | undefined;
}

const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Request interceptor to add the token to headers
axiosInstance.interceptors.request.use(
  (config) => {
    const token = Cookies.get("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor to handle errors globally
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error: AxiosError<{ message: string }>) => {
    console.error(
      "An error occurred",
      error?.response?.status,
      error?.response?.data
    );

    // Example: Redirect to login page on 401 Unauthorized error
    if (error.response?.status === 401) {
      // Handle unauthorized error
      // Optionally, you can redirect to the login page
      // window.location.href = "/login"; // TODO: This is creating issue, while logging in it redirects to login page again. Make sure if user is not authenticated and make request, it should redirect to login
    }

    // Handle error response
    let customError: CustomError = {
      message: error.response?.data?.message || "No error message found.",
      statusCode: error?.response?.status,
    };

    return Promise.reject(customError);
  }
);

export default axiosInstance;
