import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

type QueryParams = Record<string, string>;

const useQueryParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();

  // Get a query parameter by key
  const getParam = (key: string): string | null => {
    return searchParams.get(key) || null;
  };

  // Set a query parameter
  const setParam = (key: string, value: string): void => {
    if (!key) return;
    const newParams = new URLSearchParams(searchParams);
    newParams.set(key, value);
    setSearchParams(newParams);
  };

  // Set multiple query parameters at once
  const setMultipleParams = (params: QueryParams): void => {
    if (!params || typeof params !== "object") return;
    const newParams = new URLSearchParams(searchParams);
    Object.entries(params).forEach(([key, value]) => {
      newParams.set(key, value);
    });
    setSearchParams(newParams);
  };

  // Remove a query parameter
  const removeParam = (key: string): URLSearchParams => {
    const newParams = new URLSearchParams(searchParams);
    if (!key) return newParams;
    newParams.delete(key);
    setSearchParams(newParams);
    return newParams;
  };

  // Reset all query parameters
  const resetParams = (): void => {
    setSearchParams({});
  };

  // Replace all query parameters with a new set
  const replaceParams = (newParams: QueryParams): void => {
    if (!newParams || typeof newParams !== "object") return;
    const updatedParams = new URLSearchParams(newParams);
    setSearchParams(updatedParams);
  };

  // Append a query parameter without removing existing parameters
  const appendParam = (key: string, value: string): void => {
    if (!key) return;
    const newParams = new URLSearchParams(searchParams);
    newParams.append(key, value);
    setSearchParams(newParams);
  };

  // Get all query parameters as an object
  const getAllParams = (): QueryParams => {
    const paramsObj: QueryParams = {};
    searchParams.forEach((value, key) => {
      paramsObj[key] = value;
    });
    return paramsObj;
  };

  // Navigate to a new path while preserving current query parameters
  const navigateWithParams = (path?: string): void => {
    navigate(`${location.pathname}${location.search}`);
  };

  return {
    getParam,
    setParam,
    setMultipleParams,
    removeParam,
    resetParams,
    replaceParams,
    appendParam,
    getAllParams,
    navigateWithParams,
  };
};

export default useQueryParams;
