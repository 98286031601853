import { useQuery } from "@tanstack/react-query";
import { getOfferFaqs } from "services/offerService";
import { offerFaqsKeys } from "settings/constants/queryKeys";

const useOfferFaqs = (offerId: string) => {
  return useQuery({
    queryKey: offerFaqsKeys(offerId),
    queryFn: () => getOfferFaqs(offerId),
    refetchOnWindowFocus: false,
  });
};

export default useOfferFaqs;
