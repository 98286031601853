import { useQuery } from "@tanstack/react-query";
import { getProfileById } from "services/profilesService";
import { profileQueryKeys } from "settings/constants/queryKeys";

const useProfile = ({ profileId }: { profileId: string }) => {
  return useQuery({
    queryKey: profileQueryKeys(profileId),
    queryFn: () => getProfileById(profileId),
    enabled: !!profileId,
  });
};

export default useProfile;
