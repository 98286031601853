/////////////////////
// Contact Form UI
/////////////////////

// Basic Imports
import { useState, useEffect } from "react";

// Design Imports
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  chakra,
  FormLabel,
  Input,
  LightMode,
  SimpleGrid,
  Select,
  Stack,
  Textarea,
} from "@chakra-ui/react";

// Services data
import axios from "axios";

// Layout and Section Imports

// Data Imports

// Interfaces

// Functions
export const ContactForm = () => {
  const [showAlert, setShowAlert] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const [reason, setReason] = useState("General Question or Comment");
  const [comment, setComment] = useState("");

  useEffect(() => {
    // Alert will be removed when we leave this page
    return () => {
      setShowAlert(false);
    };
  }, []);

  const resetForm = () => {
    setName("");
    setEmail("");
    setReason("");
    setComment("");
  };

  const handleSubmit = async () => {
    resetForm();
    const webURI = process.env.REACT_WEB_URL;

    setShowAlert(true);
    // Airtable integration
    await axios.post("/api/sheet/contact", {
      name,
      email,
      question: reason,
      comment,
    });

    // Email for support/team
    await axios.post(webURI + "/api/sendgrid/sendMail", {
      to: "contact@homerender.io",
      subject: "New Contact Form Submission - HomeRender",
      html: `
              <p>Hello Team,</p>
              <p>A new contact form submission has been received from:</p>
              <p><strong>Name:</strong> ${name}</p>
              <p><strong>Email:</strong> ${email}</p>
              <p><strong>Reason for Contact:</strong> ${reason}</p>
              <p><strong>Message:</strong> ${comment}</p>
              <p>Thank you!</p>
              <p>HomeRender.io</p>
          `,
    });

    // Sendgrid integration
    const { status } = await axios.post(webURI + "/api/sendgrid/sendMail", {
      to: email,
      subject: "HomeRender: " + reason,
      text: "Your comment has been submitted. Thank you!",
      html: `
        <strong>Hi ${name}</strong>
        <p>Your comment has been submitted "${comment}"</p>

        <strong>
          <em>Thank You!</em>
          </br>
          <em>HomeRender.io</em>
        </strong>
      `,
    });

    // NOTE: To avoid security risk, all the console/debuging things shouldn't be exposed in production phase
    if (process.env.NODE_ENV !== "development") return; // if it not development environment then don't render below code

    if (status === 200) {
      console.log("Email sent successfully through sendgrid!");
    } else {
      console.log("Failed to send email through sendgrid...");
    }
  };

  return (
    <chakra.form
      onSubmit={async (e) => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <LightMode>
        {showAlert && (
          <Alert
            fontWeight="semibold"
            my="5"
            status="success"
            variant="solid"
            colorScheme="blue"
          >
            <AlertIcon />
            Your comments have been submitted.
          </Alert>
        )}

        <SimpleGrid pt="25" columns={1} spacing={5}>
          <Box>
            <FormLabel htmlFor="name" srOnly>
              Enter name:
            </FormLabel>
            <Input
              id="name"
              name="name"
              size="md"
              fontSize="md"
              bg="white"
              _placeholder={{ color: "gray.400" }}
              color="gray.900"
              placeholder="Name"
              focusBorderColor="blue.400"
              value={name}
              onChange={(e) => setName(e.currentTarget.value)}
              required
            />
          </Box>
          <Box>
            <FormLabel htmlFor="email" srOnly>
              Enter email:
            </FormLabel>
            <Input
              id="email"
              name="email"
              size="md"
              fontSize="md"
              bg="white"
              _placeholder={{ color: "gray.400" }}
              color="gray.900"
              placeholder="Email"
              focusBorderColor="blue.400"
              value={email}
              onChange={(e) => setEmail(e.currentTarget.value)}
              required
            />
          </Box>
          <Box>
            <FormLabel htmlFor="reason" srOnly>
              Reason for contacting us:
            </FormLabel>
            <Select
              name="reason"
              size="md"
              fontSize="md"
              bg="white"
              placeholder=""
              focusBorderColor="blue.400"
              color="gray.900"
              _placeholder={{ color: "gray.400" }}
              onChange={(e) => setReason(e.currentTarget.value)}
              value={reason}
              required
            >
              <option value="General Question or Comment">
                General Question or Comment
              </option>
              <option value="Suggestion or feature request">
                Suggestion or feature request
              </option>
              <option value="Website Help">Website Help</option>
              <option value="Sales">Sales</option>
              <option value="Billing">Billing</option>
              <option value="Press or Media Request">
                Press or Media Request
              </option>
              <option value="Investor Relations">Investor Relations</option>
              <option value="Privacy">Privacy</option>
            </Select>
          </Box>
          <Box>
            <FormLabel htmlFor="comments" srOnly>
              Comments:
            </FormLabel>
            <Textarea
              id="comments"
              name="comments"
              size="md"
              fontSize="md"
              bg="white"
              _placeholder={{ color: "gray.400" }}
              color="gray.900"
              placeholder="Enter your comments here."
              focusBorderColor="blue.400"
              value={comment}
              onChange={(e) => setComment(e.currentTarget.value)}
            />
          </Box>
          <Stack>
            <Button
              type="submit"
              size="md"
              colorScheme="blue"
              fontSize="md"
              px="10"
              w="full"
            >
              Submit
            </Button>
          </Stack>
        </SimpleGrid>
      </LightMode>
    </chakra.form>
  );
};
