import { FC } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Navigation } from "swiper";
import {
  Box,
  BoxProps,
  Icon,
  Image,
  SimpleGrid,
  Skeleton,
  Tooltip,
  Text,
} from "@chakra-ui/react";
import { SwiperPropsCustom, SwiperSlidePropsCustom } from "./types";
import { CardBadge } from "components/ui/common/CardBadge";
import PDFViewer from "libraries/PDFViewer/PDFViewer";

const SwiperWithMultipleItems: FC<SwiperPropsCustom> = ({
  slidesPerView = 3,
  spaceBetween = 30,
  styles,
  slides,
  isLoading,
  selectedSlide,
  onSlideClick,
  // ...restProps
}) => {
  const boxStyles: BoxProps = {
    my: 4,
    height: { base: "10rem", md: "12rem" },
    ...styles,
  };

  if (!!isLoading || slides.length === 0)
    return (
      <SimpleGrid columns={slidesPerView} spacing="4">
        {[...Array(slidesPerView)].map((slide, index) => {
          return <Skeleton key={index} height={boxStyles.height} />;
        })}
      </SimpleGrid>
    );

  return (
    <Box
      as={Swiper}
      preventClicks={false}
      preventClicksPropagation={false}
      slidesPerView={slidesPerView}
      spaceBetween={spaceBetween}
      navigation={true}
      modules={[Navigation]}
      style={{
        // @ts-ignore
        "--swiper-navigation-color": "#4A5568",
        "--swiper-pagination-color": "#4A5568",
      }}
      userSelect="none"
      {...boxStyles}
      // {...restProps} // TODO: I commented this to fix type warning, please test it if it doesn't create any problem, remove this todo
    >
      {slides.map((slide: SwiperSlidePropsCustom, index: number) => {
        return (
          <SwiperSlide
            style={{ overflow: "hidden" }}
            key={index}
            onClick={() => {
              if (onSlideClick && slide.imgUrl) {
                onSlideClick(slide);
              } else if (onSlideClick) {
                onSlideClick(null);
              }
            }}
          >
            <RenderSlide
              isActive={slide.id === selectedSlide?.id}
              slide={slide}
            />
          </SwiperSlide>
        );
      })}
    </Box>
  );
};

function RenderSlide({ slide, isActive }: { slide: any; isActive: boolean }) {
  return (
    <Box w="full" h="full" opacity={isActive ? 1 : 0.7} transition="all 0.5s">
      {!!slide.imgUrl && !!(slide.format != "pdf") ? (
        <Image
          src={slide.imgUrl}
          opacity="inherit"
          height="100%"
          width="100%"
          transition="inherit"
          border="2px"
          rounded="md"
          objectFit="contain"
          cursor="pointer"
          userSelect="none"
        />
      ) : !!(slide.format != "pdf") ? (
        <Icon
          as={slide?.icon}
          height={"100%"}
          width="100%"
          objectFit="contain"
          border="2px"
          rounded="md"
          cursor="pointer"
          userSelect="none"
        />
      ) : (
        <PDFViewer url={slide.thumbnailUrl} width={200} />
      )}
      {slide?.alt && (
        <CardBadge>
          <Tooltip
            label={`${slide.alt} ${slide?.quantity || ""}`}
            hasArrow
            placement="left"
          >
            <Text color="white">
              {slide.alt} {slide?.quantity || ""}
            </Text>
          </Tooltip>
        </CardBadge>
      )}
    </Box>
  );
}

export default SwiperWithMultipleItems;
