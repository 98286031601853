import Alert from "components/features/Alert";
import OrdersTable from "components/sections/OrdersSection/OrdersTable";
import SkeletonLoading from "components/ui/Loaders/SkeletonLoading";
import { useAuth } from "context/AuthProvider";
import useOrders from "hooks/useOrders";

const ActiveOrdersPage = () => {
  const { user } = useAuth();
  const { data, isLoading } = useOrders({
    userId: user?.id,
    status: "in_progress",
  });

  if (!!isLoading) return <SkeletonLoading />;
  if (!data)
    return (
      <Alert
        status="error"
        title="Something went wrong, data not found."
        showCloseIcon={false}
      />
    );
  if (data.length === 0)
    return (
      <Alert
        status="info"
        title="No active orders yet!"
        showCloseIcon={false}
      />
    );
  return <OrdersTable data={data} />;
};

export default ActiveOrdersPage;
