/////////////////////
// Profile Page
/////////////////////

// Basic Imports

// Layout and Section Imports
import ProfileForm from "./ProfileForm";
import SkeletonLoading from "components/ui/Loaders/SkeletonLoading";

// Hooks
import useProfilePage from "./useProfilePage";
import Alert from "components/features/Alert";

const ProfilePage = () => {
  const { userLoading, user } = useProfilePage();

  if (!!userLoading)
    return <SkeletonLoading h={{ base: "60vh", md: "40rem" }} />;
  if (!user)
    return (
      <Alert
        status="error"
        title="User account not found."
        description="Please attempt a hard reload or re-authenticate. If the issue continues, kindly contact support."
      />
    );
  return (
    <ProfileForm
      defaultValues={{
        firstName: user.firstName,
        lastName: user.lastName,
        avatar: user.avatar,
        email: user.email,
        company: "",
        addressLine1: user.address?.line1 || "",
        addressLine2: user.address?.line2 || "",
        city: user.address?.city || "",
        country: user.address?.country || "",
        postalCode: user.address?.postalCode || "",
        province: user.address?.province || "",
      }}
    />
  );
};

export default ProfilePage;
