/////////////////////
// Project Provider Context
/////////////////////

// Basic imports
import {
  useContext,
  useReducer,
  createContext,
  useState,
  ReactNode,
} from "react";

// Custom Hooks and Services
import { reducer } from "./ProjectReducer";
import useProjectInfo from "../../hooks/useProjectWizard/useProjectInfo";
import useSelectedAssets from "../../hooks/useProjectWizard/useSelectedAssets";
import useSelectedOutputs from "../../hooks/useProjectWizard/useSelectedOutputs";
import useSelectedStyles from "../../hooks/useProjectWizard/useSelectedStyles";
import useSupportingDocuments from "../../hooks/useProjectWizard/useSupportingDocuments";
import useDraftProject from "../../hooks/useDraftProject";
import useProjectWizard from "../../hooks/useProjectWizard";
import useProjectCompletionStage from "../../hooks/useProjectWizard/useCompletionStage";
import { project_wizard_schema } from "schemas/project_wizard_schema";

// 1- Initialize the context
export const ProjectContext = createContext({} as any);

// 2- Initial State
const INITIAL_STATE = {
  items: [],
  projectWizard: project_wizard_schema,
  newSelectedAssets: [],
  draftProjects: [],
  completionStage: "0",
  isDraft: true,
};

// 3- Use the context and do state manipulations
export function ProjectProvider({ children }: { children: ReactNode }) {
  // 3.1- Initialize the reducer
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  // 3.2- Invoke all the hooks related project wizard
  const projectInfoHook = useProjectInfo(state, dispatch);
  const useSelectedAssetsHook = useSelectedAssets(state, dispatch);
  const useSelectedOutputsHook = useSelectedOutputs(state, dispatch);
  const useSelectedStylesHook = useSelectedStyles(state, dispatch);
  const useSupportingDocumentsHook = useSupportingDocuments(state, dispatch);
  const useDraftProjectHook = useDraftProject(state, dispatch);
  const useProjectWizardHook = useProjectWizard(INITIAL_STATE, state, dispatch);
  const useProjectCompletionHook = useProjectCompletionStage(state, dispatch);
  const [initializeLoading] = useState(false);
  const [cancelButtonLoading] = useState(false);
  // 3.3- Expose the context(Provider) by adding values that should be public to use throughout the components.
  return (
    <ProjectContext.Provider
      value={{
        items: state.items,
        // Completion Stage
        completionStage: useProjectCompletionHook.completionStage,
        // New Selected Assets
        newSelectedAssets: state.newSelectedAssets,
        // Loaders
        initializeLoading: initializeLoading, // Loader
        cancelButtonLoading: cancelButtonLoading, // Loader
        // Selected Assets (Step)
        selectedAssets: useSelectedAssetsHook.selectedAssets,
        onAddSelectedAssets: useSelectedAssetsHook.handleAddSelectedAssets,
        onAddSelectedAssetsToDb:
          useSelectedAssetsHook.handleAddSelectedAssetsToDb,
        onUpdateNewSelectedAssets:
          useSelectedAssetsHook.handleUpdateNewSelectedAssets,
        // Outputs (Step)
        selectedOutputs: useSelectedOutputsHook.selectedOutputs,
        onAddSelectedOutputs: useSelectedOutputsHook.handleAddSelectedOutputs,
        onAddSelectedOutputsToDb:
          useSelectedOutputsHook.handleAddSelectedOutputsToDb,
        // Styles (Step)
        selectedStyles: useSelectedStylesHook.selectedStyles,
        onAddSelectedStyles: useSelectedStylesHook.handleAddSelectedStyles,
        // Supporting Documents (Step)
        supportingDocuments: useSupportingDocumentsHook.supportingDocuments,
        onAddSupportingDocuments:
          useSupportingDocumentsHook.handleAddSupportingDocuments,
        // Project Wizard
        projectWizard: useProjectWizardHook.projectWizard,
        loadProjectWizardFromLocalStorage:
          useProjectWizardHook.loadProjectWizardFromLocalStorage,
        onDeleteProjectWizard: useProjectWizardHook.cancelProjectWizard,
        onCloseProjectWizard: useProjectWizardHook.closeProjectWizard,
        onInitializeProject: useProjectWizardHook.initializeProject,
        onUpdateProjectWizard: useProjectWizardHook.updateProjectWizard,
        onSubmitProjectWizard: useProjectWizardHook.submitProjectWizard,
        onSaveUpdatedProjectWizard:
          useProjectWizardHook.saveUpdatedProjectWizard,
        onSetProjectWizardId: useProjectWizardHook.setProjectWizardId,
        onSaveProjectWizardAsDraft:
          useProjectWizardHook.saveProjectWizardAsDraft,
        onAddProjectIntoLocalStorage:
          useProjectWizardHook.addProjectIntoLocalStorage,
        onResetProjectWizard: useProjectWizardHook.resetProjectWizard,
        // Draft Project
        draftProjects: useDraftProjectHook.draftProjects,
        onAddDraftProjects: useDraftProjectHook.handleAddDraftProjects,
        // PROJECT INFO (Step)
        projectInfo: projectInfoHook.projectInfo,
        onAddProjectInfo: projectInfoHook.handleAddProjectInfo,
        onAddProjectInfoToDb: projectInfoHook.handleAddProjectInfoToDb,
        onChangeProjectName: projectInfoHook.handleChangeProjectName,
        onChangeProjectDescription:
          projectInfoHook.handleChangeProjectDescription,
        onChangeProjectEmail: projectInfoHook.handleChangeProjectEmail,
        onChangeProjectAddressLine1:
          projectInfoHook.handleChangeProjectAddressLine1,
        onChangeProjectAddressLine2:
          projectInfoHook.handleChangeProjectAddressLine2,
        onChangeProjectCity: projectInfoHook.handleChangeProjectCity,
        onChangeProjectState: projectInfoHook.handleChangeProjectState,
        onChangeProjectCountry: projectInfoHook.handleChangeProjectCountry,
        onChangeProjectPostalCode:
          projectInfoHook.handleChangeProjectPostalCode,
        onChangeProjectBedrooms: projectInfoHook.handleChangeProjectBedrooms,
        onChangeProjectBathrooms: projectInfoHook.handleChangeProjectBathrooms,
        onChangeProjectStories: projectInfoHook.handleChangeProjectStories,
        onChangeProjectSize: projectInfoHook.handleChangeProjectSize,
        onChangeProjectWidth: projectInfoHook.handleChangeProjectWidth,
        onChangeProjectDepth: projectInfoHook.handleChangeProjectDepth,
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
}

// 4- Create custom hook (by consuming the context that is exposed above) to use throughout the pages/components
export const useProject = () => useContext(ProjectContext);
