import {
  FC,
  // ReactNode
} from "react";
import { Card, CardHeader, CardBody, Stack, Text } from "@chakra-ui/react";
import ActivityAttachments from "./ActivityAttachments";
import { Order, OrderDelivery } from "types/order.types";
import DeliveryActionButtons from "./DeliveryActionButtons";

interface Props {
  order: Order;
  delivery: OrderDelivery;
  showActionButtons?: boolean;
}
const DeliveryDetailsCard: FC<Props> = ({
  order,
  delivery,
  showActionButtons = false,
}) => {
  return (
    <Card variant="outline" size="sm" overflow="hidden">
      <CardHeader bg="gray.100" py={2}>
        <Text fontWeight="bold">{delivery.title}</Text>
      </CardHeader>
      <CardBody>
        <Stack spacing={6}>
          <Stack>
            <Text>{delivery.description}</Text>
          </Stack>
          <ActivityAttachments attachmentIds={delivery.attachments} />
          {/* Action buttons should only be shown on actions section but not logs/activities */}
          {showActionButtons && <DeliveryActionButtons order={order} />}
        </Stack>
      </CardBody>
    </Card>
  );
};

export default DeliveryDetailsCard;
