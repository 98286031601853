/////////////////////
// Supporting Document Step Section used in New Project Wizard
/////////////////////

// Basic Imports
import { FC } from "react";

// Design Imports
import { Stack, Text } from "@chakra-ui/react";

import { useProject } from "context/project/ProjectProvider";
import { deleteAttachmentByIdService } from "services/attachmentsService";
import useCustomToast from "hooks/useCustomToast";
import { ERRORS, SUCCESS } from "settings/constants/toastMessages";
import { render_supporting_document__db_project } from "schemas/project_wizard_schema";
import _ from "lodash";
import WizardAttachmentPreview from "components/ui/Upload/DigitalOceanSpace/WizardAttachmentPreview";

// Layout and Section Imports

// Data Imports

// Interfaces
interface MainProps {
  showDeleteButton?: boolean;
}

const UploadedFiles: FC<MainProps> = ({ showDeleteButton }) => {
  const { supportingDocuments, onAddSupportingDocuments } = useProject();
  const { errorToast, successToast } = useCustomToast();
  const filterDocuments = supportingDocuments.filter(
    (document: any) => !_.includes(document.tags, "staffasset")
  );

  const handleDelete = async (id: string) => {
    // 1. Create copy of original data
    const prevSupportingDocuments = supportingDocuments;

    // 2. Filter and create new copy of an array with filtered data
    const filteredSupportingDocuments = supportingDocuments.filter(
      (document: any) => document._id !== id
    );

    // 3. Update the UI first
    onAddSupportingDocuments(filteredSupportingDocuments);

    // 4. Update the backend
    const { error } = await deleteAttachmentByIdService(id);

    // 4.1. If backend is not updated successfully then show the error message and reverse the copy of original data that we created in step 1
    if (!!error) {
      onAddSupportingDocuments(prevSupportingDocuments);

      errorToast({ title: ERRORS.GENERIC });
      return;
    }

    // 4.2. If backend is updated successfully then show the success message.
    successToast({ title: SUCCESS.ATTACHMENT_DELETED });
  };

  return (
    // <PageContent>
    <Stack mt="6">
      <Text fontSize="24" fontWeight="bold" align="center" py="2">
        Uploaded files
      </Text>
      {filterDocuments.length > 0 ? (
        <Stack spacing="4">
          {filterDocuments.map((attachment: any, idx: number) => {
            const file = render_supporting_document__db_project(attachment);
            return (
              <WizardAttachmentPreview
                key={idx.toString()}
                file={{
                  name: file.name,
                  type: file.format,
                  url: file.url,
                  size: undefined,
                }}
                description={file.description}
                showDeleteButton={showDeleteButton}
                onDelete={() => handleDelete(file.id)}
              />
            );
          })}
        </Stack>
      ) : (
        <Text textAlign="center">No file uploaded...</Text>
      )}
    </Stack>
    // </PageContent>
  );
};

export default UploadedFiles;
