/////////////////////
// Verify Page
/////////////////////

// Basic Imports
import { useEffect } from "react";

// Design Imports
import { Center, Container, Progress, Stack } from "@chakra-ui/react";

// Layout and Section Imports
import AuthLayout from "components/layouts/AuthLayout";

import FormHeader from "components/forms/AuthForm/FormHeader";
import Alert from "components/features/Alert";
import useAuthForm from "components/forms/AuthForm/useAuthForm";
import { useAuth } from "context/AuthProvider";
import SpinnerLoading from "components/ui/Loaders/SpinnerLoading";

// Data Imports

// Interfaces

// Custom Hooks and Services

// Functions
const SendVerificationEmail = () => {
  const { isLoading: userLoading, user } = useAuth();
  const {
    sendVerificationEmailMutation: { error, mutate },
  } = useAuthForm();

  useEffect(() => {
    if (!!user) {
      mutate(user.email);
    }
  }, []);

  console.log({ error });

  if (!!userLoading) return <SpinnerLoading />;
  if (!!error)
    return (
      <AuthLayout>
        <Container>
          <Center>
            <Alert
              status={"error"}
              title={"Failed to send verification email"}
              description={error?.message}
              showCloseIcon={false}
            />
          </Center>
        </Container>
      </AuthLayout>
    );

  return (
    <AuthLayout>
      <Center>
        <Stack>
          <FormHeader
            showLogo={true}
            title="You will receive link in your registered email"
            description="Sending email..."
          />
          <Progress size="xs" isIndeterminate />
        </Stack>
      </Center>
    </AuthLayout>
  );
};

export default SendVerificationEmail;
