import { FC } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Stack,
  StackDivider,
  Text,
  useColorModeValue as mode,
} from "@chakra-ui/react";
import { User } from "types/user.types";
import { useForm } from "react-hook-form";
import TextInputField from "components/ui/common/TextInputField";
import { useMutation } from "@tanstack/react-query";
import {
  updateEmailService,
  updatePasswordService,
} from "services/userProfileService";
import { useAuth } from "context/AuthProvider";
import useCustomToast from "hooks/useCustomToast";

interface ChangeEmailFormProps {
  email: string;
  password: string;
}
interface ChangePasswordFormProps {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}
interface Props {
  user: User;
}

const changeEmailSchema = yup.object().shape({
  email: yup.string().required().label("Email"),
  password: yup.string().required().label("Password"),
});
const changePasswordSchema = yup.object().shape({
  currentPassword: yup.string().required().label("Current Password"),
  newPassword: yup.string().required().label("New Password"),
  confirmNewPassword: yup.string().required().label("Confirm New Password"),
});

const SecurityForm: FC<Props> = ({ user }) => {
  const { logout } = useAuth();
  const { errorToast } = useCustomToast();
  const colorMode = mode("sm", "sm-dark");
  const changeEmailForm = useForm<ChangeEmailFormProps>({
    defaultValues: { email: user.email },
    resolver: yupResolver(changeEmailSchema),
  });
  const changePasswordForm = useForm<ChangePasswordFormProps>({
    resolver: yupResolver(changePasswordSchema),
  });

  const updateEmailMutation = useMutation({
    mutationFn: (data: ChangeEmailFormProps) => {
      return updateEmailService({
        currEmail: user.email,
        newEmail: data.email,
        password: data.password,
      });
    },
    onSuccess: logout,
    onError: (error) =>
      errorToast({
        title: "Failed to change email",
        description: error.message,
      }),
  });

  const updatePasswordMutation = useMutation({
    mutationFn: (data: ChangePasswordFormProps) =>
      updatePasswordService({
        password: data.currentPassword,
        newPassword: data.confirmNewPassword,
      }),
    onSuccess: logout,
    onError: (error) =>
      errorToast({
        title: "Failed to change password",
        description: error.message,
      }),
  });

  return (
    <Box as="section">
      {/* Change Email Form */}
      <Box
        bg="bg-surface"
        boxShadow={colorMode}
        padding="10"
        borderRadius="lg"
        border="1px solid"
        borderColor="#e2e8f0"
        p={{ base: "4", md: "6" }}
      >
        <Stack
          spacing="5"
          divider={<StackDivider />}
          as="form"
          onSubmit={changeEmailForm.handleSubmit((data) => {
            updateEmailMutation.mutate(data);
          })}
        >
          <Stack
            justify="space-between"
            direction={{ base: "column", sm: "row" }}
            spacing="5"
          >
            <Stack spacing="1">
              <Text fontSize="lg" fontWeight="medium">
                Change e-mail address?
              </Text>
            </Stack>
          </Stack>

          <Stack justify="space-between" direction="row" spacing="4">
            <TextInputField
              label="Email"
              errorMessage={changeEmailForm.formState.errors.email?.message}
              {...changeEmailForm.register("email")}
            />
            <TextInputField
              label="Password"
              type="password"
              placeholder="Please enter your current password"
              errorMessage={changeEmailForm.formState.errors.password?.message}
              {...changeEmailForm.register("password")}
            />
          </Stack>

          <Stack
            justify="space-between"
            direction={{ base: "column", sm: "row" }}
            spacing="5"
          >
            <Stack spacing="1">
              <Button
                type="submit"
                variant="primary"
                bg="blue.500"
                color="white"
                isLoading={updateEmailMutation.isPending}
              >
                Update
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Box>

      {/* Change Password Form */}
      <Box
        bg="bg-surface"
        boxShadow={mode("sm", "sm-dark")}
        padding="10"
        borderRadius="lg"
        border="1px solid"
        borderColor="#e2e8f0"
        p={{ base: "4", md: "6" }}
        mt={6}
      >
        <Stack
          spacing="5"
          as="form"
          onSubmit={changePasswordForm.handleSubmit((data) => {
            if (data.newPassword !== data.confirmNewPassword) {
              errorToast({
                title: "Confirm password should match new password.",
              });
              return undefined;
            }

            updatePasswordMutation.mutate(data);
          })}
          divider={<StackDivider />}
        >
          <Stack
            justify="space-between"
            direction={{ base: "column", sm: "row" }}
            spacing="5"
          >
            <Stack spacing="1">
              <Text fontSize="lg" fontWeight="medium">
                Change password?
              </Text>
            </Stack>
          </Stack>

          <Stack spacing="4">
            <TextInputField
              type="password"
              label="Current Password"
              placeholder="Please enter your current password"
              {...changePasswordForm.register("currentPassword")}
            />
            <TextInputField
              type="password"
              label="New Password"
              placeholder="Please enter your new password"
              {...changePasswordForm.register("newPassword")}
            />
            <TextInputField
              type="password"
              label="Confirm New Password"
              placeholder="Please enter your new password again for confirmation"
              {...changePasswordForm.register("confirmNewPassword")}
            />
          </Stack>

          <Stack
            justify="space-between"
            direction={{ base: "column", sm: "row" }}
            spacing="5"
          >
            <Stack spacing="1">
              <Button
                variant="primary"
                bg="blue.500"
                color="white"
                type="submit"
                isLoading={updatePasswordMutation.isPending}
              >
                Update
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
};

export default SecurityForm;
