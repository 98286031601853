import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import useCustomToast from "./useCustomToast";
import useSound from "use-sound";
import { useEffect, useRef } from "react";
import { NotificationHR } from "types/notification.type";
import {
  deleteNotifications,
  fetchAllNotifications,
  markNotificationsAsRead,
} from "services/notificationService";

const useNotifications = () => {
  const { errorToast } = useCustomToast();
  const queryClient = useQueryClient();
  const [play] = useSound("/sound.wav");
  const previousNotificationsCount = useRef<number | null>(null);

  const notificationQuery = useQuery<NotificationHR[]>({
    queryKey: ["notifications"],
    queryFn: fetchAllNotifications,
    refetchInterval: 20000, // refetch every 20 seconds
    refetchIntervalInBackground: true,
  });

  const readMutation = useMutation({
    mutationFn: (ids: string[]) => markNotificationsAsRead(ids),
    onSuccess: (_, ids) => {
      queryClient.setQueryData(
        ["notifications"],
        (oldNotifications: NotificationHR[]) => {
          return oldNotifications.map((notification) => ({
            ...notification,
            isRead: ids.includes(notification.id) ? true : notification.isRead,
          }));
        }
      );
    },
    onError: (error: Error) => {
      errorToast({
        title: "Failed to mark notifications as read",
        description: error.message,
      });
    },
  });

  const deleteMutation = useMutation({
    mutationFn: (ids: string[]) => deleteNotifications(ids),
    onSuccess: (_, ids) => {
      queryClient.setQueryData(
        ["notifications"],
        (oldNotifications: NotificationHR[]) => {
          return oldNotifications.filter(
            (notification) => !ids.includes(notification.id)
          );
        }
      );
    },
    onError: (error: Error) => {
      errorToast({
        title: "Failed to delete notifications",
        description: error.message,
      });
    },
  });

  useEffect(() => {
    if (notificationQuery.data) {
      const newNotificationsCount = notificationQuery.data.length;
      const prevNotification = previousNotificationsCount.current || 0;

      if (newNotificationsCount > prevNotification) {
        const newNotifications = notificationQuery.data.slice(prevNotification);
        const hasUnreadNotification = newNotifications.some(
          (notification: NotificationHR) => !notification.isRead
        );

        if (hasUnreadNotification) {
          // Play the notification sound
          play();
        }
      }
    }

    // Update the previous notifications count
    previousNotificationsCount.current = notificationQuery.data?.length || null;
  }, [notificationQuery.data, play]);

  return {
    notifications: notificationQuery.data,
    isLoading: notificationQuery.isLoading,
    error: notificationQuery.error,
    markNotificationsAsRead: readMutation.mutate,
    deleteNotifications: deleteMutation.mutate,
  };
};

export default useNotifications;
