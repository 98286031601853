/////////////////////
// Nav Menu (appMenu) UI
/////////////////////

// Basic Imports
import { useLocation } from "react-router-dom";

// Design Imports
import { Flex, HStack, Stack } from "@chakra-ui/react";

// import { GoRocket } from "react-icons/go";
// import { GrProjects } from "react-icons/gr";
import { FaPeopleRoof, FaWandMagicSparkles } from "react-icons/fa6";

import { NavItem } from "./NavItem";
import { ROUTES } from "settings/constants/routes";
import { useLocalUser } from "hooks/useLocalUser";
import { FiPackage } from "react-icons/fi";

// Layout and Section Imports

// Data Imports

// Interfaces
interface NavItemProps {
  label: string;
  href: string;
  icon: React.ReactNode;
}

interface MobileNavMenuProps {
  isOpen?: boolean;
  onClickNavItem?: (navItem: NavItemProps) => void;
}

// Functions

const NAV_ITEMS: NavItemProps[] = [
  //{ label: "Dashboard", href: ROUTES.DASHBOARD, icon: <GoDashboard /> },
  // {
  //   label: "Projects",
  //   href: ROUTES.PROJECTS_ACTIVE,
  //   icon: <GrProjects />,
  // },
  // { label: "New Project", href: ROUTES.PROJECTS_NEW, icon: <GoRocket /> },
  { label: "Orders", href: ROUTES.ORDERS, icon: <FiPackage /> },
  { label: "Offers", href: ROUTES.OFFERS, icon: <FaWandMagicSparkles /> },
  {
    label: "Talent Public Profiles",
    href: ROUTES.PROFILES,
    icon: <FaPeopleRoof />,
  },
];

const MobileNavMenu = (props: MobileNavMenuProps) => {
  const { isOpen, onClickNavItem } = props;
  const { pathname } = useLocation();
  const [userType] = useLocalUser("userType", "buyer"); // Default user type

  return (
    <Flex
      hidden={!isOpen}
      as="nav"
      direction="column"
      bg="white"
      position="fixed"
      height="calc(100vh - 4rem)"
      top="16"
      insetX="0"
      zIndex={10}
      w="full"
    >
      <Stack p="4">
        {NAV_ITEMS.map((item: NavItemProps, idx: number) => {
          // Only show 'Projects' and 'New Project' if userType is "subscriber"
          if (
            item.href === ROUTES.PROJECTS_ACTIVE ||
            item.href === ROUTES.PROJECTS_NEW
          ) {
            if (userType !== "subscriber") {
              return null; // Do not render for buyers
            }
          }

          const isActive = item.href === pathname;
          return (
            <NavItem.Mobile
              key={idx.toString()}
              active={isActive}
              icon={item.icon}
              label={item.label}
              href={item.href}
              onClickNavItem={onClickNavItem as any}
            />
          );
        })}
      </Stack>
    </Flex>
  );
};

const DesktopNavMenu = () => {
  const { pathname } = useLocation();
  const [userType] = useLocalUser("userType", "buyer"); // Default user type
  return (
    <HStack spacing="3" flex="1" display={{ base: "none", lg: "flex" }}>
      {NAV_ITEMS.map((item: NavItemProps, idx: number) => {
        if (
          item.href === ROUTES.PROJECTS_ACTIVE ||
          item.href === ROUTES.PROJECTS_NEW
        ) {
          if (userType !== "subscriber") {
            return null; // Do not render for buyers
          }
        }
        const isActive = pathname.endsWith(item.href);
        return (
          <NavItem.Desktop
            key={idx.toString()}
            active={isActive}
            icon={item.icon}
            label={item.label}
            href={item.href}
          />
        );
      })}
    </HStack>
  );
};

export const NavMenu = {
  Mobile: MobileNavMenu,
  Desktop: DesktopNavMenu,
};
