export function getFullName(firstName?: string, lastName?: string): string {
  const defaultName = "Unknown";

  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  } else if (firstName) {
    return firstName;
  } else if (lastName) {
    return lastName;
  } else {
    return defaultName;
  }
}

export const getAssetLabel = (assets: number | undefined): string => {
  if (!assets) return "0 assets per week";
  if (assets > 10) return "10+ assets per week";

  return `${assets} assets per week`;
};
