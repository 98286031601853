/////////////////////
// Desired Assets Boxes used in Cards
/////////////////////

// Basic Imports

// Design Imports
import { Icon, SimpleGrid, useCheckboxGroup } from "@chakra-ui/react";

import { ButtonlessChecklessCheckbox } from "components/ui/common/Checkbox";

// Layout and Section Imports

// Data Imports
import { AssetProps } from "data/projectWizardData";
import { FC } from "react";

// Custom Hooks and Services

// Interfaces
interface Props {
  assets: any[];
  readOnly?: boolean;
}

// Functions
const DesireAssetsBoxes: FC<Props> = ({ assets, readOnly }) => {
  const { getCheckboxProps } = useCheckboxGroup({ value: ["2DFloorPlan"] });

  return (
    <SimpleGrid maxW="7xl" mt={4} columns={{ base: 1, md: 2 }} spacing={4}>
      {assets.map((asset: AssetProps, idx: number) => (
        <ButtonlessChecklessCheckbox
          isDisabled={readOnly}
          key={idx.toString()}
          icon={<Icon as={asset.icon} />}
          title={asset.title}
          description={asset.description}
          {...getCheckboxProps({ value: asset.value })}
        />
      ))}
    </SimpleGrid>
  );
};

export default DesireAssetsBoxes;
