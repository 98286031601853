import { FC } from "react";
import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";
import Lottie from "lottie-react";
import successAnimation from "animation/success.json";
import { Logo } from "components/ui/logo/Logo2";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  description: string
}
const SuccessModal: FC<Props> = ({ isOpen, onClose, description }) => {
  return (
    <Box>
      <Modal
        isOpen={isOpen}
        onClose={() => void 0}
        size="2xl"
        // `trapFocus` and `blockScrollOnMount` are only switched off so that the preview works properly.
        blockScrollOnMount={true}
        trapFocus={false}
      >
        <ModalOverlay />
        <ModalContent borderRadius="2xl" mx="4">
          <ModalCloseButton onClick={onClose} />
          <ModalBody>
            <Stack
              maxW="xs"
              mx="auto"
              py={{ base: "12", md: "16" }}
              spacing={{ base: "6", md: "10" }}
            >
              <Logo height="8" />
              <Stack spacing="3" textAlign="center">
                <Lottie
                  animationData={successAnimation}
                  autoplay={true}
                  loop={true}
                  style={{ height: "120px" }}
                />

                <Stack justify="center" direction="row">
                  <Stack spacing="0.5" fontSize="md">
                    <Text
                      color="blue.500"
                      fontWeight="extrabold"
                      fontSize={{ base: "2xl", md: "3xl" }}
                      textTransform="uppercase"
                      transform="scale(1.2)"
                    ></Text>
                    <Text color="muted">
                      {description}
                    </Text>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default SuccessModal;
