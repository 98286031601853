import { FC } from "react";
import { Stack } from "@chakra-ui/react";

import { MdSecurity } from "react-icons/md";
import { FaWalkieTalkie } from "react-icons/fa6";
import { NavButton } from "components/ui/appMenu/NavButton";
import { ROUTES } from "settings/constants/routes";
import UserIcon from "icons/UserIcon";

const ITEMS = [
  { label: "Profile", href: ROUTES.ACCOUNT, icon: <UserIcon /> },
  // { label: "Billing", href: ROUTES.ACCOUNT_BILLING, icon: <FaMoneyBill /> },
  { label: "Security", href: ROUTES.ACCOUNT_SECURITY, icon: <MdSecurity /> },
  {
    label: "Communications",
    href: ROUTES.ACCOUNT_COMMUNICATIONS,
    icon: <FaWalkieTalkie />,
  },
];

interface Props {
  isFullWidth?: boolean;
}
const SidebarContent: FC<Props> = ({ isFullWidth = false }) => {
  return (
    <Stack spacing="4" w="full">
      {ITEMS.map((item) => (
        <NavButton
          minW={isFullWidth ? "full" : "10rem"}
          href={item.href}
          leftIcon={item.icon}
          key={item.href}
        >
          {item.label}
        </NavButton>
      ))}
    </Stack>
  );
};

export default SidebarContent;
