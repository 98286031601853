/////////////////////
// Summary Step
/////////////////////

// Basic Imports
import { FC } from "react";
import _ from "lodash";

// Design Imports

// Layout and Section Imports
import ProjectInfoCard from "../../Cards/AProjectInfo/ProjectInfoCard";
import DesiredAssetsCard from "../../Cards/BDesiredAssets/DesiredAssetsCard";
import OutputCard from "../../Cards/COutput/OutputCard";

// Data Imports
import { assets } from "data/projectWizardData";

// Custom Hooks and Services
import { useProject } from "context/project/ProjectProvider";
import SupportingDocumentsStep from "../ESupportingDocuments/SupportingDocumentsStep";
import StyleCardsReadOnly from "../../Cards/StyleCardsReadOnly";

// Interfaces
interface Props {
  userId: string;
  prevStep?: () => any;
  nextStep?: () => any;
  onSubmit?: () => void;
  stepLoading?: boolean;
  onStepClick?: () => void;
}

// Functions

const SummaryStep: FC<Props> = ({ userId, nextStep, onSubmit }) => {
  const { projectWizard } = useProject();

  return (
    <>
      <ProjectInfoCard
        styles={{ my: 5 }}
        data={projectWizard.projectInfo}
        projectId={projectWizard.id}
        readOnly={true}
      />
      <DesiredAssetsCard
        styles={{ my: 5 }}
        assets={_.intersectionBy(assets, projectWizard.selectedStyles, "title")}
        projectId={projectWizard.id}
      />
      <OutputCard
        styles={{
          my: 5,
        }}
        data={projectWizard.selectedOutputs}
        projectId={projectWizard.id}
        readOnly={true}
      />
      {projectWizard.selectedStyles.length > 0 && <StyleCardsReadOnly />}
      {projectWizard.supportingDocuments.length > 0 && (
        <SupportingDocumentsStep
          userId={userId}
          showHeader
          showUploadingForm={false}
          showDeleteButton={false}
        />
      )}
    </>
  );
};

export default SummaryStep;
