/////////////////////
// Site Credentials Settings
/////////////////////

// Handlers
const isFeaturesLive = (isLive: true | false) => isLive;

// Credentials
export const API_URL = process.env.REACT_APP_API_URL;
export const SOCKET_API_URL = (URL: string) => URL.replace("v1", ""); // This logic is just to remove "v1" from url

export const LIVE_FEATURES = isFeaturesLive(
  "TRUE" === process.env.REACT_APP_STRIPE_PRICING_FEATURE ? true : false
);

//Cloudflare and Security
export const CLOUDFLARE_SITEKEY = process.env.REACT_APP_CLOUDFLARE_SITE_KEY;
export const UNDER_ATTACK =
  process.env.REACT_APP_ISHACKED === "true" ? true : false;
