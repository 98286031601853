import { useProject } from "context/project/ProjectProvider";
import useDebounce from "hooks/useDebounce";
import { useEffect, useState } from "react";

const useDesiredAssetsStep = () => {
  let [assetDescription, setAssetDescription] = useState("");
  const [selectedAssets, setSelectedAssets] = useState([]);
  const [defaultValues, setDefaultValues] = useState([]);
  const { debouncedValue: debouncedSelectedAssets, isLoading } = useDebounce(
    selectedAssets,
    700
  );
  const {
    projectWizard,
    onAddSelectedAssets,
    onAddSelectedOutputs,
    selectedStyles,
    onAddSelectedStyles,
  } = useProject();

  useEffect(() => {
    const defaultValues = projectWizard?.selectedAssets.map(
      (asset: any) => asset.value
    );

    setDefaultValues(defaultValues.length > 0 ? defaultValues : []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // BETA: It should be working as empty array otherwise uncomment below line and remove this line
  // }, [projectWizard.selectedAssets]);

  // It updated context api when selected assets gets changes
  useEffect(() => {
    handleUpdateProjectContextAPI();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSelectedAssets]);

  // This is being used in above useEffect
  const handleUpdateProjectContextAPI = () => {
    if (debouncedSelectedAssets.length === 0) {
      onAddSelectedAssets([]);
      onAddSelectedOutputs([]);
      onAddSelectedStyles([]);
      return;
    }

    // Setting assets in project wizard.
    onAddSelectedAssets(debouncedSelectedAssets);

    // Setting outputs in project wizard.
    const initials = debouncedSelectedAssets.map((asset: any) => {
      const filteredOutput = projectWizard.selectedOutputs.find(
        (item: any) => item.title === asset.title
      );

      if (!!filteredOutput) return filteredOutput;

      return {
        id: asset.id,
        title: asset.title,
        initialData: asset.actionData.initialData?.[0],
        outputFileType: [asset.actionData.outputFileType[0]],
        imageSize: asset.actionData.imageSize[1], // Select 'Medium'
        sourceFile: asset.actionData.sourceFile[0],
        cameraCaptureLocations: [],
        quantity: 1,
      };
    });
    onAddSelectedOutputs(initials);

    // Setting styles in project wizard
    const stylesData = debouncedSelectedAssets.map((asset: any) => ({
      id: asset.id,
      title: asset.title,
      data: asset.stylesData,
    }));
    const selected = stylesData.map((asset: any) => {
      const existingSelectedStyle = selectedStyles.find(
        (style: any) => style.title === asset.title
      );
      if (!existingSelectedStyle) {
        const selected = asset.data.map((data: any) => ({
          id: data.id,
          title: data.title,
          type: data.type,
          selectedItems: [data.items[0]],
        }));
        return { id: asset.id, title: asset.title, types: selected };
      }

      return { ...existingSelectedStyle };
    });
    onAddSelectedStyles(selected);
  };

  return {
    assetDescription,
    setAssetDescription,
    selectedAssets: debouncedSelectedAssets,
    setSelectedAssets,
    defaultValues,
    isLoading,
  };
};

export default useDesiredAssetsStep;
