import { useQuery } from "@tanstack/react-query";
import { getUserSubscriptions } from "services/stripeService";

const useUserSubscriptions = () => {
  return useQuery({
    queryKey: ["user", "subscriptions"],
    queryFn: getUserSubscriptions,
  });
};

export default useUserSubscriptions;
