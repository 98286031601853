/////////////////////
// PricingCard Component
/////////////////////

// Basic Imports

// Design Imports
import {
  Box,
  BoxProps,
  Flex,
  Text,
  useColorModeValue as mode,
  Button,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import _ from "lodash";
import { FC } from "react";
import { createCheckoutSession } from "services/stripeService";

// Layout and Section Imports

// Data Imports

// Services Imports
import { LIVE_FEATURES } from "settings/site-credentials";
import { formatCurrency } from "utils/currency";

// Interfaces
interface PricingCardProps {
  name: string;
  description: string;
  price: number;
  amountSaved?: number;
  duration: string;
  benefits: string[];
  save: number;
  currency: string;
  styles: BoxProps;
  isActive?: boolean;
  priceId: string;
}

// Functions

export const PricingCard: FC<PricingCardProps> = ({
  name,
  description,
  price,
  currency,
  styles,
  isActive,
  priceId,
}) => {
  const checkoutSessionMutation = useMutation({
    mutationFn: (data: { priceId: string }) => createCheckoutSession(data),
    onSuccess: (data) => {
      window.location.href = data;
    },
    onError: (error) => console.log({ error }),
  });

  return (
    <Flex
      direction="column"
      px={{ base: 2, md: 6 }}
      pt={{ base: 4, md: 6 }}
      pb={4}
      rounded="lg"
      pos="relative"
      maxW="xl"
      mx="auto"
      minH={{ lg: "250px", base: "200px" }}
      border="1px"
      w="full"
      {...styles}
    >
      {isActive && <PopularBadge>Current Plan</PopularBadge>}
      <Box
        flex="1"
        className="kk"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        {/* {isSaving && (
          <SaveBadge>
            <Badge colorScheme="green">Save {save}%</Badge>
          </SaveBadge>
        )} */}
        <Text align="center" fontSize="2xl" fontWeight="bold">
          {_.startCase(name)}
        </Text>
        {!LIVE_FEATURES && (
          <Text
            align="center"
            mt="2"
            color={mode("gray.600", "gray.400")}
            maxW="16rem"
            mx="auto"
          >
            {description}
          </Text>
        )}
        <Flex align="center" mt="4" mb="3" justify="center">
          <Text
            fontSize={{ base: "2xl", xl: "3xl" }}
            fontWeight="extrabold"
            lineHeight="1"
            color="gray.600"
            flexShrink={0}
            textAlign="center"
          >
            {formatCurrency(price, currency)}
          </Text>
        </Flex>

        <Button
          isLoading={checkoutSessionMutation.isPending}
          isDisabled={!!isActive}
          onClick={() =>
            checkoutSessionMutation.mutate({
              priceId,
            })
          }
        >
          {!!isActive ? "Subscribed" : "Subscribe"}
        </Button>
      </Box>
    </Flex>
  );
};

const PopularBadge = (props: BoxProps) => (
  <Box
    whiteSpace="nowrap"
    top="-2"
    left="52%"
    transform="translateX(-50%)"
    pos="absolute"
    rounded="lg"
    fontWeight="bold"
    fontSize="sm"
    px="4"
    py={{ base: 0, md: 1 }}
    textTransform="uppercase"
    bg="blue.600"
    color="white"
    {...props}
  />
);
// const SaveBadge = (props: BoxProps) => (
//   <Box
//     whiteSpace="nowrap"
//     top="0px"
//     left="15%"
//     transform="translateX(-50%)"
//     pos="absolute"
//     rounded="lg"
//     fontWeight="bold"
//     fontSize="sm"
//     px="4"
//     py="1"
//     textTransform="uppercase"
//     color="white"
//     {...props}
//   />
// );

// const BillingBadge = (props: BoxProps) => (
//   <Box
//     rounded="full"
//     fontSize="sm"
//     bg="white"
//     color="gray.900"
//     px="3"
//     py="1"
//     pos="absolute"
//     top="4"
//     right="4"
//     fontWeight="bold"
//     {...props}
//   />
// );
