// interface IRoutes {
//   [propName: string]: string;
// }

import { OrderStatus, OrderStatusValue } from "types/order.types";

export const isProduction = process.env.NODE_ENV === "production";

export const ROUTES = {
  // Dashboard Routes
  DASHBOARD: "/dashboard",
  ANALYTICS: "/analytics",
  AUTOMATIONS: "/automations",
  // Project Routes
  PROJECTS: "/projects/all",
  PROJECTS_COMPLETED: "/projects/completed",
  PROJECTS_DRAFT: "/projects/draft",
  PROJECTS_PAUSED: "/projects/paused",
  PROJECTS_ACTIVE: "/projects",
  PROJECTS_DELETED: "/projects/deleted",
  PROJECTS_NEW: "/projects/new-project",
  // Single Project Route
  PROJECT: "projects/:id",
  PROJECTS_WIZARD_ID: "/projects/new-project/:id",
  // Account Routes
  ACCOUNT: "/account",
  ACCOUNT_BILLING: "/account/billing",
  ACCOUNT_SECURITY: "/account/security",
  ACCOUNT_COMMUNICATIONS: "/account/communications",
  // Plan
  SELECT_PLAN: "/select-plan",
  HELP: "/help",
  OFFERS: "/offers",
  OFFER: "/offers/:offerId",

  PROFILES: "/profiles",
  PROFILE: "/profiles/:profileId",
};

export const ORDER_STATUS: Record<OrderStatus, OrderStatusValue> = {
  pending: "Pending",
  in_progress: "In progress",
  completed: "Completed",
  cancelled: "Cancelled",
  delivered: "Delivered",
  revision_requested: "Revision Requested",
  in_revision: "In Revision",
};
