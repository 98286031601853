import { FC } from "react";
import { HStack, Text } from "@chakra-ui/react";
import { format } from "date-fns";
import { OrderActivity } from "types/order.types";
import ActivityIcons from "./ActivityIcons";

interface Props {
  activity: OrderActivity;
}
const RenderActivity: FC<Props> = ({ activity }) => {
  return (
    <HStack>
      <ActivityIcons action={activity.action} />
      <Text fontWeight="medium">
        {activity.message}
        <Text
          as="span"
          ml={2}
          color="GrayText"
          fontStyle="italic"
          fontWeight="normal"
          fontSize="sm"
        >
          {activity?.timeStamp &&
            format(new Date(activity.timeStamp), "MMM dd, yyyy hh:mm a")}
        </Text>
      </Text>
    </HStack>
  );
};

export default RenderActivity;
