import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { createComment, fetchAllCommentsByProjectId } from "services/comments";
import useCustomToast from "./useCustomToast";
import { projectCommentsQueryKeys } from "settings/constants/queryKeys";
import { Comment, CommentWithChildren } from "types/comment.types";

const useFetchComments = (projectId: string) => {
  const {
    data: comments,
    isLoading: fetchCommentsLoading,
    ...restQuery
  } = useQuery({
    queryKey: projectCommentsQueryKeys(projectId),
    queryFn: () => fetchAllCommentsByProjectId(projectId),
    enabled: !!projectId,
    refetchInterval: 10 * 1000,
    select: (data) => {
      const commentsWithParentAndChild = generateParentWithChild(data);
      return commentsWithParentAndChild;
    },
  });
  const { errorToast } = useCustomToast();
  const queryClient = useQueryClient();

  function generateParentWithChild(arr: Comment[]): CommentWithChildren[] {
    const parents = arr.filter((val: any) => !val.parentId);
    const parentsWithChild = parents.map((parent: any) => {
      return {
        ...parent,
        //p.parentId and parent.id if both were undefined they will also get in children added check
        children: arr.filter(
          (p: any) => p.parentId && parent.id && p.parentId === parent.id
        ),
      };
    });
    return parentsWithChild;
  }

  const uploadCommentMutation = useMutation({
    mutationFn: ({ commentText, commentHtml, attachments }: any) =>
      createComment({
        text: commentText,
        html: commentHtml,
        projectId: projectId,
        attachments,
      }),
    onSuccess: (data) => {
      // successToast({ title: "Comment uploaded successfully." });
      queryClient.setQueryData(
        projectCommentsQueryKeys(projectId),
        (prevComments: Comment[]) => [...prevComments, data]
      );
    },
    onError: () => {
      errorToast({
        title: "Failed to upload comment.",
        description: "Please try again or contact support",
      });
    },
  });

  return {
    comments,
    uploadCommentMutation,
    fetchCommentsLoading,
    ...restQuery,
  };
};

export default useFetchComments;
