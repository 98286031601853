/////////////////////
// Supporting Document Step Section used in New Project Wizard
/////////////////////

// Basic Imports
import { FC, useState } from "react";

// Design Imports
import { Button, FormControl, FormLabel, Textarea } from "@chakra-ui/react";
import { chakra } from "@chakra-ui/system";
import PageContent from "components/ui/appPages/PageContent";
import { useProject } from "context/project/ProjectProvider";
import useCustomToast from "hooks/useCustomToast";
import { ERRORS } from "settings/constants/toastMessages";
import { Dropzone } from "hr-design-system";
import WizardAttachmentPreview from "components/ui/Upload/DigitalOceanSpace/WizardAttachmentPreview";
import getFileFormat from "utils/getFileFormat";
import constructPath from "utils/constructPath";
import { createAttachment } from "services/attachmentsService";

interface Props {
  userId: string;
}

const UploadingForm: FC<Props> = ({ userId }) => {
  const [activeImage, setActiveImage] = useState<File | null>(null);
  const [activeDescription, setActiveDescription] = useState("");
  const {
    projectWizard,
    supportingDocuments,
    onAddSupportingDocuments,
    onSaveProjectWizardAsDraft,
  } = useProject();
  const { errorToast, warningToast } = useCustomToast();
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = async () => {
    setLoading(true);

    let projectId = "";
    if (!projectWizard.id) {
      const { data }: any = await onSaveProjectWizardAsDraft();

      if (!data.success) {
        warningToast({
          title: "Project is not initialized yet!!",
          description: "You cannot upload attachments until you save project.",
        });
        return;
      }

      const id: string = await data?.data?.project?._id;
      if (!id) {
        warningToast({ title: "Schema invalid!" });
      }
      projectId = id || "";
    }

    if (!activeImage) {
      errorToast({
        title: "Image not found!",
        description: "Try hard reload or contact support",
      });
      return;
    }

    try {
      const attachment = await createAttachment({
        mediaResponse: activeImage,
        mediaDescription: activeDescription,
        projectId: projectWizard.id || projectId,
        folderName: constructPath(
          process.env.REACT_APP_CLOUDINARY_FOLDER,
          userId,
          projectId,
          "0 Client Upload"
        ),
      });

      // Updating state with new document
      let newDocuments: any[] = supportingDocuments;
      newDocuments.unshift(attachment);

      onAddSupportingDocuments(newDocuments);

      // Reset active filters
      setActiveImage(null);
      setActiveDescription("");
    } catch (error) {
      errorToast({ title: ERRORS.GENERIC });
      setLoading(false);
      return;
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <PageContent minW="10rem">
        <chakra.form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          {!activeImage ? (
            <Dropzone
              acceptOnlyImages={false}
              showPreviews={false}
              allowMultiple={false}
              onUpload={(files) => {
                if (!files) {
                  errorToast({
                    title: "Files not found",
                    description: "Please try hard reload or contact support",
                  });
                  return;
                }

                if (Array.isArray(files)) {
                  const loadedFile: File = files[0];
                  setActiveImage(loadedFile);
                } else if (files) {
                  setActiveImage(files);
                }
              }}
            />
          ) : (
            <WizardAttachmentPreview
              file={{
                name: activeImage.name,
                size: activeImage.size,
                type: getFileFormat({ fileType: activeImage.type }),
                url: URL.createObjectURL(activeImage),
              }}
              onDelete={async () => {
                setActiveImage(null);
                setActiveDescription("");
              }}
            />
          )}
          <FormControl mt="5" id="AssetDescription">
            <FormLabel>File Description</FormLabel>
            <Textarea
              value={activeDescription}
              onChange={(e) => setActiveDescription(e.currentTarget.value)}
              placeholder="Details about file you just uploaded"
              onKeyPress={(e) => {
                if (!e.shiftKey && e.key === "Enter") {
                  handleSubmit();
                }
              }}
            />
            <Button
              isLoading={loading}
              isDisabled={!activeImage}
              mt={5}
              colorScheme="blue"
              type="submit"
            >
              Upload
            </Button>
          </FormControl>
        </chakra.form>
      </PageContent>
    </>
  );
};

export default UploadingForm;
