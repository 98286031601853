/////////////////////
// Chat Section
/////////////////////

// Basic Imports
import { FC, useCallback, useRef, useState } from "react";

// Design Imports
import { Box, BoxProps, Stack, HStack } from "@chakra-ui/react";

// Layout and Section Imports
import { Card } from "components/ui/common/Card";
import { CardContent } from "components/ui/common/CardContent";
import { CardHeader } from "components/ui/common/CardHeader";

// Data Imports
import CommentsSection from "./CommentsSection";
import UploadSection from "./UploadSection";
import ChatGalleryWithSwiper from "./ChatGalleryWithSwiper";
import { useProject } from "context/project/ProjectProvider";
import { renderAssetTypeAsDB } from "schemas/assets_schema";
import Alert from "components/features/Alert";
import { useAuth } from "context/AuthProvider";
import SpinnerLoading from "components/ui/Loaders/SpinnerLoading";
import useFetchComments from "hooks/useFetchComments";

// Interfaces
interface ChatProps extends BoxProps {
  projectId: string;
}

const Chat: FC<ChatProps> = ({ projectId, ...restProps }) => {
  const { user, isLoading: userLoading } = useAuth();
  const endMessageDiv = useRef<HTMLDivElement>();
  const { uploadCommentMutation, fetchCommentsLoading, comments, error } =
    useFetchComments(projectId);
  const { projectWizard } = useProject();
  const [fileToEdit, setFileToEdit] = useState<{
    format: string;
    name: string;
    secure_url: string;
  } | null>(null);

  console.log({ comments });

  const handleScrollToBottom = () => {
    if (!endMessageDiv.current) return;
    endMessageDiv.current.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "nearest",
    });
  };

  const getDefaultSelectedAssets = useCallback(() => {
    const formattedTargetAssets = projectWizard.selectedOutputs.map(
      (assetOutput: any) => {
        const targetAsset = projectWizard.selectedAssets.find(
          (asset: any) => asset.title === assetOutput.title
        );
        const targetAssetValue = targetAsset?.value;
        const assetType =
          renderAssetTypeAsDB[
            targetAssetValue as keyof typeof renderAssetTypeAsDB
          ];

        return {
          id: assetOutput.id,
          alt: assetOutput.title || "-",
          quantity: assetOutput.quantity,
          icon: targetAsset?.icon,
          assetType: assetType,
          name: "",
          imgUrl: "",
          format: "",
          thumbnailUrl: "",
        };
      }
    );

    const spreadUponQuantity: any[] = [];
    formattedTargetAssets.forEach((asset: any) => {
      for (let i = 0; i < asset.quantity; i++) {
        spreadUponQuantity.push({
          ...asset,
          id: `${asset.id}-${i + 1}`,
          quantity: i + 1,
        });
      }
    });

    return spreadUponQuantity;
  }, [projectWizard]);

  console.log({ error });

  if (!!userLoading) return <SpinnerLoading />;
  if (!user)
    return (
      <Alert
        status="error"
        title="Failed to authenticate"
        description="Please hard reload or try authenticating again"
      />
    );
  return (
    <Stack>
      <Card {...restProps}>
        <Stack
          direction={{ base: "column", md: "row" }}
          spacing={{ base: "4", md: "10" }}
        >
          <CardContent>
            <HStack as="section" mb={5}>
              <CardHeader title="Assets" action="" />
            </HStack>

            <Box as="div" id="top">
              {" "}
            </Box>

            <ChatGalleryWithSwiper
              defaultSelectedAssets={getDefaultSelectedAssets()}
              onEdit={(file: any) => {
                setFileToEdit({
                  secure_url: file.imgUrl,
                  name: file.name,
                  format: file.format,
                });
                handleScrollToBottom();
              }}
            />
            <CommentsSection
              userId={user.id}
              isLoading={fetchCommentsLoading}
              comments={comments}
              onEdit={(file) =>
                setFileToEdit({
                  secure_url: file.secure_url,
                  name: file.name,
                  format: file.format,
                })
              }
            />

            <Box as="div" id="bottom" ref={endMessageDiv as any}>
              {" "}
            </Box>

            <UploadSection
              userId=""
              uploadLoading={uploadCommentMutation.isPending}
              fileToEdit={fileToEdit}
              onCloseEditing={() => setFileToEdit(null)}
              onUploadComment={(comment) => {
                uploadCommentMutation.mutate(comment);
              }}
            />
          </CardContent>
        </Stack>
      </Card>
    </Stack>
  );
};

export default Chat;
