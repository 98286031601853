import { useQuery } from "@tanstack/react-query";
import { getOfferReviews } from "services/offerService";
import { offerReviewsKeys } from "settings/constants/queryKeys";
import { Review } from "types/review.types";

const useOfferReviews = (offerId: string) => {
  return useQuery<Review>({
    queryKey: offerReviewsKeys(offerId),
    queryFn: () => getOfferReviews(offerId),
    enabled: !!offerId,
  });
};

export default useOfferReviews;
