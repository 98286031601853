/////////////////////
// ProfileDropdown (appMenu) UI
/////////////////////

// Basic Imports

// Design Imports
import {
  Avatar,
  Box,
  Flex,
  HStack,
  Menu,
  MenuItem,
  MenuList,
  Text,
  useMenuButton,
  UseMenuButtonProps,
  useColorModeValue as mode,
} from "@chakra-ui/react";

import { Link } from "react-router-dom";

import SkeletonLoading from "../Loaders/SkeletonLoading";

// Layout and Section Imports

// Data Imports
import { ROUTES } from "../../../constants";
import { useAuth } from "context/AuthProvider";

// Interfaces

// Functions

const ProfileMenuButton = (props: UseMenuButtonProps) => {
  const buttonProps = useMenuButton(props);
  const { user } = useAuth();

  return (
    <Flex
      as="button"
      {...buttonProps} //TEMP:COMBAK: It was creating a warning due to button props in Flex.
      flexShrink={0}
      rounded="lg"
      outline="0"
      _focus={{ shadow: "outline" }}
    >
      <Box srOnly>Open user menu</Box>
      <Avatar
        bg="white"
        size="sm"
        src={user?.avatar || ""}
        name={user?.firstName}
      />
    </Flex>
  );
};

export const ProfileDropdown = () => {
  const { user, logout } = useAuth();
  return (
    <Box>
      <Menu>
        <ProfileMenuButton />
        <MenuList
          rounded="md"
          shadow="lg"
          py="1"
          color={mode("gray.600", "inherit")}
          fontSize="sm"
          zIndex={99}
        >
          {!user ? (
            <SkeletonLoading />
          ) : (
            <HStack px="3" py="4">
              <Avatar
                size="sm"
                src={user?.avatar || ""}
                name={user?.firstName}
              />
              <Box lineHeight="1">
                <Text align="left" fontWeight="semibold">
                  {user?.firstName + " " + user?.lastName}
                </Text>
                <Text mt="1" fontSize="xs" color="gray.500">
                  {user?.email}
                </Text>
              </Box>
            </HStack>
          )}
          <Link to={ROUTES.ACCOUNT}>
            <MenuItem
              color={mode("black.800", "whiteAlpha.800")}
              fontWeight="medium"
            >
              Account Settings
            </MenuItem>
          </Link>
          {/* <Link to={ROUTES.ACCOUNT_BILLING}>
            <MenuItem
              color={mode("black.800", "whiteAlpha.800")}
              fontWeight="medium"
            >
              Billing
            </MenuItem>
          </Link> */}
          <Link to={ROUTES.HELP}>
            <MenuItem
              color={mode("black.800", "whiteAlpha.800")}
              fontWeight="medium"
            >
              Help and Support
            </MenuItem>
          </Link>
          <MenuItem
            fontWeight="medium"
            color={mode("red.500", "red.300")}
            onClick={logout}
          >
            Sign out
          </MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
};
