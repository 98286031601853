import { Country, City, ICountry, ICity } from "country-state-city";
import { Timezones } from "country-state-city/lib/interface";
import FuzzySearch from "fuzzy-search";

const useCountryCity = () => {
  const listCountries = Country.getAllCountries();
  const listCities = City.getAllCities();

  const handleCitiesByCountry = (
    countryCode: string,
    cityName?: string
  ): ICity[] => {
    if (!!countryCode && !!cityName) {
      const allCountryCities = City.getCitiesOfCountry(countryCode);

      if (!allCountryCities) return [];
      const searcher = new FuzzySearch<ICity>(
        allCountryCities,
        ["name", "countryCode", "stateCode"],
        {
          caseSensitive: false,
        }
      );
      return searcher.search(cityName);
    }

    return City.getCitiesOfCountry(countryCode) || [];
  };

  const handleSearchCountry = (query: string): ICountry[] => {
    const searcher = new FuzzySearch<ICountry>(
      listCountries,
      ["name", "isoCode", "currency"],
      {
        caseSensitive: false,
      }
    );

    return searcher.search(query) || [];
  };

  const handleGetTimezonesByCountry = (countryCode: string): Timezones[] => {
    return Country.getCountryByCode(countryCode)?.timezones || [];
  };

  const handleGetCityByName = (name: string) => {
    return City.getAllCities().find((country) => country.name === name);
  };

  return {
    listCountries,
    listCities,
    searchCountry: handleSearchCountry,
    getTimezonesByCountry: handleGetTimezonesByCountry,
    getCitiesByCountry: handleCitiesByCountry,
    getCountryByISOCode: Country.getCountryByCode,
    getCityByName: handleGetCityByName,
  };
};

export default useCountryCity;
