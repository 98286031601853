import { useQuery } from "@tanstack/react-query";
import { orderKeys } from "settings/constants/queryKeys";
import { getOrderById } from "services/orderService";

const useOrder = (id?: string) => {
  return useQuery({
    queryKey: orderKeys(id!),
    queryFn: () => getOrderById(id!),
    enabled: !!id,
  });
};

export default useOrder;
