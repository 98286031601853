import { FC, ReactNode } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Divider,
  Heading,
  HStack,
} from "@chakra-ui/react";
import { FaList } from "react-icons/fa6";

interface Props {
  children: ReactNode;
}
const OrderActivityCard: FC<Props> = ({ children }) => {
  return (
    <Card variant="outline">
      <CardHeader>
        <HStack spacing={4}>
          <Heading fontSize="2xl" fontWeight="bold" textTransform="capitalize">
            Logs
          </Heading>
          <FaList size={22} />
        </HStack>
      </CardHeader>
      <Divider />
      <CardBody>{children}</CardBody>
    </Card>
  );
};

export default OrderActivityCard;
