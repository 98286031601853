import {
  // Card,
  // CardBody,
  // CardHeader,
  Circle,
  Divider,
  HStack,
  Icon,
  SlideFade,
  Stack,
  Text,
} from "@chakra-ui/react";
import MinimizeIconButton from "components/ui/IconButtons/MinimizeIconButton";
import { FC, useEffect, useState } from "react";
import { FaRepeat } from "react-icons/fa6";
import RevisionForm from "./RevisionForm";
import Alert from "components/features/Alert";
import useQueryParams from "hooks/useQueryParams";

interface Props {
  orderId: string;
  remainingRevisions: number | undefined;
}
const OrderRevisionAction: FC<Props> = ({ orderId, remainingRevisions }) => {
  const [isMinimized, setIsMinimized] = useState(false);
  const { getParam } = useQueryParams();
  const highlightParam = getParam("highlight");

  useEffect(() => {
    if (highlightParam) setIsMinimized(false);
  }, [highlightParam]);

  return (
    <Stack spacing={4}>
      <HStack justify="space-between" align="center">
        <HStack w="full" justify="space-between">
          <HStack>
            <Circle bg="red.200" p={3}>
              <Icon color="red.900" as={FaRepeat} />
            </Circle>
            <Stack spacing={0}>
              <Text fontWeight="semibold">
                You can request a delivery revision
              </Text>
              <Text>
                Kindly review the delivery details provided in the delivery
                activity below.
              </Text>
            </Stack>
          </HStack>

          <Stack>
            <MinimizeIconButton
              aria-label="Minimize Revision"
              isMinimized={!isMinimized}
              onClick={() => setIsMinimized(!isMinimized)}
            />
          </Stack>
        </HStack>
      </HStack>

      {!isMinimized && (
        <SlideFade in={true} offsetY="-20px">
          <Divider color="gray.300" mb={4} />
          <>
            {remainingRevisions === 0 ? (
              <Alert
                status="warning"
                title="Revisions Limit Reached"
                description="You have reached the maximum number of revisions. If you need further assistance, please contact the talent directly through chat."
              />
            ) : (
              <RevisionForm orderId={orderId} />
            )}
          </>
        </SlideFade>
      )}
    </Stack>
  );
};

export default OrderRevisionAction;
