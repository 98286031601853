"use client";
import { Card, Stack } from "@chakra-ui/react";
import { FC } from "react";
import ProfileCardImages from "./ProfileCardImages";
import ProfileCardInfo from "./ProfileCardInfo";
import { Profile } from "types/profile.types";
import { Link } from "react-router-dom";

//TODO update this interface for global use after the meet
interface ProfileCardProps {
  profile: Profile;
}

const ProfileCard: FC<ProfileCardProps> = ({ profile }) => {
  return (
    <Link to={`/profiles/${profile._id || profile.id}`}>
      <Card
        variant="outline"
        cursor="pointer"
        overflow="hidden"
        colorScheme="red"
        rounded="lg"
        pos="relative"
      >
        <ProfileCardImages
          attachments={profile?.galleryId?.attachments || []}
        />
        <Stack p="4" w="full" gap="4" h="full" justifyContent="space-between">
          <ProfileCardInfo
            avatar={profile.avatar}
            title={profile.title || ""}
            description={profile?.description || ""}
            availability={profile.availability}
          />
        </Stack>
      </Card>
    </Link>
  );
};

export default ProfileCard;
