import React from "react";
import { AsyncSelect, GroupBase } from "chakra-react-select";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Text,
} from "@chakra-ui/react";
import { FieldError, useFormContext, useWatch } from "react-hook-form";
import useCountryCity from "hooks/useCountryCity";

export interface ISelectOption {
  label: string;
  value: string;
}

interface FormFields {
  country: string;
}

const CountryField: React.FC = () => {
  const { searchCountry, getCountryByISOCode } = useCountryCity();
  const {
    setValue,
    formState: { errors },
  } = useFormContext<FormFields>();
  const country = useWatch({ name: "country" });
  const error = errors.country as FieldError;
  const countryFromAPI = getCountryByISOCode(country);

  return (
    <FormControl isInvalid={!!error}>
      <FormLabel>
        Country
        <Text ml={2} as="span" color="dimgrey" fontSize="sm" fontStyle="italic">
          (To replace text, type over the current contents)
        </Text>
      </FormLabel>
      <AsyncSelect<ISelectOption, false, GroupBase<ISelectOption>>
        isMulti={false}
        value={
          countryFromAPI
            ? { label: countryFromAPI.name, value: countryFromAPI.isoCode }
            : undefined
        }
        onChange={(values) =>
          setValue("country", values?.value || "", {
            shouldDirty: true,
            shouldTouch: true,
            shouldValidate: true,
          })
        }
        loadOptions={(value, callback) => {
          setTimeout(() => {
            const countries = searchCountry(value);
            callback(
              countries.map((c) => ({
                label: c.name,
                value: c.isoCode,
              }))
            );
          }, 500);
        }}
      />

      <FormErrorMessage>{error?.message}</FormErrorMessage>
    </FormControl>
  );
};

export default CountryField;
