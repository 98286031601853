/////////////////////
// Output Card UI used on Output Step of New Project Wizard
/////////////////////

// Basic Imports
import { useState, useEffect } from "react";

// Design Imports
import { Button, FlexProps, Icon, Stack } from "@chakra-ui/react";

import { GoPencil } from "react-icons/go";

// Layout and Section Imports
import { Card } from "components/ui/common/Card";
import { CardContent } from "components/ui/common/CardContent";
import { CardHeader } from "components/ui/common/CardHeader";
import { ToggleButton } from "components/ui/common/ToggleButton";
import { ToggleButtonGroup } from "components/ui/common/ToggleButtonGroup";
import RenderCameraCaptureLocation from "./CameraCaptureLocation";
import RenderQuantity from "./Quantity";
import RenderOutputFile from "./OutputFile";
import RenderQuality from "./Quality";
import RenderSourceFile from "./SourceFile";

// Data Imports
import { ActionDataProps, LocationProps } from "data/projectWizardData";
import { useProject } from "context/project/ProjectProvider";
import { OUTPUT_MAX_QUANTITY } from "settings/constants/projectWizard";

// Interfaces
interface OutputCardProps extends FlexProps {
  id: string;
  title: string;
  data: ActionDataProps;
  onChange?: (selected: any) => void;
  selectedData?: any;
  readOnly?: boolean;
}

export interface Item {
  label: string;
  value: string;
}

// Functions
export const OutputCardForSteps = ({
  title,
  data,
  selectedData,
  readOnly,
}: OutputCardProps) => {
  const { projectWizard, onAddSelectedOutputs } = useProject();
  const [cameraCaptureQuantity, setCameraCaptureQuantity] = useState(
    selectedData?.quantity || "1"
  ); // This quantity will decide how many camera captured locations has to be selected
  const [selected, setSelected] = useState(selectedData);

  // If readOnly then it will work
  useEffect(() => {
    if (!readOnly) return;
    setSelected(selectedData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedData]);

  /*
    1. If there is not readOnly then it will work.
    2. If no readOnly then it will update project wizard with updated data
  */
  useEffect(() => {
    // When only need to display data rather than update data
    if (!!readOnly) return;

    // Below logics are only required when you want to update data
    const selectedOutputs = projectWizard.selectedOutputs;
    const index = selectedOutputs.findIndex(
      (item: any) => item.id === selected?.id
    );

    // Hack: To avoid unexpected compile error
    if (index === -1) {
      return;
    }

    const updatedOutputs = [...selectedOutputs];
    updatedOutputs[index] = selected;

    onAddSelectedOutputs(updatedOutputs);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  useEffect(() => {
    setSelected({ ...selected, quantity: cameraCaptureQuantity });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cameraCaptureQuantity]);

  return (
    <Card>
      <Stack
        direction={{ base: "column", md: "row" }}
        spacing={{ base: "4", md: "10" }}
      >
        <CardContent>
          <CardHeader
            title={title}
            action={
              <Button
                hidden={true}
                variant="outline"
                leftIcon={
                  <Icon as={GoPencil} color="gray.400" marginStart="-1" />
                }
              >
                Edit
              </Button>
            }
          />
          <Stack spacing={4} mt="6">
            {/* ONlY FOR 2D FLOOR PLAN */}
            {selected?.initialData && (
              <ToggleButtonGroup
                readOnly={readOnly}
                value={selected.initialData}
                isAttached
                variant="outline"
                aria-label="Source File type"
                onChange={(value) => {
                  if (!!readOnly) return;
                  setSelected({ ...selected, initialData: value });
                }}
              >
                {data.initialData &&
                  data.initialData.map((option: any, idx: number) => (
                    <ToggleButton
                      key={option}
                      value={option}
                      id={idx.toString()}
                    />
                  ))}
              </ToggleButtonGroup>
            )}

            <RenderOutputFile
              readOnly={readOnly}
              title="Output Filetype"
              data={data.outputFileType}
              defaultValues={selected?.outputFileType}
              onSelect={(value: string[]) => {
                if (!!readOnly) return;
                setSelected({ ...selected, outputFileType: value });
              }}
            />
            <RenderQuality
              readOnly={readOnly}
              selected={selected?.imageSize}
              title="Image Size"
              defaultValue={selected?.imageSize}
              options={data.imageSize}
              onSelect={(value: string) => {
                if (!!readOnly) return;
                setSelected({ ...selected, imageSize: value });
              }}
            />
            <RenderSourceFile
              readOnly={readOnly}
              title="Source File"
              defaultValue={selected?.sourceFile}
              selected={selected?.sourceFile}
              options={data.sourceFile}
              onSelect={(value: string) => {
                if (!!readOnly) return;
                setSelected({ ...selected, sourceFile: value });
              }}
            />
            <RenderQuantity
              readOnly={readOnly}
              min={1}
              max={OUTPUT_MAX_QUANTITY} // Depends on camera capture options
              value={cameraCaptureQuantity}
              defaultValue={selected.quantity}
              onChange={(value: string) => {
                if (!!readOnly) return;
                setCameraCaptureQuantity(value);
              }}
            />
            <RenderCameraCaptureLocation
              readOnly={readOnly}
              title="Camera capture locations"
              selectedLocations={selected.cameraCaptureLocations.map(
                (l: string) => {
                  // We need to show label that we are not getting from backend.
                  const targetLocation = data.cameraCaptureLocations.find(
                    (dl: any) => dl.value === l
                  );

                  if (!!targetLocation)
                    return {
                      label: targetLocation.label,
                      value: targetLocation.value,
                    };

                  return {
                    label: l,
                    value: l,
                  };
                }
              )}
              locations={data.cameraCaptureLocations}
              noOfMaxSelections={cameraCaptureQuantity}
              onSelect={(locations: LocationProps[]) => {
                if (!!readOnly) return;
                const formatLocationSchema = locations.map((l) => l.value);
                setSelected({
                  ...selected,
                  cameraCaptureLocations: formatLocationSchema,
                });
              }}
            />
          </Stack>
        </CardContent>
      </Stack>
    </Card>
  );
};
